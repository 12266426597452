import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { sendEmailVerification } from '../../../actions/userAuth'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser'

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        background: "#ecf0f5",
        alignItems: "initial"
    },
    body: {
        maxWidth: "700px"
    },
    icon: {
        fontSize: "6rem",
        marginBottom: "10px"
    },
    button: {
        paddingLeft: "35px",
        paddingRight: "35px"
    }
}));

const AccountVerification = (props) => {
    const userAuth = useSelector((state) => state.userAuth)
    const dispatch = useDispatch()
    const history = useHistory()
    const classes = useStyles();

    const handleEmailVerification = () => {
        if (userAuth.isLoading) return;
        dispatch(sendEmailVerification())
    }

    return (
        <Backdrop className={classes.backdrop} open={props.open}>
            <Box my={5} textAlign="center">
                <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="Excellence in Breeding Platform" width="250" />
                <Box mt={7}>
                    <VerifiedUserIcon color={userAuth.isVerified ? "secondary" : "primary"} className={classes.icon} />
                    <Typography variant="h4">{userAuth.isVerified ? "Account Verification Complete" : "Account Verification!"}</Typography>
                </Box>
                {userAuth.isVerified ?
                    <Box my={2} px={2} className={classes.body}>
                        <Typography variant="body1">Hi <b>{userAuth.user.fullName}</b>, thank you for verifying your email address - <u>{userAuth.user.email}</u>.</Typography>
                        <Typography variant="body1">You can now proceed using the Breeding Pipeline Manager by clicking the "Go To Home" button.</Typography>
                        <Box my={4}>
                            <Button variant="contained" onClick={() => history.push("/")} color="secondary">Go To Home</Button>
                        </Box>
                    </Box> :
                    <Box my={2} px={2} className={classes.body}>
                        <Typography variant="body1">Welcome <b>{userAuth.user.fullName}</b>, Please verify your email address - <u>{userAuth.user.email}</u>.</Typography>
                        <Typography variant="body1">We have sent a verification link to your email account. If you've not received, please click resend verification. </Typography>
                        <Box my={4}>
                            <Button className={classes.button} variant="contained" onClick={handleEmailVerification} color="primary">
                                Resend Email Verification
                                {
                                    userAuth.isLoading &&
                                    <Box position="absolute" top="20%" right="2%">
                                        <CircularProgress color="inherit" size={20} thickness={5} />
                                    </Box>
                                }
                            </Button>
                        </Box>
                    </Box>
                }
            </Box>
        </Backdrop>
    );
}

export default AccountVerification
import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_RESPONSE, CHANGE_PASSWORD_RESET } from '../constants/actionTypes'

const initialState = {
    isLoading: false,
    success: false,
    message: null
}
const reducer = (changePassword = initialState, action) => {
    switch (action.type) {
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...changePassword,
                isLoading: true
            }
        case CHANGE_PASSWORD_RESPONSE:
            return {
                ...action.payload,
                isLoading: false
            }
        case CHANGE_PASSWORD_RESET:
            return {
                ...initialState
            }
        default:
            return changePassword
    }
}

export default reducer
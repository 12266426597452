import { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import ShareIcon from '@material-ui/icons/Share';
import ShareDialog from '../ShareDialog';
import Badge from '@material-ui/core/Badge';
import { withStyles } from '@material-ui/core/styles';

const StyledBadge = withStyles((theme) => ({
    badge: {
        right: 6,
        top: 6,
        padding: '0 4px',
        minWidth: "16px",
        fontSize: "0.7rem",
        height: "16px",
        cursor: "pointer",
        backgroundColor: "rgb(1, 150, 136)"
    },
}))(Badge);

const ShareContent = ({ count, groupId, BreedingPipelineId, marketSegmentIds = [], breedingSchemeIds = [], productProfileIds = [], btnColor, btnSize, isDisabled, isHidden, reloadGrid }) => {
    const [dialogOpen, setDialogOpen] = useState(false)
    const isContentEmpty = marketSegmentIds.length === 0 && productProfileIds.length === 0 && breedingSchemeIds.length === 0
    return (
        <>
            <ShareDialog
                isOpen={dialogOpen}
                groupId={groupId}
                BreedingPipelineId={BreedingPipelineId}
                marketSegmentIds={marketSegmentIds}
                breedingSchemeIds={breedingSchemeIds}
                productProfileIds={productProfileIds}
                handleCloseShare={() => reloadGrid ? reloadGrid() : setDialogOpen(false)}
                handleCancelShare={() => setDialogOpen(false)}
            />
            <StyledBadge
                style={{ display: isHidden ? "none" : "inline-flex" }}
                badgeContent={count ? Number(count) : 0}
                color="secondary"
                onClick={() => !isDisabled && !isContentEmpty && setDialogOpen(true)}
            >
                <IconButton
                    disabled={isDisabled || isContentEmpty}
                    style={{ display: isHidden ? "none" : "inline-flex" }}
                    size={btnSize || "medium"}
                    color={btnColor || "inherit"}
                    onClick={() => setDialogOpen(true)}>
                    <ShareIcon />
                </IconButton>
            </StyledBadge>
        </>
    )
}

export default ShareContent;
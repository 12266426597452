import { useState, useEffect } from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import SaveIcon from '@material-ui/icons/Save'
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    form: {
        maxWidth: "340px"
    },
    dialogTitle: {
        paddingBottom: "0px"
    },
    textField: {
        margin: "10px 0px"
    }
}))

const BreedingSchemeFlowchartNodeForm = ({ open, updatedNode, handleSubmit, handleCancel }) => {

    const [nodeObj, setNodeObj] = useState({
        id: "",
        type: "",
        position: {},
        data: { label: "", description: "" }
    })

    useEffect(() => {
        setNodeObj(updatedNode)
    }, [updatedNode])

    const handleLabelChange = (e) => {
        setNodeObj({
            ...nodeObj,
            data: {
                ...nodeObj.data,
                label: e.target.value,

            }
        })
    }

    const handleDescriptionChange = (e) => {
        setNodeObj({
            ...nodeObj,
            data: {
                ...nodeObj.data,
                description: e.target.value,
            }
        })
    }

    const classes = useStyles()

    return (
        <Dialog
            open={open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <form className={classes.form} onSubmit={(e) => { e.preventDefault(); handleSubmit(nodeObj) }} autoComplete="off">
                <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">Update Node</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus={true}
                        autoComplete="off"
                        margin="dense"
                        id="label"
                        name="label"
                        label="Label"
                        value={nodeObj.data.label}
                        className={classes.textField}
                        type="text"
                        required
                        onChange={handleLabelChange}
                        fullWidth
                    />
                    <TextField
                        autoFocus={true}
                        autoComplete="off"
                        margin="dense"
                        id="description"
                        name="description"
                        label="Text/Description"
                        value={nodeObj.data.description}
                        className={classes.textField}
                        type="text"
                        onChange={handleDescriptionChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancel} color="primary" size="small" autoFocus>
                        Cancel
                    </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        size="small"
                        startIcon={<SaveIcon />}
                    >
                        Save
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default BreedingSchemeFlowchartNodeForm;
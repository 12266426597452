import { useState } from 'react'
import FormControl from '@material-ui/core/FormControl';
import Tooltip from '@material-ui/core/Tooltip'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    menuItem: {
        '& .MuiTypography-body1': {
            fontSize: "0.9rem"
        }
    }
}))

const BreedingSchemeFlowchartColumnToggler = (props) => {
    const [open, setOpen] = useState(false)

    const handleOpen = () => {
        setOpen(true)
    }

    const handleClose = () => {
        setOpen(false)
    }
    const handleChange = (e) => {
        props.toggleColumns(e.target.value)
    }

    const flowchartColumns = [
        { id: 1, label: 'Scheme' },
        { id: 2, label: 'Evaluation Decisions' },
        { id: 3, label: 'Selection Decisions' },
        { id: 4, label: 'Crossing / Multiplication Decisions' },
        { id: 5, label: 'Molecular Decisions' }]

    const classes = useStyles()
    return (
        <FormControl>
            <Tooltip title="Show/Hide Columns" placement="bottom">
                <IconButton id="columnToggleBtn" onClick={handleOpen}>
                    <ViewColumnIcon />
                </IconButton>
            </Tooltip>
            <Select
                labelId="demo-mutiple-checkbox-label"
                id="demo-mutiple-checkbox"
                multiple
                value={props.hiddenColumns}
                onChange={handleChange}
                input={<Input id="breeding-stage-columns" />}
                style={{ display: "none" }}
                open={open}
                onClose={handleClose}
                MenuProps={{
                    anchorEl: document.getElementById("columnToggleBtn"),
                    style: { marginTop: 60 }
                }}
            >
                {flowchartColumns.map((column, idx) => (
                    <MenuItem className={classes.menuItem} key={column.id} value={column.id}>
                        <Checkbox checked={!(props.hiddenColumns.indexOf(column.id) > -1)} />
                        <ListItemText primary={column.label} />
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

export default BreedingSchemeFlowchartColumnToggler;
import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    getBreedingPipelines, createBreedingPipeline, updateBreedingPipeline,
    confirmRemoveBreedingPipeline, cancelRemoveBreedingPipeline, removeBreedingPipeline
} from '../../../actions/breedingPipelines'
import { showNotification } from '../../../actions/notification'
import { format } from 'date-fns'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Tooltip from '@material-ui/core/Tooltip'
import MaterialTable from 'material-table'
import { MTableToolbar, MTableAction } from 'material-table'
import TableIcons from '../../molecules/TableIcons'
import ViewComfyIcon from '@material-ui/icons/ViewComfy'
import BlurLinearIcon from '@material-ui/icons/BlurLinear';
import FormatListBulletedIcon from '@material-ui/icons/FormatListBulleted';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import makeStyles from '@material-ui/core/styles/makeStyles'
import MarketSegment from '../MarketSegment'
import BreedingScheme from '../BreedingScheme'
import ProductProfile from '../ProductProfile'
import UserDialog from '../../molecules/UserDialog'
import ShareContent from '../ShareContent';
import Divider from '@material-ui/core/Divider';
import { marketSegmentLookups } from '../../../constants'


const useStyles = makeStyles((theme) => ({
    table: {
        '& th': {
            border: "1px solid #e3e3e3",
            background: "#ecf0f5"
        },
        '& td': {
            padding: "2px 24px 2px 16px",
            fontSize: "13px !important",
            border: "1px solid #e3e3e3",
        },
        '& tbody > tr > td:first-child': {
            width: "30px !important"
        },
        '& td input': {
            width: "100%"
        },
        '& td > div': {
            width: "auto !important"
        },
    },
    rowTooltip: {
        width: "100px"
    }
}))

const DeactivatedUser = (props) => {
    return (
        <Tooltip title="User Account is Deactivated" arrow placement="top">
            <Typography style={{ font: "inherit" }} color="error">{props.fullName}</Typography>
        </Tooltip>
    )
}

const EmptyMarketSegments = () => {
    return (
        <Tooltip title="Market Segment Not Defined!" arrow placement="top">
            <Typography style={{ font: "inherit", fontWeight: "bold" }} color="error">0</Typography>
        </Tooltip>
    )
}

const BreedingPipeline = ({ sharedOnly }) => {
    const tableRef = useRef()
    const userAuth = useSelector((state) => state.userAuth)
    const breedingPipelinesState = useSelector((state) => state.breedingPipelines)
    const [tablePageSize, setTablePageSize] = useState(10)
    const [sharedContentOnly, setSharedContentOnly] = useState(sharedOnly)
    const dispatch = useDispatch()
    const classes = useStyles()
    useEffect(() => {
        if (sharedOnly !== sharedContentOnly) {
            setSharedContentOnly(sharedOnly)
            reloadGrid()
        }
    }, [sharedOnly, sharedContentOnly])

    const columns = [
        {
            title: 'Region',
            field: 'region',
            align: "left",
            lookup: marketSegmentLookups.region,
            removable: false,
            cellStyle: { width: "10%" },
            render: (rowData) => rowData.region ? rowData.region : "-"
        },
        {
            title: 'Continent',
            field: 'continent',
            align: "left",
            lookup: marketSegmentLookups.continent,
            removable: false,
            cellStyle: { width: "10%" },
            render: (rowData) => rowData.continent ? rowData.continent : "-"
        },
        {
            title: 'Species',
            field: 'specie',
            align: "left",
            removable: false,
            cellStyle: { width: "20%" },
            render: (rowData) => rowData.specie ? rowData.specie : "-"
        },
        { title: 'Breeding Pipeline Name', field: 'name', align: "left", removable: false, cellStyle: { width: "20%" }, editable: "never" },
        {
            title: 'Breeder(s) in charge',
            field: 'breedersInCharge',
            align: "left",
            render: rowData => rowData.breedersInCharge ? rowData.breedersInCharge : "-",
            removable: false,
            cellStyle: { width: "20%" },
            editable: "onUpdate"
        },
        {
            title: 'Market Segments',
            field: 'totalMarketSegments',
            align: "center",
            render: rowData => rowData.MarketSegments ? rowData.MarketSegments.length : <EmptyMarketSegments />,
            removable: true,
            editable: "never"
        },
        {
            title: 'Product Profiles',
            field: 'totalProductProfiles',
            align: "center",
            render: rowData => rowData.ProductProfiles ? rowData.ProductProfiles.length : 0,
            removable: true,
            editable: "never"
        },
        {
            title: 'Breeding Schemes',
            field: 'totalBreedingSchemes',
            align: "center",
            removable: true,
            editable: "never",
            render: rowData => rowData.BreedingSchemes ? rowData.BreedingSchemes.length : 0
        },
        { title: 'Description', field: 'description', align: "left", removable: false, cellStyle: { width: "25%" }, render: (rowData) => rowData.description ? rowData.description : "-", },
        { title: 'Created At', field: 'createdAt', hidden: true, editable: "never" },
        {
            title: 'Updated',
            field: 'updatedAt',
            hidden: true,
            cellStyle: { width: "10%" },
            render: rowData => format(new Date(rowData.updatedAt), 'MMM dd, yyyy'),
            editable: "never"
        },
        {
            title: 'Created By',
            field: 'User.fullName',
            align: "left",
            hidden: true,
            render: rowData => rowData.User.deletedAt ? <DeactivatedUser fullName={rowData.User.fullName} /> : rowData.User.fullName,
            removable: true,
            editable: "never"
        },
    ];

    const getBreedingPipelineData = query => {
        setTablePageSize(query.pageSize)
        if (query.sharedOnly !== sharedContentOnly)
            query.page = 0
        query.sharedOnly = sharedContentOnly
        const { detailPanel } = tableRef.current.props;
        return new Promise((resolve, reject) => {
            Promise.resolve(dispatch(getBreedingPipelines(query))
                .then((breedingPipelines) => {
                    const TableData = breedingPipelines.data.rows
                    const totalCount = breedingPipelines.data.count
                    TableData.map(pipelineObj => {
                        if (breedingPipelinesState.renderedMarketSegments.includes(pipelineObj.id))
                            return pipelineObj.tableData = {
                                ...pipelineObj.tableData,
                                showDetailPanel: detailPanel[0].render
                            }
                        else if (breedingPipelinesState.renderedProductProfiles.includes(pipelineObj.id))
                            return pipelineObj.tableData = {
                                ...pipelineObj.tableData,
                                showDetailPanel: detailPanel[1].render
                            }
                        else if (breedingPipelinesState.renderedBreedingSchemes.includes(pipelineObj.id))
                            return pipelineObj.tableData = {
                                ...pipelineObj.tableData,
                                showDetailPanel: detailPanel[2].render
                            }
                        else return pipelineObj
                    })
                    resolve({
                        data: TableData,
                        page: query.page,
                        totalCount,
                    });
                }))
        });
    };

    const handleRemovePipeline = async () => {
        await dispatch(removeBreedingPipeline(breedingPipelinesState.removePipelineForm.breedingPipelineId))
        reloadGrid()
    }

    const handleCancelRemovePipeline = () => {
        dispatch(cancelRemoveBreedingPipeline())
    }

    const reloadGrid = () => {
        tableRef.current && tableRef.current.onQueryChange()
    }

    return (
        <Box px={2} className={classes.table} flexGrow={1}>
            <UserDialog
                open={breedingPipelinesState.removePipelineForm.open}
                contentTitle="Are you sure you want to remove the selected breeding pipeline?"
                contentText="Note: Removing Breeding Pipeline will also delete market segments & breeding schemes defined under it."
                handleAction={handleRemovePipeline}
                handleCancel={handleCancelRemovePipeline}
            />
            <MaterialTable
                title={sharedContentOnly ? "Shared Contents" : "Breeding Pipelines"}
                tableRef={tableRef}
                columns={columns}
                icons={TableIcons}
                isLoading={breedingPipelinesState.isLoading}
                options={{
                    pageSize: tablePageSize,
                    debounceInterval: 500,
                    //Added to assist the UI experience during searching but raised unpleasant experience on other fronts
                    //searchAutoFocus: true,
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    exportButton: true,
                    columnsButton: true,
                    addRowPosition: "first",
                    padding: "dense",
                    loadingType: "overlay",
                    toolbarButtonAlignment: "left",
                    rowStyle: (rowData) => ({
                        backgroundColor: rowData.User.deletedAt ? "#ffcdd2" : "inherit"
                    })
                }}
                data={query => getBreedingPipelineData(query)}
                components={{
                    Toolbar: props => (
                        <span className="appTour-step-2">
                            <MTableToolbar {...props} />
                        </span>
                    ),
                    Action: actionProps =>
                        actionProps.action.position === "row" ?
                            <span className="appTour-step-3" style={{ display: "flex", color: "#000" }}>
                                <MTableAction {...actionProps} />
                                <Divider orientation="vertical" variant="fullWidth" flexItem style={{
                                    display: sharedContentOnly ? ((actionProps.data.Shares && actionProps.data.Shares.length > 0) ? "inline" : "none") : "inline"
                                }} />
                                <ShareContent
                                    count={actionProps.data ? actionProps.data.SharedCount : 0}
                                    reloadGrid={reloadGrid}
                                    isDisabled={sharedContentOnly ? ((actionProps.data.Shares && actionProps.data.Shares.includes(userAuth.user.id)) ? false : true) : false}
                                    isHidden={sharedContentOnly ? ((actionProps.data.Shares && actionProps.data.Shares.includes(userAuth.user.id)) ? false : true) : false}
                                    groupId={`BreedingPipeline-${actionProps.data.id}`}
                                    BreedingPipelineId={actionProps.data.id}
                                    marketSegmentIds={(actionProps.data && actionProps.data.MarketSegments) ? actionProps.data.MarketSegments : []}
                                    breedingSchemeIds={(actionProps.data && actionProps.data.BreedingSchemes) ? actionProps.data.BreedingSchemes : []}
                                    productProfileIds={(actionProps.data && actionProps.data.ProductProfiles) ? actionProps.data.ProductProfiles : []}
                                    btnColor="primary"
                                    btnSize="small" />
                            </span> :
                            <span className="appTour-step-3" style={{ color: "#000" }}>
                                <MTableAction {...actionProps} />
                            </span>
                }}
                detailPanel={[
                    {
                        icon: () => <BlurLinearIcon />,
                        openIcon: () => <BlurLinearIcon color="secondary" />,
                        tooltip: "Market Segments",
                        render: rowData => <MarketSegment breedingPipelineId={rowData.id} reloadGrid={reloadGrid} sharedOnly={sharedContentOnly} />
                    },
                    {
                        icon: () => <FormatListBulletedIcon />,
                        openIcon: () => <FormatListBulletedIcon color="secondary" />,
                        tooltip: "Product Profiles",
                        render: rowData => <ProductProfile breedingPipelineId={rowData.id} sharedOnly={sharedContentOnly} />
                    },
                    {
                        icon: () => <ViewComfyIcon />,
                        openIcon: () => <ViewComfyIcon color="secondary" />,
                        tooltip: 'Breeding Schemes',
                        render: (rowData) => <BreedingScheme breedingPipelineId={rowData.id} reloadGrid={reloadGrid} sharedOnly={sharedContentOnly} />
                    }
                ]}
                actions={[
                    rowData => ({
                        icon: () => <DeleteSweepIcon color="action" />,
                        tooltip: "Delete Breeding Pipeline",
                        isFreeAction: false,
                        hidden: sharedContentOnly ? ((rowData.Shares && rowData.Shares.includes(userAuth.user.id)) ? false : true) : false,
                        disabled: sharedContentOnly ? ((rowData.Shares && rowData.Shares.includes(userAuth.user.id)) ? false : true) : false,
                        onClick: async (event) => dispatch(confirmRemoveBreedingPipeline(rowData.id))
                    })
                ]}
                editable={
                    sharedContentOnly ? {} : {
                        onRowAdd: newData =>
                            new Promise(async (resolve, reject) => {
                                await dispatch(createBreedingPipeline(newData))
                                resolve(reloadGrid())
                            }),
                    }}
                cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise(async (resolve, reject) => {
                            const updatedBreedingPipelineObj = {
                                ...rowData,
                                [columnDef.field]: newValue
                            }
                            if (sharedContentOnly && !(rowData.Shares && rowData.Shares.includes(userAuth.user.id))) {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to make changes to this Breeding Pipeline" }))
                                reject()
                            } else {
                                await dispatch(updateBreedingPipeline(updatedBreedingPipelineObj))
                                resolve(reloadGrid())
                            }
                        });
                    }
                }}
            />
        </Box>
    )
}

export default BreedingPipeline;
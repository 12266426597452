import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_REQUEST, FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_RESPONSE, LOADING_BREEDING_SCHEME_BLUEPRINT,
    SAVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE, REMOVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE, FETCH_BREEDING_PIPELINE_FLOWCHART_RESPONSE
} from '../constants/actionTypes';

//Action Creators

export const getBreedingPipeline = (id) => async (dispatch) => {
    try {
        const { data } = await api.fetchBreedingPipeline(id)
        dispatch({ type: FETCH_BREEDING_PIPELINE_FLOWCHART_RESPONSE, payload: data })
    } catch (error) {
        dispatch({ type: FETCH_BREEDING_PIPELINE_FLOWCHART_RESPONSE })
    }
}

export const getBreedingSchemeBlueprint = (id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_REQUEST })
        const { data } = await api.fetchBreedingSchemeBlueprint(id)
        dispatch({ type: FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_RESPONSE, payload: data })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const saveBreedingSchemeBlueprint = (breedingSchemeBlueprintObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_SCHEME_BLUEPRINT })
        const { data } = await api.saveBreedingSchemeBlueprint(breedingSchemeBlueprintObj.BreedingSchemeId, breedingSchemeBlueprintObj)
        dispatch({ type: SAVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE, payload: data })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: SAVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const removeBreedingSchemeBlueprint = (breedingSchemeId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_SCHEME_BLUEPRINT })
        const { data } = await api.removeBreedingSchemeBlueprint(breedingSchemeId)
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: REMOVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
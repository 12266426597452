import { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const GridAutocompleteField = (props) => {
    const autocompleteOptions = Object.keys(props.columnDef.lookup)
    const [value, setValue] = useState("")
    const [newValue, setNewValue] = useState("")
    const [width, setWidth] = useState(value.length < 3 ? "3ch" : (value.length + 3) + "ch")

    useEffect(() => {
        setValue(props.columnDef.render ? props.columnDef.render(props.rowData) : props.value)
        setNewValue(props.columnDef.render ? props.columnDef.render(props.rowData) : props.value)
    }, [props])

    const handleChange = (e) => {
        // eslint-disable-next-line eqeqeq
        if (value != newValue) {
            props.handleSubmit(newValue, value, props.rowData, props.columnDef)
            setValue(newValue)
            setWidth(newValue.length < 3 ? "3ch" : (newValue.length + 3) + "ch")
        }
    }
    return (
        <Autocomplete
            name={props.name}
            onBlur={handleChange}
            disabled={props.disabled}
            freeSolo
            options={autocompleteOptions}
            style={{ ...props.columnDef.cellStyle, minWidth: width, border: 0 }}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option;
            }}
            inputValue={newValue}
            onInputChange={(e, newValue) => {
                setNewValue(newValue)
            }}
            onSelect={handleChange}
            renderInput={(params) => (
                <TextField {...params}
                    name={props.name}
                    fullWidth                    
                    onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, props.columnDef.maxLength)
                    }}
                />
            )}
            renderOption={(option) => (
                <Box flex={1}>
                    <Box flexGrow={1}><Typography>{option}</Typography></Box>
                </Box>
            )}
        />
    )
}

export default GridAutocompleteField
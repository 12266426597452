import {
    FETCH_BREEDING_SCHEME_SETTINGS_RESPONSE,
    UPDATE_BREEDING_SCHEME_SETTINGS_RESPONSE, LOADING_BREEDING_SCHEME_SETTINGS
} from '../constants/actionTypes'

const initialState = {
    data: {
        constMaterialFromRSpipeline: "",
        constMaterialFromIntrogPipe: "",
        constMaterialFromExternalPipe: "",
        constYearsInTrainingSet: "",
        constLevel1: "",
        constLevel2: "",
        constLevel3: "",
        constLevel4: "",
        constCostPerFieldSpot: "",
        constCostPerPot: "",
        constCostPerTube: "",
        constCostPerRow: "",
        constCostPerPlot: "",
        constCostPerNursery: "",
        constCostPerPetri: "",
        constCostPerLocation: "",
        constCostPerSampleRFLP: "",
        constCostPerSampleAFLP: "",
        constCostPerSampleSTR: "",
        constCostPerSampleSSR: "",
        constCostPerSampleKASP: "",
        constCostPerSampleGBS: "",
        constCostPerSampleArray: "",
        constCostPerSampleFlowC: "",
        constAnalyticsCost: "",
        constCostPerCloning: "",
        constCostPerDoubling: "",
        constCostToHapInduct: "",
        constCostPerSelfCross: "",
        constCostPer2way: "",
        constCostPer3way: "",
        constCostTo4way: "",
        constCostPerBC: "",
        constCostPerTC: "",
        constCostPerSCA: "",
        constCostToOP: "",
        constCostPerInter2way: "",
        constCostPerInter3way: "",
    },
    isLoading: false
}

const reducer = (breedingSchemeSettings = initialState, action) => {
    switch (action.type) {
        case LOADING_BREEDING_SCHEME_SETTINGS:
            return {
                ...breedingSchemeSettings,
                isLoading: true
            }
        case FETCH_BREEDING_SCHEME_SETTINGS_RESPONSE:
            return {
                ...breedingSchemeSettings,
                ...action.payload,
                isLoading: false
            }
        case UPDATE_BREEDING_SCHEME_SETTINGS_RESPONSE:
            return {
                ...breedingSchemeSettings,
                ...action.payload,
                isLoading: false
            }
        default:
            return breedingSchemeSettings
    }
}

export default reducer
import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { selectBreedingStagesData, selectBreedingSchemePermission } from '../../../selectors/breedingStageSelector'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Header from '../../organisms/Header'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Footer from '../../organisms/Footer'
import Typography from '@material-ui/core/Typography'
import AppsIcon from '@material-ui/icons/Apps';
import ViewComfyIcon from '@material-ui/icons/ViewComfy'
import AssessmentIcon from '@material-ui/icons/Assessment'
import makeStyles from '@material-ui/core/styles/makeStyles'
import BreedingSchemeFlowchart from '../../organisms/BreedingSchemeFlowchart'
import { getBreedingPipeline, getBreedingSchemeBlueprint } from '../../../actions/breedingSchemeFlowchart'

import { getBreedingStages } from '../../../actions/breedingStage';


const useStyles = makeStyles((theme) => ({
    divider: {
        height: 28,
        margin: 0,
        background: "#999"
    },
    breadcrumb: {
        color: "#333",
        '& a': {
            textDecoration: "none",
            color: "inherit"
        }
    },
    breadcrumbIcon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}))

const BreedingSchemeFlowchartPage = () => {
    const breedingSchemePermissionState = useSelector(selectBreedingSchemePermission)
    const breedingSchemeFlowchartState = useSelector((state) => state.breedingSchemeFlowchart)
    const breedingStageDataState = useSelector(selectBreedingStagesData)

    const [headerContent, setHeaderContent] = useState({
        breedingPipeline: "",
        name: "",
        headerPage: "breedingScheme"
    })
    const dispatch = useDispatch()
    let { id } = useParams();

    useEffect(() => {
        if (id) {
            dispatch(getBreedingPipeline(id))
            dispatch(getBreedingSchemeBlueprint(id))
            dispatch(getBreedingStages(id))
        }
    }, [dispatch, id])

    useEffect(() => {
        if (Object.keys(breedingSchemeFlowchartState.breedingScheme).length) {
            setHeaderContent({
                ...headerContent,
                breedingPipeline: breedingSchemeFlowchartState.breedingScheme.BreedingPipeline.specie,
                name: breedingSchemeFlowchartState.breedingScheme.name
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breedingSchemeFlowchartState])

    const handleRefreshFlowchart = () => {
        dispatch(getBreedingSchemeBlueprint(id))
    }

    const classes = useStyles()
    return (
        <>
            <Header content={headerContent} />
            <Box mt={2}>
                <Box px={2}>
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" to={breedingSchemePermissionState === "OWNER" ? "/" : "/shared"} className={classes.link}>
                            <Box display="flex">
                                <AppsIcon className={classes.breadcrumbIcon} />
                                <Typography variant="subtitle2">
                                    {breedingSchemePermissionState !== "OWNER" && "Shared"} Breeding Pipelines
                                </Typography>
                            </Box>
                        </Link>
                        <Link color="inherit" to={`/breeding-stage/${id}`} className={classes.link}>
                            <Box display="flex">
                                <ViewComfyIcon className={classes.breadcrumbIcon} />
                                <Typography variant="subtitle2">
                                    Breeding Stages
                                </Typography>
                            </Box>
                        </Link>
                        <Box display="flex">
                            <AssessmentIcon className={classes.breadcrumbIcon} />
                            <Typography variant="subtitle2" color="textPrimary" className={classes.link}>
                                Breeding Scheme Flowchart
                            </Typography>
                        </Box>
                    </Breadcrumbs>
                </Box>
                <Box>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <BreedingSchemeFlowchart
                                BreedingSchemeId={id}
                                savedData={breedingSchemeFlowchartState.data}
                                schemeData={breedingStageDataState}
                                isLoading={breedingSchemeFlowchartState.isLoading}
                                refreshFlowhcart={handleRefreshFlowchart}
                                permission={breedingSchemePermissionState}
                            />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Footer />
        </>
    )
}

export default BreedingSchemeFlowchartPage;
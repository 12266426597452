import axios from 'axios'

export const signup = (newAccountObj) => axios.post("/api/account/register", newAccountObj)
export const login = (userAuth) => axios.post("/api/account/login", userAuth)
export const resendVerification = () => axios.post("/api/account/verify")
export const changePassword = (passwordObj) => axios.post("/api/account/change-password", passwordObj)
export const logout = () => axios.get("/api/account/logout")
export const recoverPassword = (email) => axios.post("/api/account/recover", email)
export const reset = (token) => axios.post(`/api/account/reset/${token}`)
export const resetPassword = (token, password) => axios.post(`/api/account/reset-password/${token}`, password)
export const checkUser = () => axios.get("/api/account/check-user")

export const fetchBreedingPipelines = (query) => axios.post(`/api/breeding-pipeline/all`, query)
//Breeding Scheme ID is passed as an argument
export const fetchBreedingPipeline = (id) => axios.get(`/api/breeding-pipeline/${id}`)
export const createBreedingPipeline = (breedingPipelineObj) => axios.post(`/api/breeding-pipeline`, breedingPipelineObj)
export const updateBreedingPipeline = (id, breedingPipelineObj) => axios.put(`/api/breeding-pipeline/${id}`, breedingPipelineObj)
export const removeBreedingPipeline = (id) => axios.delete(`/api/breeding-pipeline/${id}`)

export const fetchMarketSegments = (id, queryObj) => axios.post(`/api/market-segment/${id}`, queryObj)
export const createMarketSegment = (marketSegmentObj) => axios.post(`/api/market-segment`, marketSegmentObj)
export const updateMarketSegment = (id, marketSegmentObj) => axios.put(`/api/market-segment/${id}`, marketSegmentObj)
export const duplicateMarketSegment = (id) => axios.post(`/api/market-segment/duplicate/${id}`)
export const orderMarketSegment = (orderObj) => axios.patch(`/api/market-segment/`, orderObj)
export const removeMarketSegment = (id) => axios.delete(`/api/market-segment/${id}`)
export const exportMarketSegment = (id) => axios.post(`/api/market-segment/export/${id}`)

export const fetchProductProfiles = (id, queryObj) => axios.post(`/api/product-profile/${id}`, queryObj)
export const createProductProfile = (productProfileObj) => axios.post(`/api/product-profile`, productProfileObj)
export const updateProductProfile = (id, productProfileObj) => axios.put(`/api/product-profile/${id}`, productProfileObj)
export const duplicateProductProfile = (id) => axios.post(`/api/product-profile/duplicate/${id}`)
export const orderProductProfile = (orderObj) => axios.patch(`/api/product-profile/`, orderObj)
export const removeProductProfile = (id) => axios.delete(`/api/product-profile/${id}`)
export const exportProductProfile = (id) => axios.post(`/api/product-profile/export/${id}`)

export const fetchProductProfileTraits = (id) => axios.get(`/api/product-profile-trait/${id}`)
export const createProductProfileTrait = (productProfileTraitObj) => axios.post(`/api/product-profile-trait`, productProfileTraitObj)
export const updateProductProfileTrait = (id, productProfileTraitObj) => axios.put(`/api/product-profile-trait/${id}`, productProfileTraitObj)
export const duplicateProductProfileTrait = (id) => axios.post(`/api/product-profile-trait/${id}`)
export const orderProductProfileTrait = (orderObj) => axios.patch(`/api/product-profile-trait/`, orderObj)
export const removeProductProfileTrait = (id) => axios.delete(`/api/product-profile-trait/${id}`)
export const exportProductProfileTrait = (id) => axios.post(`/api/product-profile-trait/export/${id}`)

export const fetchBreedingSchemeSettings = (id) => axios.get(`/api/scheme-settings/${id}`)
export const updateBreedingSchemeSettings = (id, breedingSchemeSettingsObj) => axios.put(`/api/scheme-settings/${id}`, breedingSchemeSettingsObj)

export const fetchBreedingSchemes = (id, queryObj) => axios.post(`/api/breeding-scheme/${id}`, queryObj)
export const createBreedingScheme = (breedingSchemeObj) => axios.post(`/api/breeding-scheme`, breedingSchemeObj)
export const updateBreedingScheme = (id, breedingSchemeObj) => axios.put(`/api/breeding-scheme/${id}`, breedingSchemeObj)
export const duplicateBreedingScheme = (id) => axios.post(`/api/breeding-scheme/duplicate/${id}`)
export const orderBreedingScheme = (orderObj) => axios.patch(`/api/breeding-scheme/`, orderObj)
export const removeBreedingScheme = (id) => axios.delete(`/api/breeding-scheme/${id}`)

export const fetchBreedingStages = (id) => axios.get(`/api/breeding-stage/${id}`)
export const createBreedingStage = (breedingStageObj) => axios.post(`/api/breeding-stage`, breedingStageObj)
export const updateBreedingStage = (breedingStageObj) => axios.put(`/api/breeding-stage`, breedingStageObj)
export const duplicateBreedingStage = (id) => axios.post(`/api/breeding-stage/${id}`)
export const orderBreedingStage = (orderObj) => axios.patch(`/api/breeding-stage/`, orderObj)
export const addTraits = (id, traitObj) => axios.post(`/api/trait/${id}`, traitObj)
export const updateTraits = (id, traitObj) => axios.put(`/api/trait/${id}`, traitObj)
export const removeTraits = (id, breedingSchemeId) => axios.delete(`/api/trait/${id}/${breedingSchemeId}`)
export const addCrossingPool = (id) => axios.post(`/api/crossing-pool/${id}`)
export const removeCrossingPool = (id, breedingSchemeId) => axios.delete(`/api/crossing-pool/${id}/${breedingSchemeId}`)
export const removeBreedingStage = (id, breedingSchemeId) => axios.delete(`/api/breeding-stage/${id}/${breedingSchemeId}`)
export const exportBreedingStages = (id, breedingStagesObj) => axios.post(`/api/breeding-stage/export/${id}`, breedingStagesObj)

export const fetchBreedingSchemeBlueprint = (id) => axios.get(`/api/breeding-scheme-blueprint/${id}`)
export const saveBreedingSchemeBlueprint = (id, breedingSchemeBlueprintObj) => axios.put(`/api/breeding-scheme-blueprint/${id}`, breedingSchemeBlueprintObj)
export const removeBreedingSchemeBlueprint = (id) => axios.delete(`/api/breeding-scheme-blueprint/${id}`)

export const fetchProfile = () => axios.get(`/api/user`)
export const fetchUsers = (query) => axios.post(`/api/user`, query)
export const updateUser = (id, userObj) => axios.put(`/api/user/${id}`, userObj)
export const updateProfile = (userObj) => axios.patch(`/api/user`, userObj)
export const removeUser = (id) => axios.delete(`/api/user/${id}`)
export const restoreUser = (id) => axios.patch(`/api/user/${id}`)

export const fetchUnsharedUsers = (queryObj) => axios.post(`/api/share/users`, queryObj)
export const fetchSharedUsers = (shareObj) => axios.post(`/api/share/shared-users`, shareObj)
export const shareContent = (shareObj) => axios.post(`/api/share/`, shareObj)
export const removeSharedUser = (shareObj) => axios.post(`/api/share/remove`, shareObj)
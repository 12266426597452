import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
    getMarketSegments, createMarketSegment, updateMarketSegment, cancelDuplicateMarketSegment, confirmDuplicateMarketSegment,
    duplicateMarketSegment, confirmRemoveMarketSegment, cancelRemoveMarketSegment, removeMarketSegment, exportMarketSegments, orderMarketSegment
} from '../../../actions/marketSegment'
import { showNotification } from '../../../actions/notification'
import { updateRenderedMarketSegment } from '../../../actions/breedingPipelines'
import { marketSegmentLookups } from '../../../constants'
import Box from '@material-ui/core/Box'
import Autocomplete from '@material-ui/lab/Autocomplete'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MaterialTable, { MTableActions, MTableEditField, MTableBodyRow } from 'material-table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableIcons from '../../molecules/TableIcons'
import TableRowOrder from '../../molecules/TableRowOrder';
import GetAppIcon from '@material-ui/icons/GetApp'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import UserDialog from '../../molecules/UserDialog'
import ShareContent from '../ShareContent';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles'
import TableCellTooltip from '../../molecules/TableCellTooltip'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const useStyles = makeStyles((theme) => ({
    table: {
        '& h6': {
            fontSize: "0.85rem"
        },
        '& div > div > div > div ': {
            marginBottom: "4px"
        },
        '& .MuiToolbar-regular': {
            minHeight: "48px"
        },
        '& th': {
            padding: "4px 10px",
            fontSize: "13px !important",
            border: "1px solid #b3b3b3 !important",
            lineHeight: "1rem"
        },
        '& td': {
            border: "1px solid #b9b9b9 !important",
        },
        '& tbody > tr': {
            height: "auto !important"
        },
        '& tbody > tr:first-child': {
            height: "38px !important"
        },
        '& tbody > tr > td:first-child, thead > tr:first-child > th:first-child': {
            position: "sticky",
            left: 0,
            minWidth: "81px",
            backgroundColor: "#ecf0f5",
            zIndex: 10
        },
        '& tbody > tr > td:nth-child(2), thead > tr:first-child > th:nth-child(2)': {
            position: "sticky",
            left: 101,
            minWidth: "50px",
            backgroundColor: "#ecf0f5",
            zIndex: 10
        },
        '& td input': {
            width: "75px !important"
        },
        '& td fieldset': {
            maxWidth: "30px !important",
            overflowX: "hidden"
        },
        '& td > div': {
            width: "auto !important"
        },
    },
    stickyNoColumn: {
        position: "sticky",
        left: "140px",
        backgroundColor: "#ecf0f5",
        zIndex: 10
    },
    soloAutocomplete: {
        '& input': {
            fontSize: "13px !important"
        }
    }
}))

const FormatDate = (value) => {
    const formattedDate = new Date(value).toLocaleString('en-us', { month: 'short', day: '2-digit', year: 'numeric' })
    return formattedDate
}

const MarketSegmentColumns = () => {
    const classes = useStyles()

    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">Actions</TableCell>
                <TableCell className={classes.stickyNoColumn} align="center">No</TableCell>
                <TableCell align="center">Center</TableCell>
                <TableCell align="center">Crop</TableCell>
                <TableCell align="center">Region</TableCell>
                <TableCell align="center">Sub-Region</TableCell>
                <TableCell align="center">Continent</TableCell>
                <TableCell align="center">Crop Type</TableCell>
                <TableCell align="center">Color</TableCell>
                <TableCell align="center">Where</TableCell>
                <TableCell align="center">How</TableCell>
                <TableCell align="center">Maturity</TableCell>
                <TableCell align="center">Other</TableCell>
                <TableCell align="center">Name</TableCell>
                <TableCell align="center">Code</TableCell>
                <TableCell align="center">Updated At</TableCell>
                <TableCell align="center">Created</TableCell>
            </TableRow>
        </TableHead>
    )
}

const AutocompleteInput = (cellProps) => {
    const autocompleteOptions = Object.keys(cellProps.columnDef.lookup)
    const [selectedValue] = useState(cellProps.value)
    const classes = useStyles()
    return (
        <Autocomplete
            id={cellProps.columnDef.field}
            freeSolo
            style={{ margin: "0px" }}
            options={autocompleteOptions.map((option) => option)}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option;
            }}
            defaultValue={selectedValue}
            onInputChange={(e, newValue) => {
                cellProps.onChange(newValue)
            }}
            renderInput={(params) => (
                <TextField {...params}
                    margin="normal"
                    className={classes.soloAutocomplete}
                    variant="standard"
                    fullWidth
                />
            )}
            renderOption={(option) => (
                <Box flex={1}>
                    <Box flexGrow={1}><Typography>{option}</Typography></Box>
                </Box>
            )}
        />
    )
}

const MarketSegment = (props) => {
    const history = useHistory()
    const marketSegmentState = useSelector((state) => state.marketSegment)
    const dispatch = useDispatch()
    const [marketSegments, setMarketSegments] = useState({
        data: [],
        breedingPipelineId: "",
        isLoading: false,
        duplicateMarketSegmentForm: {
            marketSegmentId: "",
            breedingPipelineId: "",
            open: false
        },
        removeMarketSegmentForm: {
            marketSegmentId: "",
            breedingPipelineId: "",
            open: false
        }
    })

    useEffect(() => {
        dispatch(getMarketSegments(props.breedingPipelineId, { sharedOnly: props.sharedOnly }))
        //logic to check which pipelines are expanded
        dispatch(updateRenderedMarketSegment(props.breedingPipelineId, true))
        return () => {
            //pathname is being checked to verify if user has navigated to a different page. If so,
            //the rendered market segments will not be removed from store
            if (history.location.pathname === "/" || history.location.pathname === "/shared")
                dispatch(updateRenderedMarketSegment(props.breedingPipelineId, false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.breedingPipelineId, dispatch])

    useEffect(() => {
        if (marketSegmentState.breedingPipelineId === props.breedingPipelineId) {
            setMarketSegments({ ...marketSegmentState })
        }
    }, [marketSegmentState, props.breedingPipelineId])

    const handleSortMarketSegment = (newOrderNo, previousOrderNo, BreedingPipelineId) => {
        setMarketSegments({ ...marketSegments, isLoading: true })
        dispatch(orderMarketSegment({ newOrderNo, previousOrderNo, BreedingPipelineId }, { sharedOnly: props.sharedOnly }))
    }

    const columns = [
        {
            title: 'No', field: 'recordNo', align: "center",
            cellStyle: {
                fontWeight: "bold",
                padding: "2px 0",
                position: "sticky",
                backgroundColor: "#ecf0f5",
                zIndex: 10
            }, editable: "never",
            render: rowData => <TableRowOrder
                id={rowData.BreedingPipelineId}
                order={rowData.recordNo}
                total={marketSegments.data.length}
                sort={handleSortMarketSegment}
                isDisabled={props.sharedOnly ?
                    (rowData.Shares[0] && (rowData.Shares[0].permission === 'OWNER' &&
                        (rowData.Shares[0].groupId === `MarketSegment-${marketSegments.breedingPipelineId}` || rowData.Shares[0].groupId === `BreedingPipeline-${marketSegments.breedingPipelineId}`)
                    ) ? false : true) : false}>
                {rowData.recordNo}
            </TableRowOrder>
        },
        {
            title: 'Center', field: 'center', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.center, render: rowData => rowData.center ? rowData.center : "-",
            editComponent: inputProps => (
                <AutocompleteInput {...inputProps} />
            )
        },
        {
            title: 'Crop', field: 'crop', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.crop, render: rowData => rowData.crop ? rowData.crop : "-",
            editComponent: inputProps => (
                <AutocompleteInput {...inputProps} />
            )
        },
        { title: 'Region', field: 'region', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.region, render: rowData => rowData.region ? rowData.region : "-" },
        { title: 'Sub-Region', field: 'subRegion', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.subRegion, render: rowData => rowData.subRegion ? rowData.subRegion : "-" },
        { title: 'Continent', field: 'continent', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.continent, render: rowData => rowData.continent ? rowData.continent : "-" },
        {
            title: 'Crop Type', field: 'cropType', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.cropType, render: rowData => rowData.cropType ? rowData.cropType : "-",
            editComponent: inputProps => (
                <AutocompleteInput {...inputProps} />
            )
        },
        {
            title: 'Color', field: 'color', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.color, render: rowData => rowData.color ? rowData.color : "-",
            editComponent: inputProps => (
                <AutocompleteInput {...inputProps} />
            )
        },
        { title: 'Where', field: 'altitude', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.where, render: rowData => rowData.altitude ? rowData.altitude : "-" },
        {
            title: 'How', field: 'how', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.how, render: rowData => rowData.how ? rowData.how : "-",
            editComponent: inputProps => (
                <AutocompleteInput {...inputProps} />
            )
        },
        { title: 'Maturity', field: 'maturity', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, lookup: marketSegmentLookups.maturity, render: rowData => rowData.maturity ? rowData.maturity : "-" },
        { title: 'Other', field: 'other', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, render: rowData => rowData.other ? rowData.other : "-" },
        {
            title: 'Name', field: 'name', align: "center",
            cellStyle: { backgroundColor: "#E3EFD9", minHeight: "36px", maxWidth: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" },
            editable: "never", render: rowData => rowData.name ? rowData.name : "-"
        },
        { title: 'Code', field: 'code', align: "center", cellStyle: { backgroundColor: "#E3EFD9" }, editable: "never", render: rowData => rowData.code ? rowData.code : "-" },
        { title: 'Updated At', field: 'createdAt', align: "center", cellStyle: { whiteSpace: "nowrap", backgroundColor: "#E3EFD9" }, editable: "never", render: rowData => FormatDate(rowData.updatedAt) },
        { title: 'Created', field: 'createdAt', align: "center", cellStyle: { whiteSpace: "nowrap", backgroundColor: "#E3EFD9" }, editable: "never", render: rowData => FormatDate(rowData.createdAt) },
    ];

    const handleDuplicateMarketSegment = async () => {
        await dispatch(duplicateMarketSegment(marketSegments.duplicateMarketSegmentForm.marketSegmentId, marketSegments.duplicateMarketSegmentForm.breedingPipelineId, { sharedOnly: props.sharedOnly }))
        props.reloadGrid()
    }

    const handleCancelDuplicateMarketSegment = () => {
        dispatch(cancelDuplicateMarketSegment())
    }

    const handleRemoveMarketSegment = async () => {
        await dispatch(removeMarketSegment(marketSegments.removeMarketSegmentForm.marketSegmentId, marketSegments.removeMarketSegmentForm.breedingPipelineId, { sharedOnly: props.sharedOnly }))
        props.reloadGrid()
    }

    const handleCancelRemoveMarketSegment = () => {
        dispatch(cancelRemoveMarketSegment())
    }

    const reloadMarketSegmentGrid = () => {
        dispatch(getMarketSegments(props.breedingPipelineId, { sharedOnly: props.sharedOnly }))
    }

    const classes = useStyles()
    return (
        <Box id={props.breedingPipelineId} px={0} className={classes.table} style={{ maxWidth: "92vw" }}>
            <UserDialog
                open={marketSegments.duplicateMarketSegmentForm.open}
                contentTitle="Are you sure you want to create a copy of the selected market segment?"
                contentText=""
                handleAction={handleDuplicateMarketSegment}
                handleCancel={handleCancelDuplicateMarketSegment}
            />
            <UserDialog
                open={marketSegments.removeMarketSegmentForm.open}
                contentTitle="Are you sure you want to remove the selected market segment?"
                contentText=""
                handleAction={handleRemoveMarketSegment}
                handleCancel={handleCancelRemoveMarketSegment}
            />
            <MaterialTable
                title="Market Segments"
                style={{ backgroundColor: "#ecf0f5" }}
                columns={columns}
                icons={TableIcons}
                isLoading={marketSegments.isLoading}
                options={{
                    search: false,
                    pageSize: 5,
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    exportButton: false,
                    columnsButton: false,
                    padding: "dense",
                    loadingType: "overlay",
                    toolbarButtonAlignment: "left",
                }}
                data={marketSegments.data}
                components={{
                    Header: props => (
                        <MarketSegmentColumns />
                    ),
                    Row: rowProps => {
                        const marketSegmentName = rowProps.data.name
                        return (
                            <TableCellTooltip title={marketSegmentName}>
                                <MTableBodyRow {...rowProps} />
                            </TableCellTooltip>
                        )
                    },
                    Actions: actionsProps =>
                        <>
                            <MTableActions {...actionsProps} />
                            {actionsProps.actions && ['toolbar', 'row'].includes(actionsProps.actions[0].position) &&
                                <>
                                    <Divider orientation="vertical" variant="fullWidth" flexItem style={{
                                        display: props.sharedOnly ? ((actionsProps.data && actionsProps.data.Shares[0]) && actionsProps.data.Shares[0].permission !== 'OWNER' ? "none" : "inline") : "inline"
                                    }} />
                                    <ShareContent
                                        count={actionsProps.data ? actionsProps.data.SharedCount : 0}
                                        reloadGrid={reloadMarketSegmentGrid}
                                        isDisabled={props.sharedOnly ? ((actionsProps.data && actionsProps.data.Shares[0]) && actionsProps.data.Shares[0].permission !== 'OWNER' ? true : false) : false}
                                        isHidden={props.sharedOnly ? ((actionsProps.data && actionsProps.data.Shares[0]) ? (actionsProps.data.Shares[0].permission !== 'OWNER' ? true : false) : true) : false}
                                        groupId={actionsProps.data ? `MarketSegment-${actionsProps.data.id}` : `MarketSegment-${marketSegments.breedingPipelineId}`}
                                        BreedingPipelineId={marketSegments.breedingPipelineId}
                                        marketSegmentIds={actionsProps.data ? [actionsProps.data.id] : marketSegments.data.map(marketSegment => marketSegment.id)}
                                        btnColor="primary"
                                        btnSize="small" />
                                </>
                            }
                        </>,
                    EditField: cellProps =>
                        (['center', 'crop', 'cropType', 'color', 'how'].includes(cellProps.columnDef.field)) ?
                            <AutocompleteInput {...cellProps} /> : <MTableEditField {...cellProps} />
                }}
                actions={
                    [
                        rowData => ({
                            icon: () => <LibraryAddIcon color="primary" />,
                            tooltip: "Create Duplicate Market Segment",
                            isFreeAction: false,
                            disabled: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            hidden: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            onClick: (event) => dispatch(confirmDuplicateMarketSegment(rowData.id, rowData.BreedingPipelineId))
                        }),
                        rowData => ({
                            icon: () => <DeleteSweepIcon color="action" />,
                            tooltip: "Delete Market Segment",
                            isFreeAction: false,
                            disabled: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            hidden: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            onClick: (event) => dispatch(confirmRemoveMarketSegment(rowData.id, rowData.BreedingPipelineId))
                        }),
                        {
                            icon: () => <GetAppIcon color="primary" />,
                            tooltip: "Export to CSV",
                            isFreeAction: true,
                            onClick: (event) => {
                                dispatch(exportMarketSegments(props.breedingPipelineId))
                            }
                        },
                        {
                            icon: () => <PictureAsPdfIcon color="primary" />,
                            tooltip: "Export to PDF",
                            isFreeAction: true,
                            onClick: (event) => {
                                const input = document.getElementById(props.breedingPipelineId);
                                html2canvas(input, {
                                    logging: false,
                                    scale: 1,
                                    x: input.getBoundingClientRect().x + window.pageXOffset,
                                    y: input.getBoundingClientRect().y + window.pageYOffset,
                                    width: input.getElementsByTagName("table")[0].offsetWidth + 170,
                                    windowWidth: input.getElementsByTagName("table")[0].offsetWidth + 170,
                                })
                                    .then((canvas) => {
                                        const imgData = canvas.toDataURL('image/png');
                                        const elementWidth = (input.getElementsByTagName("table")[0].offsetWidth + 120) * 0.265 //Returns value in (mm)                                    
                                        const elementHeight = input.offsetHeight * 0.265 //Returns value in (mm)
                                        const pdf = new jsPDF({
                                            orientation: "landscape",
                                            format: [elementWidth, elementHeight]
                                        });
                                        pdf.addImage(imgData, 'JPEG', 0, 0);
                                        pdf.save("Market_Segments.pdf");
                                    })
                            }
                        },
                    ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            newData.BreedingPipelineId = props.breedingPipelineId
                            if (props.sharedOnly && marketSegments.data[0].Shares[0].permission !== "OWNER") {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to add new market segment to this breeding pipeline" }))
                                reject()
                            } else {
                                dispatch(createMarketSegment(newData, { sharedOnly: props.sharedOnly }))
                                resolve(props.reloadGrid())
                            }
                        }),
                }}
                cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise((resolve, reject) => {
                            const updatedMarketSegmentObj = {
                                ...rowData,
                                [columnDef.field]: newValue
                            }
                            if (props.sharedOnly && rowData.Shares[0].permission === "VIEWER") {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to make changes to this market segment" }))
                                reject()
                            } else {
                                //TODO Fix state update here
                                setMarketSegments({ ...marketSegments, isLoading: true })
                                dispatch(updateMarketSegment(updatedMarketSegmentObj, { sharedOnly: props.sharedOnly }))
                                resolve()
                            }
                        });
                    }
                }}
            />
        </Box>
    )
}

export default MarketSegment;
import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        maxWidth: 175,
        paddingTop: "7px",
        border: '1px solid #dadde9',
    },
    arrow: {
        color: theme.palette.primary.main,
    },
}))(Tooltip);

const BreedingSchemeFlowchartTooltip = (props) => {

    return <CustomTooltip arrow placement="top-end" title={
        <>
            <Typography variant="button" style={{ fontSize: 11, fontWeight: "bold" }}>
                {props.title}
            </Typography>            
            <Typography variant="body1" style={{ paddingTop: "5px", fontSize: 12 }}>
                {props.description}
            </Typography>
        </>
    } >
        {props.children}
    </CustomTooltip>
}

export default BreedingSchemeFlowchartTooltip
import React from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom'
import { logout } from '../../../actions/userAuth'
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import LoggedAccount from '../../molecules/LoggedAccount'

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: "50px",
    },
    toolbar: {
        minHeight: "50px"
    },
    title: {
        flexGrow: 1,
    },
    content: {
        flexGrow: 1,
        fontWeight: "normal",
        fontSize: "0.8rem"
    },
    link: {
        textDecoration: "none",
        color: "inherit"
    }
}))

const Header = (props) => {
    const userAuth = useSelector((state) => state.userAuth)
    const dispatch = useDispatch()
    const classes = useStyles()

    const handleLogout = () => {
        dispatch(logout())
    }

    return (
        <AppBar className={classes.root} variant="outlined" position="static">
            <Toolbar className={classes.toolbar}>
                <Typography variant="h4" className={classes.title}>
                    <Link className={classes.link} to="/">Breeding Pipeline Manager</Link>
                </Typography>
                {props.content && Object.keys(props.content).length > 0 &&
                    <Typography className={classes.content} variant="button">
                        Species: <u><b>{props.content.breedingPipeline}</b></u> &nbsp;-&nbsp; {props.content.headerPage === "breedingScheme" ? "Breeding Scheme Name" : "Product Profile"} <u><b>{props.content.name}</b></u>
                    </Typography>
                }
                <LoggedAccount className="appTour-step-1" user={userAuth.user} handleLogout={handleLogout} />
            </Toolbar>
        </AppBar >
    )
}

export default Header;
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    getProductProfileTraits, createProductProfileTrait, updateProductProfileTrait, cancelDuplicateProductProfileTrait, confirmDuplicateProductProfileTrait,
    duplicateProductProfileTrait, confirmRemoveProductProfileTrait, cancelRemoveProductProfileTrait, removeProductProfileTrait, exportProductProfileTraits, orderProductProfileTrait
} from '../../../actions/productProfileTraits'
import { productProfileTraitLookups } from '../../../constants'
import { showNotification } from '../../../actions/notification'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import MaterialTable from 'material-table'
import { MTableToolbar } from 'material-table'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import TableIcons from '../../molecules/TableIcons'
import TableRowOrder from '../../molecules/TableRowOrder';
import GetAppIcon from '@material-ui/icons/GetApp'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit';
import UserDialog from '../../molecules/UserDialog'
import makeStyles from '@material-ui/core/styles/makeStyles'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        backgroundColor: "#fff"
    },
    table: {
        '& h6': {
            fontSize: "0.85rem"
        },
        '& div > div > div > div ': {
            marginBottom: "4px"
        },
        '& .MuiToolbar-regular': {
            minHeight: "48px"
        },
        '& th': {
            padding: "10px 10px",
            fontSize: "13px !important",
            border: "1px solid #b3b3b3 !important",
            lineHeight: "1rem"
        },
        '& td': {
            border: "1px solid #b9b9b9 !important",
            padding: "2px 24px 2px 16px",
            fontSize: "13px !important"
        },
        '& tbody > tr': {
            height: "auto !important",
            backgroundColor: "#fff"
        },
        '& tbody > tr:first-child': {
            height: "38px !important"
        },
        '& td input': {
            width: "75px !important"
        },
        '& td > div': {
            width: "auto !important"
        },
    }
}))

const rowStyles = (traitType) => {
    switch (traitType) {
        case "Agronomic":
            return "#f1f8e9"
        case "Quality":
            return "#d0d4ed"
        case "Morphological":
            return "#c1e5e2"
        case "Phenological":
            return "#ffe6c1"
        case "AbioticStress":
            return "#ffebee"
        default:
            return "#e3f2fd"
    }
}

const ProductProfileTraitColumns = () => {
    return (
        <TableHead>
            <TableRow>
                <TableCell align="center">Actions</TableCell>
                <TableCell align="center">No</TableCell>
                <TableCell align="center">Trait</TableCell>
                <TableCell align="center">Trait Type</TableCell>
                <TableCell colSpan={3} align="center">Unit or Scale</TableCell>
                <TableCell align="center">Threshold or Range</TableCell>
                <TableCell align="center">Benchmark Material</TableCell>
            </TableRow>
        </TableHead>
    )
}

const ProductProfileTrait = (props) => {
    const productProfileTraitState = useSelector((state) => state.productProfileTraits)
    const dispatch = useDispatch()
    const [productProfileTraits, setProductProfileTraits] = useState({
        data: [],
        productProfileId: "",
        isLoading: false,
        permission: "VIEWER",
        success: false,
        message: null,
        duplicateProductProfileTraitForm: {
            productProfileTraitId: "",
            open: false
        },
        removeProductProfileTraitForm: {
            productProfileTraitId: "",
            open: false
        }
    })

    useEffect(() => {
        dispatch(getProductProfileTraits(props.productProfileId))
    }, [dispatch, props.productProfileId])

    useEffect(() => {
        setProductProfileTraits({ ...productProfileTraitState })
    }, [productProfileTraitState])

    const handleSortProductProfileTrait = (newOrderNo, previousOrderNo, ProductProfileId) => {
        dispatch(orderProductProfileTrait({ newOrderNo, previousOrderNo, ProductProfileId }))
    }

    const columns = [
        {
            title: 'No', field: 'recordNo', align: "center",
            cellStyle: { width: "5%", padding: "0" }, editable: "never",
            render: rowData => <TableRowOrder
                id={rowData.ProductProfileId}
                order={rowData.recordNo}
                total={productProfileTraits.data.length}
                sort={handleSortProductProfileTrait}>
                {rowData.recordNo}
            </TableRowOrder>
        },
        { title: 'Trait', field: 'trait', align: "center", render: rowData => rowData.trait ? rowData.trait : "-" },
        { title: 'Trait Type', field: 'traitType', align: "center", lookup: productProfileTraitLookups.traitType, render: rowData => rowData.traitType ? rowData.traitType : "-" },
        { title: 'Unit or Scale', field: 'unit1', align: "center", lookup: productProfileTraitLookups.unit1, render: rowData => rowData.unit1 ? rowData.unit1 : "-" },
        { title: 'Unit or Scale', field: 'unit2', align: "center", lookup: productProfileTraitLookups.unit2, render: rowData => rowData.unit2 ? rowData.unit2 : "-" },
        { title: 'Unit or Scale', field: 'unit3', align: "center", lookup: productProfileTraitLookups.unit3, render: rowData => rowData.unit3 ? rowData.unit3 : "-" },
        { title: 'Threshold or range', field: 'threshold', align: "center", render: rowData => rowData.threshold ? rowData.threshold : "-" },
        { title: 'Benchmark material', field: 'benchmarkMaterial', align: "center", render: rowData => rowData.benchmarkMaterial ? rowData.benchmarkMaterial : "-" },
    ];

    const handleDuplicateProductProfileTrait = async () => {
        await dispatch(duplicateProductProfileTrait(productProfileTraits.duplicateProductProfileTraitForm.productProfileTraitId, props.productProfileId))
    }

    const handleCancelDuplicateProductProfileTrait = () => {
        dispatch(cancelDuplicateProductProfileTrait())
    }

    const handleRemoveProductProfileTrait = async () => {
        await dispatch(removeProductProfileTrait(productProfileTraits.removeProductProfileTraitForm.productProfileTraitId, props.productProfileId))
    }

    const handleCancelRemoveProductProfileTrait = () => {
        dispatch(cancelRemoveProductProfileTrait())
    }

    const classes = useStyles()
    return (
        <Box id={props.productProfileId} px={0} className={classes.table}>
            <UserDialog
                open={productProfileTraits.duplicateProductProfileTraitForm.open}
                contentTitle="Are you sure you want to create a copy of the selected product profile trait?"
                contentText=""
                handleAction={handleDuplicateProductProfileTrait}
                handleCancel={handleCancelDuplicateProductProfileTrait}
            />
            <UserDialog
                open={productProfileTraits.removeProductProfileTraitForm.open}
                contentTitle="Are you sure you want to remove the selected product profile trait?"
                contentText=""
                handleAction={handleRemoveProductProfileTrait}
                handleCancel={handleCancelRemoveProductProfileTrait}
            />
            <MaterialTable
                title={
                    <>
                        {
                            productProfileTraits.permission === 'VIEWER' ?
                                <Tooltip title="Viewer [readonly access]" placement="top">
                                    <VisibilityIcon color="primary" style={{ verticalAlign: "middle", marginRight: "7px" }} />
                                </Tooltip> :
                                <Tooltip title="Editor [read/write access]" placement="top">
                                    <EditIcon color="secondary" style={{ verticalAlign: "middle", marginRight: "7px" }} />
                                </Tooltip>
                        }
                        <Typography variant="subtitle2" style={{ fontSize: "0.79rem", display: "inline" }}>
                            PRODUCT PROFILE TRAITS
                        </Typography>
                    </>
                }
                style={{ backgroundColor: "#ecf0f5" }}
                columns={columns}
                icons={TableIcons}
                isLoading={productProfileTraits.isLoading}
                options={{
                    search: false,
                    pageSize: 10,
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    exportButton: false,
                    columnsButton: false,
                    padding: "dense",
                    loadingType: "overlay",
                    toolbarButtonAlignment: "left",
                    rowStyle: rowData => ({
                        backgroundColor: rowData.traitType ? rowStyles(rowData.traitType) : "white"
                    })
                }}
                data={productProfileTraits.data}
                components={{
                    Toolbar: props => (
                        <Box className={classes.toolbar}>
                            <MTableToolbar {...props} />
                        </Box>
                    ),
                    Header: props => (
                        <ProductProfileTraitColumns />
                    )
                }}
                actions={
                    [
                        rowData => ({
                            icon: () => <LibraryAddIcon color="primary" />,
                            tooltip: "Create a Copy",
                            isFreeAction: false,
                            hidden: productProfileTraits.permission === "VIEWER",
                            disabled: productProfileTraits.permission === "VIEWER",
                            onClick: (event) => dispatch(confirmDuplicateProductProfileTrait(rowData.id))
                        }),
                        rowData => ({
                            icon: () => <DeleteSweepIcon color="action" />,
                            tooltip: "Delete Product Profile Trait",
                            isFreeAction: false,
                            hidden: productProfileTraits.permission === "VIEWER",
                            disabled: productProfileTraits.permission === "VIEWER",
                            onClick: (event) => dispatch(confirmRemoveProductProfileTrait(rowData.id))
                        }),
                        {
                            icon: () => <GetAppIcon color="primary" />,
                            tooltip: "Export to CSV",
                            isFreeAction: true,
                            onClick: (event) => {
                                dispatch(exportProductProfileTraits(props.productProfileId))
                            }
                        },
                        {
                            icon: () => <PictureAsPdfIcon color="primary" />,
                            tooltip: "Export to PDF",
                            isFreeAction: true,
                            onClick: (event) => {
                                const input = document.getElementById(props.productProfileId);
                                html2canvas(input, {
                                    logging: false,
                                    scale: 1,
                                    x: input.getBoundingClientRect().x + window.pageXOffset,
                                    y: input.getBoundingClientRect().y + window.pageYOffset,
                                    width: input.getElementsByTagName("table")[0].offsetWidth + 150,
                                    windowWidth: input.getElementsByTagName("table")[0].offsetWidth + 150,
                                })
                                    .then((canvas) => {
                                        const imgData = canvas.toDataURL('image/png');
                                        const elementWidth = (input.getElementsByTagName("table")[0].offsetWidth + 120) * 0.265 //Returns value in (mm)                                    
                                        const elementHeight = input.offsetHeight * 0.265 //Returns value in (mm)
                                        const pdf = new jsPDF({
                                            orientation: "landscape",
                                            format: [elementWidth, elementHeight]
                                        });
                                        pdf.addImage(imgData, 'JPEG', 0, 0);
                                        pdf.save("Product_Profiles.pdf");
                                    })
                            }
                        },
                    ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            newData.ProductProfileId = props.productProfileId
                            if (productProfileTraits.permission === "VIEWER") {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to add new Product Profile Trait." }))
                                reject()
                            } else {
                                dispatch(createProductProfileTrait(newData))
                                resolve()
                            }
                        }),
                }}
                cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise((resolve, reject) => {
                            const updatedProductProfileTraitObj = {
                                ...rowData,
                                [columnDef.field]: newValue
                            }
                            if (productProfileTraits.permission === "VIEWER") {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to make changes to this Product Profile" }))
                                reject()
                            } else {
                                dispatch(updateProductProfileTrait(updatedProductProfileTraitObj))
                                resolve()
                            }
                        });
                    }
                }}
            />
        </Box>
    )
}

export default ProductProfileTrait;
import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ALL_MARKET_SEGMENT_RESPONSE, CREATE_MARKET_SEGMENT_RESPONSE,
    UPDATE_MARKET_SEGMENT_RESPONSE, CONFIRM_DUPLICATE_MARKET_SEGMENT, CANCEL_DUPLICATE_MARKET_SEGMENT,
    DUPLICATE_MARKET_SEGMENT_RESPONSE, CONFIRM_REMOVE_MARKET_SEGMENT, CANCEL_REMOVE_MARKET_SEGMENT,
    REMOVE_MARKET_SEGMENT_RESPONSE, LOADING_BREEDING_PIPELINE
} from '../constants/actionTypes'

//Action Creators
export const getMarketSegments = (id, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.fetchMarketSegments(id, queryObj)
        dispatch({ type: FETCH_ALL_MARKET_SEGMENT_RESPONSE, payload: { ...data, breedingPipelineId: id } })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_ALL_MARKET_SEGMENT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const createMarketSegment = (marketSegmentObj, queryObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_PIPELINE })
        const { data } = await api.createMarketSegment(marketSegmentObj)
        dispatch({ type: CREATE_MARKET_SEGMENT_RESPONSE })
        if (data.success) dispatch(getMarketSegments(marketSegmentObj.BreedingPipelineId, queryObj))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: CREATE_MARKET_SEGMENT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateMarketSegment = (marketSegmentObj, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.updateMarketSegment(marketSegmentObj.id, marketSegmentObj)
        if (data.success) dispatch(getMarketSegments(marketSegmentObj.BreedingPipelineId, queryObj))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_MARKET_SEGMENT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmDuplicateMarketSegment = (id, breedingPipelineId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_DUPLICATE_MARKET_SEGMENT, payload: {
            duplicateMarketSegmentForm: {
                marketSegmentId: id,
                breedingPipelineId: breedingPipelineId,
                open: true
            }
        }
    })
}

export const cancelDuplicateMarketSegment = () => async (dispatch) => {
    dispatch({
        type: CANCEL_DUPLICATE_MARKET_SEGMENT, payload: {
            duplicateMarketSegmentForm: {
                marketSegmentId: "",
                breedingPipelineId: "",
                open: false
            }
        }
    })
}

export const duplicateMarketSegment = (id, breedingPipelineId, queryObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_PIPELINE })
        dispatch({
            type: DUPLICATE_MARKET_SEGMENT_RESPONSE, payload: {
                duplicateMarketSegmentForm: {
                    marketSegmentId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        const { data } = await api.duplicateMarketSegment(id)
        if (data.success) {
            await dispatch(getMarketSegments(breedingPipelineId, queryObj))
        }
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
    } catch (error) {
        dispatch({
            type: DUPLICATE_MARKET_SEGMENT_RESPONSE, payload: {
                duplicateMarketSegmentForm: {
                    marketSegmentId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const orderMarketSegment = (orderObj, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.orderMarketSegment(orderObj)
        if (data.success) dispatch(getMarketSegments(orderObj.BreedingPipelineId, queryObj))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_MARKET_SEGMENT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveMarketSegment = (id, breedingPipelineId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_MARKET_SEGMENT, payload: {
            removeMarketSegmentForm: {
                marketSegmentId: id,
                breedingPipelineId: breedingPipelineId,
                open: true
            }
        }
    })
}

export const cancelRemoveMarketSegment = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_MARKET_SEGMENT, payload: {
            removeMarketSegmentForm: {
                marketSegmentId: "",
                breedingPipelineId: "",
                open: false
            }
        }
    })
}

export const removeMarketSegment = (id, breedingPipelineId, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.removeMarketSegment(id)
        dispatch({
            type: REMOVE_MARKET_SEGMENT_RESPONSE, payload: {
                removeMarketSegmentForm: {
                    marketSegmentId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        if (data.success) dispatch(getMarketSegments(breedingPipelineId, queryObj))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_MARKET_SEGMENT_RESPONSE, payload: {
                removeMarketSegmentForm: {
                    marketSegmentId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const exportMarketSegments = (breedingPipelineId) => async (dispatch) => {
    try {
        const { data } = await api.exportMarketSegment(breedingPipelineId)
        if (data.success) window.open(`${window.location.origin}/api/market-segment/export/${breedingPipelineId}`)
        else dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
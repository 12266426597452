import { useDispatch } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Navigation from '../../organisms/Navigation'
import ProfileDetail from '../../organisms/ProfileDetail'
import ProfileForm from '../../organisms/ProfileForm'
import { getProfile } from '../../../actions/users'

const ProfilePage = () => {
    const dispatch = useDispatch()
    dispatch(getProfile())
    return (
        <>
            <Header />
            <Box my={2} pb={7} display="flex">
                <Navigation />
                <Box ml={2} width="75%">
                    <Grid container spacing={2}>
                        <Grid md={4} xs={12} item>
                            <ProfileDetail />
                        </Grid>
                        <Grid md={8} xs={12} item>
                            <ProfileForm />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Footer />
        </>
    )
}

export default ProfilePage;
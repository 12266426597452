import { useState } from 'react'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Alert from '@material-ui/lab/Alert';
import CircularProgress from '@material-ui/core/CircularProgress';
import LockIcon from '@material-ui/icons/Lock'
import { Link } from "react-router-dom"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    loginForm: {
        marginTop: "10px",
        padding: "30px 15px",
        borderRadius: "10px",
        border: "1px solid #d3d3d3",
        backgroundColor: "white"
    },
    textField: {
        marginBottom: "20px",
    },
}))

const ChangePasswordForm = (props) => {
    const [changePasswordObj, setChangePasswordObj] = useState({
        oldPassword: "",
        newPassword: "",
        confirmPassword: "",
        passwordMatch: true,
    })
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const classes = useStyles()

    const passwordMatch = () => {
        return changePasswordObj.newPassword === changePasswordObj.confirmPassword
    }

    const handleChange = (e) => {
        setChangePasswordObj({
            ...changePasswordObj,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (passwordMatch()) {
            setChangePasswordObj({
                ...changePasswordObj,
                passwordMatch: true
            })
            props.handleChangePassword(changePasswordObj)
        }
        else
            setChangePasswordObj({
                ...changePasswordObj,
                passwordMatch: false
            })
    }

    return (
        <Box width="90%" maxWidth="480px" mt={smallScreen ? 4 : 0} >
            <Typography variant="h5">Change Password</Typography>
            {
                (!props.changePasswordState.success && props.changePasswordState.message) &&
                <Alert severity="error">{props.changePasswordState.message}</Alert>
            }
            <form className={classes.loginForm} autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><LockIcon /></Grid>
                    <Grid item xs={10}>
                        <TextField
                            id="oldPassword"
                            type="password"
                            label="Old Password"
                            value={changePasswordObj.oldPassword}
                            onChange={handleChange}
                            placeholder="Enter Old Password" fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><LockIcon /></Grid>
                    <Grid item xs={10}>
                        <TextField
                            id="newPassword"
                            type="password"
                            required
                            label="New Password"
                            value={changePasswordObj.newPassword}
                            onChange={handleChange}
                            placeholder="Enter New Password" fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><CheckCircleOutlineIcon /></Grid>
                    <Grid item xs={10}>
                        <TextField
                            id="confirmPassword"
                            error={!changePasswordObj.passwordMatch}
                            helperText={!changePasswordObj.passwordMatch && "Password doesn't match"}
                            required
                            type="password"
                            label="Confirm Password"
                            value={changePasswordObj.confirmPassword}
                            onChange={handleChange}
                            placeholder="Confirm Password" fullWidth />
                    </Grid>
                </Grid>
                <Box mb={1}>
                    <Button
                        type="submit"
                        disabled={props.changePasswordState.isLoading}
                        variant="contained" color="secondary" fullWidth>
                        Change Password
                        {
                            props.changePasswordState.isLoading &&
                            <Box position="absolute" top="20%" right="25%">
                                <CircularProgress color="primary" size={20} thickness={5} />
                            </Box>
                        }
                    </Button>
                </Box>
            </form>
            <Box textAlign="center" mt={2}>
                <Typography>
                    Return to Homepage - <Link to="/" color="secondary">Home</Link>
                </Typography>
            </Box>
        </Box>
    )
}

export default ChangePasswordForm;
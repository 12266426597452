import * as api from '../api'
import { showNotification } from './notification'
import {
    FETCH_BREEDING_SCHEME_SETTINGS_RESPONSE, LOADING_BREEDING_STAGE,
    UPDATE_BREEDING_SCHEME_SETTINGS_RESPONSE, LOADING_BREEDING_SCHEME_SETTINGS
} from '../constants/actionTypes'
import { reloadBreedingStages } from './breedingStage'

//Action Creators
export const getSchemeSettings = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_SCHEME_SETTINGS })
        const { data } = await api.fetchBreedingSchemeSettings(id)
        dispatch({ type: FETCH_BREEDING_SCHEME_SETTINGS_RESPONSE, payload: data })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_BREEDING_SCHEME_SETTINGS_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateSchemeSettings = (breedingSchemeSettingsObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_SCHEME_SETTINGS })
        const { data } = await api.updateBreedingSchemeSettings(breedingSchemeSettingsObj.settings.id, breedingSchemeSettingsObj)
        dispatch({ type: UPDATE_BREEDING_SCHEME_SETTINGS_RESPONSE, payload: data })
        if (data.success) {
            dispatch({ type: LOADING_BREEDING_STAGE })
            await dispatch(reloadBreedingStages(breedingSchemeSettingsObj.settings.BreedingSchemeId))
        }
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_SCHEME_SETTINGS_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
import { createSelector } from "reselect";

const breedingStagesPipeline = state => state.breedingStage.breedingPipeline;

export const selectBreedingStagesPipeline = createSelector(breedingStagesPipeline, stagesPipeline => stagesPipeline)

const breedingSchemePermission = state => state.breedingStage.permission

export const selectBreedingSchemePermission = createSelector(breedingSchemePermission, schemePermission => schemePermission)

const breedingStagesData = state => state.breedingStage.data;

export const selectBreedingStagesData = createSelector(breedingStagesData, stagesData => stagesData)

const breedingStagesIsLoading = state => state.breedingStage.isLoading;

export const selectBreedingStagesIsLoading = createSelector(breedingStagesIsLoading, stagesIsLoading => stagesIsLoading)
import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ALL_BREEDING_STAGE_REQUEST, FETCH_ALL_BREEDING_STAGE_RESPONSE, FETCH_BREEDING_PIPELINE_RESPONSE,
    LOADING_BREEDING_STAGE, CREATE_BREEDING_STAGE_RESPONSE, UPDATE_BREEDING_STAGE_RESPONSE,
    UPDATE_BREEDING_STAGE_CELLS_REQUEST, UPDATE_BREEDING_STAGE_CELLS_RESPONSE, CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST,
    CONFIRM_DUPLICATE_STAGE, CANCEL_DUPLICATE_STAGE, DUPLICATE_STAGE_RESPONSE,
    OPEN_UPDATE_TRAITS, UPDATE_TRAITS_RESPONSE, CANCEL_UPDATE_TRAITS,
    CONFIRM_REMOVE_ITEM, CANCEL_REMOVE_ITEM, REMOVE_ITEM_RESPONSE, BREEDING_STAGE_TOGGLE_COLUMN
} from '../constants/actionTypes';

//Action Creators

export const toggleBreedingStagesColumn = (hiddenColumns, breedingSchemeId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        //Update the UI state with latest data from server and toggle column        
        await dispatch({ type: BREEDING_STAGE_TOGGLE_COLUMN, payload: hiddenColumns })
        await dispatch(reloadBreedingStages(breedingSchemeId))
    } catch (error) {
        console.log(error)
    }
}

export const getBreedingStages = (id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_REQUEST })
        const { data } = await api.fetchBreedingStages(id)
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_RESPONSE, payload: data })
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_RESPONSE, payload: data })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const getBreedingPipeline = (id) => async (dispatch) => {
    try {
        const { data } = await api.fetchBreedingPipeline(id)
        dispatch({ type: FETCH_BREEDING_PIPELINE_RESPONSE, payload: data })
    } catch (error) {
        dispatch({ type: FETCH_BREEDING_PIPELINE_RESPONSE })
    }
}

//Expected argument is the selected Breeding Scheme Id
export const reloadBreedingStages = (id) => async (dispatch) => {
    try {
        const { data } = await api.fetchBreedingStages(id)
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_RESPONSE, payload: data })
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_RESPONSE, payload: data })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_ALL_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
//Expected argument is the selected Breeding Scheme Id
export const refreshBreedingStagesTable = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch(reloadBreedingStages(id))
    } catch (error) {
        console.log(error)
    }
}

export const createBreedingStage = (breedingStageObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.createBreedingStage(breedingStageObj)
        if (data.success) await dispatch(reloadBreedingStages(breedingStageObj.BreedingSchemeId))
        else dispatch({ type: CREATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: CREATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

const updateBreedingStageTableCells = (id) => async (dispatch) => {
    try {
        const { data } = await api.fetchBreedingStages(id)
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_RESPONSE, payload: data })
        !data.success && dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateBreedingStage = (breedingStageObj) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_BREEDING_STAGE_CELLS_REQUEST })
        const { data } = await api.updateBreedingStage(breedingStageObj)
        if (data.success) dispatch(updateBreedingStageTableCells(breedingStageObj.BreedingSchemeId))
        else dispatch({ type: CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST })
        !data.success && dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const orderBreedingStage = (orderObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.orderBreedingStage(orderObj)
        if (data.success) dispatch(reloadBreedingStages(orderObj.BreedingSchemeId))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmDuplicateBreedingStage = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_DUPLICATE_STAGE, payload: {
            duplicateStageForm: {
                breedingStageId: id,
                open: true
            }
        }
    })
}

export const cancelDuplicateBreedingStage = (id) => async (dispatch) => {
    dispatch({
        type: CANCEL_DUPLICATE_STAGE, payload: {
            duplicateStageForm: {
                breedingStageId: "",
                open: false
            }
        }
    })
}

export const duplicateBreedingStage = (id, breedingSchemeId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: DUPLICATE_STAGE_RESPONSE, payload: {
                duplicateStageForm: {
                    breedingStageId: "",
                    open: false
                }
            }
        })
        const { data } = await api.duplicateBreedingStage(id)
        if (data.success) dispatch(reloadBreedingStages(breedingSchemeId))
        else dispatch({ type: DUPLICATE_STAGE_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: DUPLICATE_STAGE_RESPONSE, payload: {
                duplicateStageForm: {
                    breedingStageId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const addTraits = (traitObj) => async (dispatch) => {
    try {
        await dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.addTraits(traitObj.BreedingSchemeId, traitObj)
        if (data.success) await dispatch(reloadBreedingStages(traitObj.BreedingSchemeId))
        else dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const openUpdateTraits = (id, breedingSchemeId, columnName, value) => async (dispatch) => {
    dispatch({
        type: OPEN_UPDATE_TRAITS, payload: {
            updateTraitsForm: {
                traitsGroupId: id,
                BreedingSchemeId: breedingSchemeId,
                columnName: columnName,
                costForPhenotypingTrait: value,
                open: true
            }
        }
    })
}

export const updateTraits = (traitsObj) => async (dispatch) => {
    try {
        dispatch({
            type: UPDATE_TRAITS_RESPONSE, payload: {
                updateTraitsForm: {
                    traitsGroupId: "",
                    BreedingSchemeId: "",
                    columnName: "",
                    costForPhenotypingTrait: 0,
                    open: false
                },
            }
        })
        dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.updateTraits(traitsObj.traitsGroupId, traitsObj)
        if (data.success) await dispatch(reloadBreedingStages(traitsObj.BreedingSchemeId))
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: UPDATE_TRAITS_RESPONSE, payload: {
                updateTraitsForm: {
                    traitsGroupId: "",
                    BreedingSchemeId: "",
                    columnName: "",
                    costForPhenotypingTrait: 0,
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const cancelUpdateTraits = () => async (dispatch) => {
    dispatch({
        type: CANCEL_UPDATE_TRAITS, payload: {
            updateTraitsForm: {
                traitsGroupId: "",
                BreedingSchemeId: "",
                columnName: "",
                costForPhenotypingTrait: 0,
                open: false
            }
        }
    })
}

export const confirmRemoveTraits = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removeTraitsForm: {
                traitsGroupId: id,
                open: true
            }
        }
    })
}

export const cancelRemoveTraits = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removeTraitsForm: {
                traitsGroupId: "",
                open: false
            }
        }
    })
}

export const removeTraits = (id, breedingSchemeId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeTraitsForm: {
                    traitsGroupId: "",
                    open: false
                }
            }
        })
        const { data } = await api.removeTraits(id, breedingSchemeId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeTraitsForm: {
                    traitsGroupId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const addCrossingPool = (breedingSchemeId) => async (dispatch) => {
    try {
        await dispatch({ type: LOADING_BREEDING_STAGE })
        const { data } = await api.addCrossingPool(breedingSchemeId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId))
        else dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_STAGE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemovePools = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removePoolsForm: {
                poolsGroupId: id,
                open: true
            }
        }
    })
}

export const cancelRemovePools = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removePoolsForm: {
                poolsGroupId: "",
                open: false
            }
        }
    })
}

export const removePools = (id, breedingSchemeId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removePoolsForm: {
                    poolsGroupId: "",
                    open: false
                }
            }
        })
        const { data } = await api.removeCrossingPool(id, breedingSchemeId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removePoolsForm: {
                    poolsGroupId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}


export const confirmRemoveBreedingStage = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_ITEM, payload: {
            removeStageForm: {
                breedingStageId: id,
                open: true
            }
        }
    })
}

export const cancelRemoveBreedingStage = (id) => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_ITEM, payload: {
            removeStageForm: {
                breedingStageId: "",
                open: false
            }
        }
    })
}

export const removeBreedingStage = (id, breedingSchemeId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_STAGE })
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeStageForm: {
                    breedingStageId: "",
                    open: false
                }
            }
        })
        const { data } = await api.removeBreedingStage(id, breedingSchemeId)
        if (data.success) await dispatch(reloadBreedingStages(breedingSchemeId))
        else dispatch({ type: REMOVE_ITEM_RESPONSE, payload: { isLoading: false } })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_ITEM_RESPONSE, payload: {
                removeStageForm: {
                    breedingStageId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const exportBreedingStages = (breedingStagesObj) => async (dispatch) => {
    try {
        if (breedingStagesObj.length) {
            const { data } = await api.exportBreedingStages(breedingStagesObj[0].BreedingSchemeId, breedingStagesObj)
            if (data.success) window.open(`${window.location.origin}/api/breeding-stage/export/${breedingStagesObj[0].BreedingSchemeId}`)
            else dispatch(showNotification({ type: "error", message: data.message }))
        }
        else dispatch(showNotification({ type: "error", message: "Cannot export empty data!" }))
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
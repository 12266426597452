import {
    FETCH_ALL_PRODUCT_PROFILE_RESPONSE, CREATE_PRODUCT_PROFILE_RESPONSE, UPDATE_PRODUCT_PROFILE_RESPONSE,
    CONFIRM_DUPLICATE_PRODUCT_PROFILE, CANCEL_DUPLICATE_PRODUCT_PROFILE, DUPLICATE_PRODUCT_PROFILE_RESPONSE,
    REMOVE_PRODUCT_PROFILE_RESPONSE, CONFIRM_REMOVE_PRODUCT_PROFILE, CANCEL_REMOVE_PRODUCT_PROFILE
} from '../constants/actionTypes'

const initialState = {
    data: [],
    availableMarketSegments: [],
    productProfileId: "",
    isLoading: false,
    success: false,
    message: null,
    duplicateProductProfileForm: {
        productProfileId: "",
        breedingPipelineId: "",
        open: false
    },
    removeProductProfileForm: {
        productProfileId: "",
        breedingPipelineId: "",
        open: false
    }

}
const reducer = (productProfile = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_PRODUCT_PROFILE_RESPONSE:
            if (action.payload.data)
                action.payload.data.map((ms, idx) => ms.recordNo = idx + 1)
            return {
                ...productProfile,
                ...action.payload,
                isLoading: false
            }
        case CREATE_PRODUCT_PROFILE_RESPONSE:
            return {
                ...productProfile,
                isLoading: false
            }
        case UPDATE_PRODUCT_PROFILE_RESPONSE:
            return {
                ...productProfile,
                isLoading: false
            }
        case CONFIRM_DUPLICATE_PRODUCT_PROFILE:
            return {
                ...productProfile,
                ...action.payload
            }
        case CANCEL_DUPLICATE_PRODUCT_PROFILE:
            return {
                ...productProfile,
                ...action.payload
            }
        case DUPLICATE_PRODUCT_PROFILE_RESPONSE:
            return {
                ...productProfile,
                ...action.payload
            }
        case CONFIRM_REMOVE_PRODUCT_PROFILE:
            return {
                ...productProfile,
                ...action.payload
            }
        case CANCEL_REMOVE_PRODUCT_PROFILE:
            return {
                ...productProfile,
                ...action.payload
            }
        case REMOVE_PRODUCT_PROFILE_RESPONSE:
            return {
                ...productProfile,
                ...action.payload,
                isLoading: false
            }
        default:
            return productProfile
    }
}

export default reducer
import { SHOW_NOTIFICATION, CLOSE_NOTIFICATION, RESET_NOTIFICATION } from '../constants/actionTypes'

//Action Creators
export const showNotification = (messageObj) => async (dispatch) => {
    try {
        dispatch({
            type: SHOW_NOTIFICATION,
            payload: {
                open: true,
                type: messageObj.type,
                message: messageObj.message
            }
        })
    } catch (error) {
        console.log(error.message)
    }
}

export const closeNotification = () => async (dispatch) => {
    try {
        dispatch({ type: CLOSE_NOTIFICATION })
    } catch (error) {
        console.log(error.message)
    }
}

export const resetNotification = () => async (dispatch) => {
    try {
        dispatch({ type: RESET_NOTIFICATION })
    } catch (error) {
        console.log(error.message)
    }
}
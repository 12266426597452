import { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import {
    createBreedingStage, updateBreedingStage, confirmDuplicateBreedingStage, orderBreedingStage,
    confirmRemoveBreedingStage, addTraits, refreshBreedingStagesTable, openUpdateTraits, confirmRemoveTraits, confirmRemovePools, exportBreedingStages,
    addCrossingPool
} from '../../../actions/breedingStage'
import MaterialTable, { MTableToolbar } from 'material-table'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import TableHead from '@material-ui/core/TableHead'
import TableCell from '@material-ui/core/TableCell'
import TablePagination from '@material-ui/core/TablePagination'
import GridCell from "../GridCell"
import GridEditableCell from "../GridEditableCell"
import BreedingStageSummary from '../BreedingStageSummary'
import TableIcons from '../../molecules/TableIcons'
import TableRowOrder from '../../molecules/TableRowOrder'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import makeStyles from '@material-ui/core/styles/makeStyles'
import BreedingStageToolbar from '../BreedingStageToolbar'
import BreedingStageColumns from '../BreedingStageColumns'
import Tooltip from '@material-ui/core/Tooltip';
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit';
import { flattenBreedingSchemeData } from "../../../utils"
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const useStyles = makeStyles((theme) => ({
    table: {
        '& div > div > div > div ': {
            marginBottom: "10px"
        },
        '& .breeding-stage-toolbar > .MuiToolbar-root': {
            minHeight: "25px",
            display: "inline-block",
            float: "left",
            marginRight: "15px",
            paddingTop: "15px"
        },
        '& .MuiToolbar-regular  button': {
            padding: "8px 12px"
        },
        '& tbody > tr > td:first-child, thead > tr:first-child > th:first-child': {
            position: "sticky",
            left: 0,
            backgroundColor: "#fff",
            zIndex: 10
        },
        '& th': {
            fontSize: "0.79rem",
            padding: "4px 10px",
            border: "1px solid #b3b3b3",
            borderBottom: "2px solid #b3b3b3"
        },
        '& .verticalTitle': {
            padding: "8px",
            textAlign: "-webkit-center",
            verticalAlign: "bottom",
            height: "180px",
            whiteSpace: "nowrap",
        },
        '& .verticalTitle > div': {
            transform: " rotate(270deg)",
            width: "25px",
        },
        '& .MuiTableRow-root': {
            height: "0px !important"
        },
        '& .MuiTableRow-footer': {
            height: "auto !important"
        }
    }
}))

const BreedingStage = (props) => {
    const dispatch = useDispatch()
    const [breedingStages, setBreedingStages] = useState(props.breedingStagesData)
    const [breedingSchemePermission, setBreedingSchemePermission] = useState("VIEWER")
    const breedingStageColumns = useSelector((state) => state.breedingStageColumns)
    const tableRef = useRef()

    useEffect(() => {
        setBreedingStages(props.breedingStagesData)
        setBreedingSchemePermission(props.permission)
    }, [props.breedingStagesData, props.permission])
    //Rearrange the Json Object Keys inorder to render in the same format in the Excel Template
    let sortedColumnObj = {}
    if (breedingStages.length) {
        sortedColumnObj = {
            actions: "",
            stageNo: breedingStages[0].stageNo,
            ...breedingStages[0],
            EvaluationStrategy: {
                plotSize: {
                    plotLength: breedingStages[0].EvaluationStrategy.plotLength,
                    plotLengthUnits: breedingStages[0].EvaluationStrategy.plotLengthUnits,
                    plotWidth: breedingStages[0].EvaluationStrategy.plotWidth,
                    plotWidthUnits: breedingStages[0].EvaluationStrategy.plotWidthUnits
                },
                experimentalDesignWithinEnvironment: {
                    plantPortionToBePlanted: breedingStages[0].EvaluationStrategy.plantPortionToBePlanted,
                    noOfPlantPortions: breedingStages[0].EvaluationStrategy.noOfPlantPortions,
                    fromPlantPortionOrigin: breedingStages[0].EvaluationStrategy.fromPlantPortionOrigin,
                    plantPortionPlantedIn: breedingStages[0].EvaluationStrategy.plantPortionPlantedIn,
                    experimentalDesign: breedingStages[0].EvaluationStrategy.experimentalDesign,
                    noSpatialChecks: breedingStages[0].EvaluationStrategy.noSpatialChecks,
                    noTemporalChecks: breedingStages[0].EvaluationStrategy.noTemporalChecks,
                },
                experimentalDesignAcrossEnvironments: {
                    connectivityMethod: breedingStages[0].EvaluationStrategy.connectivityMethod,
                    sparseTesting: breedingStages[0].EvaluationStrategy.sparseTesting,
                },
            },
            SelectionStrategy: {
                QualityTraits: breedingStages[0].SelectionStrategy.QualityTraits.map(qualityTrait => ({
                    [qualityTrait.columnName]: qualityTrait
                })),
                AgronomicTraits: breedingStages[0].SelectionStrategy.AgronomicTraits.map(agronomicTrait => ({
                    [agronomicTrait.columnName]: agronomicTrait
                })),
                MorphologicalTraits: breedingStages[0].SelectionStrategy.MorphologicalTraits.map(morphologicalTrait => ({
                    [morphologicalTrait.columnName]: morphologicalTrait
                })),
                PhenologicalTraits: breedingStages[0].SelectionStrategy.PhenologicalTraits.map(phenologicalTrait => ({
                    [phenologicalTrait.columnName]: phenologicalTrait
                })),
                AbioticStressTraits: breedingStages[0].SelectionStrategy.AbioticStressTraits.map(abioticStressTrait => ({
                    [abioticStressTrait.columnName]: abioticStressTrait
                })),
                BioticStressTraits: breedingStages[0].SelectionStrategy.BioticStressTraits.map(bioticStressTrait => ({
                    [bioticStressTrait.columnName]: bioticStressTrait
                }))
            },
            RecyclingStrategy: {
                recyclingGeneration: breedingStages[0].RecyclingStrategy.recyclingGeneration,
                recyclingUnit: breedingStages[0].RecyclingStrategy.recyclingUnit,
                noOfUnitsRecycled: breedingStages[0].RecyclingStrategy.noOfUnitsRecycled,
                kpi: breedingStages[0].RecyclingStrategy.kpi,
                evaluationSeasonCost: breedingStages[0].EvaluationStrategy.evaluationSeasonCost,
                selectionSeasonCost: breedingStages[0].RecyclingStrategy.selectionSeasonCost,
            },
            MultiplicationStrategy: {
                multiplicationUnit: breedingStages[0].MultiplicationStrategy.multiplicationUnit,
                multiplicationMethod: breedingStages[0].MultiplicationStrategy.multiplicationMethod,
                multiplicationRate: {
                    multiplicationRateValue: breedingStages[0].MultiplicationStrategy.multiplicationRateValue,
                    multiplicationRateUnit: breedingStages[0].MultiplicationStrategy.multiplicationRateUnit,
                    multiplicationRatePer: "per",
                    multiplicationPortionMultiplied: breedingStages[0].MultiplicationStrategy.multiplicationPortionMultiplied
                }
            },
            CrossingStrategy: {
                CrossingPools: breedingStages[0].CrossingStrategy.CrossingPools.map((pool, idx) => ({
                    [pool.columnName + " " + (idx + 1)]: pool
                })),
                matingDesign: breedingStages[0].CrossingStrategy.matingDesign,
            }
        }
    }

    const handleAddBreedingStage = () => {
        const initialBreedingStageData = {
            BreedingSchemeId: props.breedingSchemeId,
            SelectionStrategy: breedingStages[0] ? breedingStages[0].SelectionStrategy : {
                QualityTraits: [],
                AgronomicTraits: [],
                MorphologicalTraits: [],
                PhenologicalTraits: [],
                AbioticStressTraits: [],
                BioticStressTraits: []
            },
            CrossingStrategy: breedingStages[0] ? breedingStages[0].CrossingStrategy : {
                CrossingPools: []
            }
        }
        dispatch(createBreedingStage(initialBreedingStageData))
    }

    const handleRefreshBreedingStage = () => {
        dispatch(refreshBreedingStagesTable(props.breedingSchemeId))
    }

    const handleExportToCSV = () => {
        dispatch(exportBreedingStages(tableData.data))
    }

    const handleExportToPDF = () => {
        const input = document.getElementById('breedingStageTable');
        html2canvas(input, {
            logging: false,
            scale: 1,
            width: input.getElementsByTagName("table")[0].offsetWidth,
            windowWidth: input.getElementsByTagName("table")[0].offsetWidth,
        })
            .then((canvas) => {
                const imgData = canvas.toDataURL('image/png');
                const elementWidth = input.getElementsByTagName("table")[0].offsetWidth * 0.265 //Returns value in (mm)                                    
                const elementHeight = input.offsetHeight * 0.265 //Returns value in (mm)
                const pdf = new jsPDF({
                    orientation: "landscape",
                    format: [elementWidth, elementHeight]
                });
                pdf.addImage(imgData, 'JPEG', 0, 0);
                pdf.save(`${props.breedingScheme.name}.pdf`);
            })
    }

    const handleAddQualityTrait = () => {
        dispatch(addTraits({
            BreedingSchemeId: props.breedingSchemeId,
            type: "Quality"
        }))
    }
    const handleAddAgronomicTrait = () => {
        dispatch(addTraits({
            BreedingSchemeId: props.breedingSchemeId,
            type: "Agronomic"
        }))
    }
    const handleAddMorphologicalTrait = () => {
        dispatch(addTraits({
            BreedingSchemeId: props.breedingSchemeId,
            type: "Morphological"
        }))
    }
    const handleAddPhenologicalTrait = () => {
        dispatch(addTraits({
            BreedingSchemeId: props.breedingSchemeId,
            type: "Phenological"
        }))
    }
    const handleAddAbioticStressTrait = () => {
        dispatch(addTraits({
            BreedingSchemeId: props.breedingSchemeId,
            type: "AbioticStress"
        }))
    }
    const handleAddBioticStressTrait = () => {
        dispatch(addTraits({
            BreedingSchemeId: props.breedingSchemeId,
            type: "BioticStress"
        }))
    }

    const handleAddCrossingPool = () => {
        dispatch(addCrossingPool(props.breedingSchemeId))
    }

    const handleConfirmRemovePools = (id) => {
        dispatch(confirmRemovePools(id))
    }

    //Arguments passed are newCellValue, oldCellValue, rowData, columnDef
    const handleSubmit = async (newValue, oldValue, rowData, columnDef) => {
        //Update the rowData column field with the new value. This ensures an updated data to be sent for calculations                        
        rowData[columnDef.field] = newValue.replace(/,/g, '')
        const objKeys = columnDef.field.split(".")
        const updatedField = objKeys.pop()
        const updatedBreedingStage = {
            id: rowData[objKeys.join(".") + ".id"],
            [updatedField]: newValue.replace(/,/g, ''),
            BreedingSchemeId: rowData.BreedingSchemeId,
            modelName: columnDef.modelName,
            breedingStageRecord: rowData,
            breedingSchemeConstants: props.pipelineSettings
        }
        await dispatch(updateBreedingStage(updatedBreedingStage))
    }

    //Arguments passed are Traits GroupId, TraitName and CostForPhenotypingTrait
    const handleOpenUpdateTraits = (id, columnName, value) => {
        dispatch(openUpdateTraits(id, props.breedingSchemeId, columnName, value))
    }

    const handleConfirmRemoveTraits = (id) => {
        dispatch(confirmRemoveTraits(id))
    }

    const handleSortBreedingStage = (newOrderNo, previousOrderNo, BreedingSchemeId) => {
        dispatch(orderBreedingStage({ newOrderNo, previousOrderNo, BreedingSchemeId }))
    }

    const tableData = breedingStages.length && flattenBreedingSchemeData(breedingStages, breedingStageColumns.hiddenColumns, breedingSchemePermission, props.pipelineSettings)
    const classes = useStyles()
    return (
        <Box id="breedingStageTable" className={classes.table}>
            <MaterialTable
                tableRef={tableRef}
                title={
                    <>
                        {
                            breedingSchemePermission === 'VIEWER' ?
                                <Tooltip title="Viewer [readonly access]" placement="top">
                                    <VisibilityIcon color="primary" style={{ verticalAlign: "middle", marginRight: "7px" }} />
                                </Tooltip> :
                                <Tooltip title="Editor [read/write access]" placement="top">
                                    <EditIcon color="secondary" style={{ verticalAlign: "middle", marginRight: "7px" }} />
                                </Tooltip>
                        }
                        <Typography variant="subtitle2" style={{ display: "inline" }}>
                            BREEDING SCHEME FOR PIPELINE [<Typography component="span" style={{ fontSize: "12px" }} color="error" variant="inherit"> FILL FROM LEFT TO RIGHT (PLANT DEVELOPMENT), AND ROW BY ROW (BREEDING STAGES) </Typography>]</Typography>
                    </>
                }
                columns={tableData.columns}
                icons={TableIcons}
                localization={{
                    toolbar: {
                        exportCSVName: "Export to Excel"
                    }
                }}
                isLoading={props.isLoading}
                components={{
                    Header: headerProps => (
                        <TableHead>
                            <BreedingStageColumns
                                data={sortedColumnObj}
                                pipelineConst={props.pipelineSettings}
                                rowDepth={3}
                                hiddenColumns={breedingStageColumns.hiddenColumns}
                                handleAddQualityTrait={handleAddQualityTrait}
                                handleAddAgronomicTrait={handleAddAgronomicTrait}
                                handleAddMorphologicalTrait={handleAddMorphologicalTrait}
                                handleAddPhenologicalTrait={handleAddPhenologicalTrait}
                                handleAddAbioticStressTrait={handleAddAbioticStressTrait}
                                handleAddBioticStressTrait={handleAddBioticStressTrait}
                                handleOpenUpdateTraits={handleOpenUpdateTraits}
                                handleConfirmRemoveTraits={handleConfirmRemoveTraits}
                                handleAddCrossingPool={handleAddCrossingPool}
                                handleConfirmRemovePools={handleConfirmRemovePools}
                                permission={breedingSchemePermission}
                            />
                        </TableHead>),
                    Toolbar: toolbarProps => (
                        <div className="breeding-stage-toolbar">
                            <MTableToolbar {...toolbarProps} />
                            <BreedingStageToolbar
                                breedingSchemeId={props.breedingSchemeId}
                                breedingPipelineId={props.breedingScheme.BreedingPipelineId}
                                handleAddBreedingStage={handleAddBreedingStage}
                                handleRefreshBreedingStage={handleRefreshBreedingStage}
                                handleExportToCSV={handleExportToCSV}
                                handleExportToPDF={handleExportToPDF}
                                permission={breedingSchemePermission}
                            />
                        </div>
                    ),
                    Cell: cellProps => cellProps.columnDef.editable === 'never' ?
                        cellProps.columnDef.field === "stageNo" ?
                            <TableCell style={{ ...cellProps.columnDef.cellStyle, color: "rgba(0,0,0,0.7)", border: "1px solid #b3b3b3" }}>
                                <TableRowOrder
                                    id={cellProps.rowData.BreedingSchemeId}
                                    order={cellProps.rowData.stageNo}
                                    total={tableData.data ? tableData.data.length : 0}
                                    sort={handleSortBreedingStage}
                                    isDisabled={breedingSchemePermission === "VIEWER"}>
                                    {cellProps.rowData.stageNo}
                                </TableRowOrder>
                            </TableCell> :
                            <GridCell handleSubmit={handleSubmit} {...cellProps} /> : <GridEditableCell handleSubmit={handleSubmit} {...cellProps} />,
                    Pagination: paginationProps => <>
                        <TableCell style={{ float: "left", padding: "8px 16px" }}>
                            <BreedingStageSummary {...tableData.totalCosts} />
                        </TableCell>
                        <TablePagination {...paginationProps} style={{ float: "right", width: "auto" }} /></>
                }}
                options={{
                    search: false,
                    columnsButton: false,
                    exportButton: false,
                    padding: "dense",
                    pageSize: 50,
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    pageSizeOptions: [5, 20, 50, 100],
                    addRowPosition: "last",
                    loadingType: "overlay",
                    toolbarButtonAlignment: "left",
                }}
                data={tableData.data}
                actions={
                    [
                        rowData => ({
                            icon: () => <LibraryAddIcon color="primary" />,
                            tooltip: "Create Duplicate Breeding Stage",
                            isFreeAction: false,
                            disabled: breedingSchemePermission === "VIEWER",
                            onClick: (event) => dispatch(confirmDuplicateBreedingStage(rowData.id))
                        }),
                        rowData => ({
                            icon: () => <DeleteSweepIcon color="action" />,
                            tooltip: "Delete Breeding Stage",
                            disabled: breedingSchemePermission === "VIEWER",
                            isFreeAction: false,
                            onClick: (event) => dispatch(confirmRemoveBreedingStage(rowData.id))
                        })
                    ]}
            />
        </Box >
    )
}

export default BreedingStage;
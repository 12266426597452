/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
    getProductProfiles, createProductProfile, updateProductProfile, cancelDuplicateProductProfile, confirmDuplicateProductProfile,
    duplicateProductProfile, confirmRemoveProductProfile, cancelRemoveProductProfile, removeProductProfile, exportProductProfiles, orderProductProfile
} from '../../../actions/productProfile'
import { showNotification } from '../../../actions/notification'
//import { getMarketSegments } from '../../../actions/marketSegment'
import { updateRenderedProductProfiles } from '../../../actions/breedingPipelines'
import Box from '@material-ui/core/Box'
import MaterialTable, { MTableActions, MTableCell } from 'material-table'
import TableCell from '@material-ui/core/TableCell';
import TableIcons from '../../molecules/TableIcons'
import TableRowOrder from '../../molecules/TableRowOrder';
import GetAppIcon from '@material-ui/icons/GetApp'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import ViewListIcon from '@material-ui/icons/ViewList';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import UserDialog from '../../molecules/UserDialog'
import ShareContent from '../ShareContent';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles'
import TableCellTooltip from '../../molecules/TableCellTooltip'
import Chip from '@material-ui/core/Chip'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const useStyles = makeStyles((theme) => ({
    table: {
        '& table': {
            margin: "1px 0px"
        },
        '& h6': {
            fontSize: "0.85rem"
        },
        '& th': {
            fontSize: "13px !important",
            border: "1px solid #b3b3b3 !important",
            lineHeight: "1rem"
        },
        '& tbody td': {
            padding: "2px 24px 2px 16px",
            fontSize: "13px !important",
            border: "1px solid #b9b9b9 !important",
            background: "#fff"
        },
        '& tbody > tr': {
            height: "auto !important"
        },
        '& tbody > tr:first-child': {
            height: "38px !important"
        },
        '& tbody > tr > td:first-child': {
            width: "30px !important"
        },
        '& td input': {
            width: "100%"
        },
        '& td > div': {
            width: "auto !important"
        },
    },
    multiselectInput: {
        '& > div': {
            width: "250px",
            display: "flex",
            flexWrap: "wrap"
        },
        '& .Mui-disabled > div': {
            backgroundColor: "#7e7e7e"
        }
    }
}))

const MarketSegmentChip = ({ name, code }) => {
    return (
        <TableCellTooltip title={name}>
            <Chip style={{ margin: "0px 5px 5px 0px" }} size="small" label={code} color="secondary" />
        </TableCellTooltip>
    )
}

const FormatDate = (value) => {
    const formattedDate = new Date(value).toLocaleString('en-us', { month: 'short', day: '2-digit', year: 'numeric' })
    return formattedDate
}

const ProductProfileMarketSegmentsInput = (cellProps) => {
    const [marketSegmentOptions, setMarketSegmentOptions] = useState([])
    const [selectedMarketSegments, setSelectedMarketSegments] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true)

    useEffect(() => {
        if (cellProps.rowData.id) {
            const selectedOptions = cellProps.value.map(pm => pm.MarketSegment.id)
            const unusedOptions = cellProps.columnDef.availableMarketSegments.filter(ao => !selectedOptions.includes(ao.id))
            setSelectedMarketSegments(cellProps.value.map(pm => pm.MarketSegment))
            setMarketSegmentOptions(cellProps.value.map(pm => pm.MarketSegment).concat(unusedOptions))
            setIsDisabled(cellProps.rowData.Shares.length > 0 ? cellProps.rowData.Shares[0].permission === 'VIEWER' ? true : false : false)
        }
        else {
            setSelectedMarketSegments([])
            setMarketSegmentOptions(cellProps.columnDef.availableMarketSegments)
        }
    }, [cellProps])

    const handleChange = (event) => {
        setSelectedMarketSegments(event.target.value);
    };

    const handleSubmit = () => {
        if (cellProps.rowData.id)
            cellProps.handleSubmit(cellProps.rowData, selectedMarketSegments)
        else
            cellProps.onChange(selectedMarketSegments)
    }
    const classes = useStyles()

    return (
        <FormControl>
            <Select
                id="marketSegments"
                multiple
                disabled={isDisabled}
                className={classes.multiselectInput}
                value={selectedMarketSegments}
                onChange={handleChange}
                onClose={handleSubmit}
                input={<Input />}
                renderValue={(selected) => selected.map((marketSegment, idx) => <MarketSegmentChip key={idx} name={marketSegment.name} code={marketSegment.code} />)}
            >
                {marketSegmentOptions.map((marketSegment, idx) => (
                    <MenuItem key={idx} value={marketSegment}>
                        <Checkbox checked={selectedMarketSegments.map(ms => ms.id).indexOf(marketSegment.id) > -1} />
                        <Typography style={{ fontSize: "0.9rem" }}>{`${marketSegment.name} (${marketSegment.code})`}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const ProductProfile = (props) => {
    const userAuth = useSelector((state) => state.userAuth)
    const productProfileState = useSelector((state) => state.productProfile)
    const dispatch = useDispatch()
    const history = useHistory()
    const [productProfiles, setProductProfiles] = useState({
        data: [],
        availableMarketSegments: [],
        breedingPipelineId: "",
        isLoading: false,
        duplicateProductProfileForm: {
            productProfileId: "",
            breedingPipelineId: "",
            open: false
        },
        removeProductProfileForm: {
            productProfileId: "",
            breedingPipelineId: "",
            open: false
        }
    })

    useEffect(() => {
        setProductProfiles({ ...productProfiles, isLoading: true })
        dispatch(getProductProfiles(props.breedingPipelineId, { sharedOnly: props.sharedOnly }))

        //logic to check which pipelines are expanded
        dispatch(updateRenderedProductProfiles(props.breedingPipelineId, true))
        return () => {
            //pathname is being checked to verify if user has navigated to a different page. If so,
            //the rendered market segments will not be removed from store
            if (history.location.pathname === "/" || history.location.pathname === "/shared")
                dispatch(updateRenderedProductProfiles(props.breedingPipelineId, false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.breedingPipelineId, dispatch])

    useEffect(() => {
        if (productProfileState.breedingPipelineId === props.breedingPipelineId) {
            setProductProfiles({ ...productProfiles, ...productProfileState })
        }
    }, [productProfileState, props.breedingPipelineId])

    const handleSortProductProfile = (newOrderNo, previousOrderNo, BreedingPipelineId) => {
        setProductProfiles({ ...productProfiles, isLoading: true })
        dispatch(orderProductProfile({ newOrderNo, previousOrderNo, BreedingPipelineId }, { sharedOnly: props.sharedOnly }))
    }

    const columns = [
        {
            title: 'No', field: 'recordNo', cellStyle: { width: "5%", padding: "2px 0px" }, align: "center", editable: "never",
            render: rowData => <TableRowOrder
                id={rowData.BreedingPipelineId}
                order={rowData.recordNo}
                total={productProfiles.data.length}
                sort={handleSortProductProfile}
                isDisabled={props.sharedOnly ?
                    (rowData.Shares[0] && (rowData.Shares[0].permission === 'OWNER' &&
                        (rowData.Shares[0].groupId === `ProductProfile-${productProfiles.breedingPipelineId}` || rowData.Shares[0].groupId === `BreedingPipeline-${productProfiles.breedingPipelineId}`)
                    ) ? false : true) : false}>
                {rowData.recordNo}
            </TableRowOrder >
        },
        { title: 'Name', field: 'name', align: "center", cellStyle: { width: "30%" }, render: rowData => rowData.name ? rowData.name : "-" },
        {
            title: 'Market Segments',
            field: 'ProductProfileMarketSegments',
            align: "center",
            availableMarketSegments: productProfiles.availableMarketSegments,
            editComponent: inputProps => (
                <ProductProfileMarketSegmentsInput {...inputProps} />
            )
        },
        { title: 'Updated At', field: 'createdAt', align: "center", editable: "never", render: rowData => FormatDate(rowData.updatedAt) },
        { title: 'Created', field: 'createdAt', align: "center", editable: "never", render: rowData => FormatDate(rowData.createdAt) },
    ];

    const handleUpdateAttendedMarketSegments = (rowData, selectedMarketSegments) => {
        const updatedProductProfileObj = {
            ...rowData,
            MarketSegments: selectedMarketSegments
        }
        dispatch(updateProductProfile(updatedProductProfileObj, { sharedOnly: props.sharedOnly }))
    }

    const handleDuplicateProductProfile = async () => {
        setProductProfiles({ ...productProfiles, isLoading: true })
        await dispatch(duplicateProductProfile(productProfiles.duplicateProductProfileForm.productProfileId, productProfiles.duplicateProductProfileForm.breedingPipelineId, { sharedOnly: props.sharedOnly }))
    }

    const handleCancelDuplicateProductProfile = () => {
        dispatch(cancelDuplicateProductProfile())
    }

    const handleRemoveProductProfile = async () => {
        setProductProfiles({ ...productProfiles, isLoading: true })
        await dispatch(removeProductProfile(productProfiles.removeProductProfileForm.productProfileId, productProfiles.removeProductProfileForm.breedingPipelineId, { sharedOnly: props.sharedOnly }))
    }

    const handleCancelRemoveProductProfile = () => {
        dispatch(cancelRemoveProductProfile())
    }

    const reloadProductProfileGrid = () => {
        dispatch(getProductProfiles(props.breedingPipelineId, { sharedOnly: props.sharedOnly }))
    }

    const classes = useStyles()
    return (
        <Box id={props.breedingPipelineId} px={0} className={classes.table} style={{ maxWidth: userAuth.user.role === "admin" ? "92vw" : "96vw" }}>
            <UserDialog
                open={productProfiles.duplicateProductProfileForm.open}
                contentTitle="Are you sure you want to create a copy of the selected product profile?"
                contentText=""
                handleAction={handleDuplicateProductProfile}
                handleCancel={handleCancelDuplicateProductProfile}
            />
            <UserDialog
                open={productProfiles.removeProductProfileForm.open}
                contentTitle="Are you sure you want to remove the selected product profile?"
                contentText=""
                handleAction={handleRemoveProductProfile}
                handleCancel={handleCancelRemoveProductProfile}
            />
            <MaterialTable
                title="Product Profiles"
                style={{ backgroundColor: "#ecf0f5" }}
                columns={columns}
                icons={TableIcons}
                isLoading={productProfiles.isLoading}
                options={{
                    search: false,
                    pageSize: 5,
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    exportButton: false,
                    columnsButton: false,
                    padding: "dense",
                    loadingType: "overlay",
                    toolbarButtonAlignment: "left",
                }}
                components={{
                    Cell: cellProps => cellProps.columnDef.field === 'ProductProfileMarketSegments' ?
                        <TableCell>
                            <ProductProfileMarketSegmentsInput {...cellProps} handleSubmit={handleUpdateAttendedMarketSegments} />
                        </TableCell> : <MTableCell {...cellProps} />,
                    Actions: actionsProps =>
                        <>
                            <MTableActions {...actionsProps} />
                            {actionsProps.actions && ['toolbar', 'row'].includes(actionsProps.actions[0].position) &&
                                <>
                                    <Divider orientation="vertical" variant="fullWidth" flexItem style={{
                                        display: props.sharedOnly ? ((actionsProps.data && actionsProps.data.Shares[0]) && actionsProps.data.Shares[0].permission !== 'OWNER' ? "none" : "inline") : "inline"
                                    }} />
                                    <ShareContent
                                        count={actionsProps.data ? actionsProps.data.SharedCount : 0}
                                        reloadGrid={reloadProductProfileGrid}
                                        isDisabled={props.sharedOnly ? ((actionsProps.data && actionsProps.data.Shares[0]) && actionsProps.data.Shares[0].permission !== 'OWNER' ? true : false) : false}
                                        isHidden={props.sharedOnly ? ((actionsProps.data && actionsProps.data.Shares[0]) ? (actionsProps.data.Shares[0].permission !== 'OWNER' ? true : false) : true) : false}
                                        groupId={actionsProps.data ? `ProductProfile-${actionsProps.data.id}` : `ProductProfile-${productProfiles.breedingPipelineId}`}
                                        BreedingPipelineId={productProfiles.breedingPipelineId}
                                        productProfileIds={actionsProps.data ? [actionsProps.data.id] : productProfiles.data.map(productProfile => productProfile.id)}
                                        btnColor="primary"
                                        btnSize="small" />
                                </>
                            }
                        </>,
                }}
                data={productProfiles.data}
                actions={
                    [
                        rowData => ({
                            icon: () => <ViewListIcon color="secondary" />,
                            tooltip: "Open Product Profile Traits",
                            isFreeAction: false,
                            onClick: (event) => history.push(`/product-profile/${rowData.id}`)
                        }),
                        rowData => ({
                            icon: () => <LibraryAddIcon color="primary" />,
                            tooltip: "Create Duplicate Product Profile",
                            isFreeAction: false,
                            disabled: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            hidden: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            onClick: (event) => dispatch(confirmDuplicateProductProfile(rowData.id, rowData.BreedingPipelineId))
                        }),
                        rowData => ({
                            icon: () => <DeleteSweepIcon color="action" />,
                            tooltip: "Delete Product Profile",
                            isFreeAction: false,
                            disabled: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            hidden: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            onClick: (event) => dispatch(confirmRemoveProductProfile(rowData.id, rowData.BreedingPipelineId))
                        }),
                        {
                            icon: () => <GetAppIcon color="primary" />,
                            tooltip: "Export to CSV",
                            isFreeAction: true,
                            onClick: (event) => {
                                dispatch(exportProductProfiles(props.breedingPipelineId))
                            }
                        },
                        {
                            icon: () => <PictureAsPdfIcon color="primary" />,
                            tooltip: "Export to PDF",
                            isFreeAction: true,
                            onClick: (event) => {
                                const input = document.getElementById(props.breedingPipelineId);
                                html2canvas(input, {
                                    logging: false,
                                    scale: 1,
                                    x: input.getBoundingClientRect().x + window.pageXOffset,
                                    y: input.getBoundingClientRect().y + window.pageYOffset + 30,
                                    width: input.getElementsByTagName("table")[0].offsetWidth + 170,
                                    windowWidth: input.getElementsByTagName("table")[0].offsetWidth + 170,
                                })
                                    .then((canvas) => {
                                        const imgData = canvas.toDataURL('image/png');
                                        const elementWidth = (input.getElementsByTagName("table")[0].offsetWidth + 120) * 0.265 //Returns value in (mm)                                    
                                        const elementHeight = input.offsetHeight * 0.265 //Returns value in (mm)
                                        const pdf = new jsPDF({
                                            orientation: "landscape",
                                            format: [elementWidth, elementHeight]
                                        });
                                        pdf.addImage(imgData, 'JPEG', 0, 0);
                                        pdf.save("Product_Profiles.pdf");
                                    })
                            }
                        },
                    ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            newData.BreedingPipelineId = props.breedingPipelineId
                            if (props.sharedOnly && productProfiles.data[0].Shares[0].permission !== "OWNER") {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to add new product profiles to this breeding pipeline" }))
                                reject()
                            } else {
                                setProductProfiles({ ...productProfiles, isLoading: true })
                                dispatch(createProductProfile(newData, { sharedOnly: props.sharedOnly }))
                                resolve()
                            }
                        }),
                }}
                cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise((resolve, reject) => {
                            const updatedProductProfileObj = {
                                ...rowData,
                                [columnDef.field]: newValue
                            }
                            if (props.sharedOnly && rowData.Shares[0].permission === "VIEWER") {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to make changes to this product profile" }))
                                reject()
                            } else {
                                setProductProfiles({ ...productProfiles, isLoading: true })
                                dispatch(updateProductProfile(updatedProductProfileObj, { sharedOnly: props.sharedOnly }))
                                resolve()
                            }
                        });
                    }
                }}
            />
        </Box>
    )
}

export default ProductProfile;
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import { useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'

const WelcomeSection = () => {
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    return (
        <Box pt={smallScreen ? 3 : 0} p={smallScreen ? 0 : 6}>
            <Box mb={smallScreen ? 2 : 6}>
                <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="Excellence in Breeding Platform" width={smallScreen ? "250" : "350"} />
            </Box>
            <Typography variant="h4">Breeding Pipeline Manager</Typography>
            <br />
            {!smallScreen &&
                <>
                    <Typography>
                        This tool aims to help breeding professionals to formalize the market segments,
                        product profiles and breeding schemes associated to their breeding program(s).
                        It serves as repository of valuable information that can be used for different purposes,
                        e.g. discussions with leadership on proper targets, level of investment in different markets,
                        breeding scheme optimization discussions to allocate resources more efficiently,
                        share information with partners remotely, among others.
                        The resulting documents can be considered the “blueprints” of the design step in the breeding process.
                    </Typography>
                    <br />
                    <Typography>
                        Other useful and complementary tools in designing programs are:
                    </Typography>
                    <ul>
                        <li>
                            <Typography>
                                Costing tools (<a href="https://aussorgm.org.au/downloads/breeding-costing-tool/" target="_blank" rel="noreferrer">https://aussorgm.org.au/downloads/breeding-costing-tool/</a>).
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Modeling/simulation tools (<a href="https://CRAN.R-project.org/package=AlphaSimR" target="_blank" rel="noreferrer">https://CRAN.R-project.org/package=AlphaSimR</a>
                                &nbsp;or <a href="https://excellenceinbreeding.org/toolbox/tools/breeding-scheme-design-tools-and-software" rel="noreferrer" target="_blank">https://excellenceinbreeding.org/toolbox/tools/breeding-scheme-design-tools-and-software</a>).
                            </Typography>
                        </li>
                        <li>
                            <Typography>
                                Analytical and data management tools <a href="https://ebs.excellenceinbreeding.org/" rel="noreferrer" target="_blank">(https://ebs.excellenceinbreeding.org/)</a>
                            </Typography>
                        </li>
                    </ul>

                </>
            }
        </Box>
    )
}

export default WelcomeSection;
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import TableCell from '@material-ui/core/TableCell'
import GridTextField from '../../atoms/GridTextField'
import { makeStyles } from '@material-ui/core/styles'
import { flatten } from 'flat'

const useStyles = makeStyles({
    root: {
        border: "1px solid #b3b3b3",
        '& input, div': {
            fontSize: "12px !important",
            textAlign: "center"
        },
        '& .Mui-disabled': {
            //color: "#000",
            fontWeight: 500
        }
    }
});

const GridCell = (props) => {
    const [cellProps, setCellProps] = useState(props)
    const breedingStageTableCellState = useSelector((state) => state.breedingStageTable.data.filter(stage => stage.id === cellProps.rowData.id))
    let flattenedBreedingStageData = breedingStageTableCellState.length ? flatten(breedingStageTableCellState[0]) : null

    useEffect(() => {
        setCellProps(props)
    }, [props])

    useEffect(() => {
        if (flattenedBreedingStageData && flattenedBreedingStageData[cellProps.columnDef.field] !== cellProps.value) {
            setCellProps({ ...cellProps, rowData: flattenedBreedingStageData, value: flattenedBreedingStageData[cellProps.columnDef.field] })
        }
    }, [flattenedBreedingStageData, cellProps])

    const classes = useStyles()
    return (
        <TableCell
            className={classes.root}
            align="center"
            variant="body"
            padding="none"
            size="small"
            style={{ ...cellProps.columnDef.cellStyle }}
        >
            <GridTextField {...cellProps} />
        </TableCell>
    )
}

export default GridCell
import { useState, useEffect } from 'react'
import SelectField from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'

const GridSelectField = (props) => {
    const [value, setValue] = useState("-")
    const [width, setWidth] = useState(value.length < 3 ? "3ch" : (value.length + 3) + "ch")

    useEffect(() => {
        setValue(props.columnDef.render ? props.columnDef.render(props.rowData) : props.value)
    }, [props])

    const handleChange = (e) => {
        // eslint-disable-next-line eqeqeq
        if (value != e.target.value) {
            props.handleSubmit(e.target.value, value, props.rowData, props.columnDef)
            setValue(e.target.value)
            setWidth(e.target.value.length < 3 ? "3ch" : (e.target.value.length + 3) + "ch")
        }
    }
    return (
        <SelectField
            name={props.name}
            autoWidth={true}
            onChange={handleChange}
            renderValue={(inputVal) => inputVal}
            inputProps={{
                value: value
            }}
            style={{ ...props.columnDef.cellStyle, minWidth: width }}
        >
            {Object.keys(props.columnDef.lookup).map(objKey => <MenuItem key={objKey} value={objKey}>{props.columnDef.lookup[objKey]}</MenuItem>)}
        </SelectField>
    )
}

export default GridSelectField
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid'
import Autocomplete from '@material-ui/lab/Autocomplete';
import PublicIcon from '@material-ui/icons/Public';
import { countries } from '../../../constants'
import { makeStyles } from '@material-ui/core/styles';

// ISO 3166-1 alpha-2
// ⚠️ No support for IE 11
function countryToFlag(isoCode) {
    return typeof String.fromCodePoint !== 'undefined'
        ? isoCode
            .toUpperCase()
            .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
        : isoCode;
}

const useStyles = makeStyles({
    root: {
        marginBottom: "20px"
    },
    option: {
        fontSize: 15,
        '& > span': {
            marginRight: 10,
            fontSize: 18,
        },
    },
});

export default function CountrySelect(props) {
    const classes = useStyles();

    return (
        <Grid className={classes.root} container spacing={1} alignItems="flex-end">
            <Grid item><PublicIcon /></Grid>
            <Grid item xs={10}>
                <Autocomplete
                    id="country"
                    style={{ width: "100%" }}
                    options={countries}
                    disabled={props.disabled}
                    onInputChange={(e, newValue) => {
                        props.handleChange(newValue)
                    }}
                    inputValue={props.value}
                    classes={{
                        option: classes.option,
                    }}
                    autoHighlight
                    getOptionLabel={(option) => option.label}
                    renderOption={(option) => (
                        <>
                            <span>{countryToFlag(option.code)}</span>
                            {option.label} ({option.code})
                        </>
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            id="country"
                            error={props.error}
                            helperText={props.error && "Field is required!"}
                            required
                            fullWidth
                            label="Choose a country"
                            variant="standard"
                            inputProps={{
                                ...params.inputProps,
                                form: {
                                    autoComplete: 'off', // disable autocomplete and autofill                        
                                }
                            }}
                        />
                    )}
                />
            </Grid>
        </Grid>
    );
}
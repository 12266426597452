import { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import Avatar from '@material-ui/core/Avatar';
import { sharingPermissions } from '../../../constants'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { shareContent, removeShare } from '../../../actions/share'
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,
    },
    listAction: {
        width: "110px",
    },
    avatar: {
        backgroundColor: "#ecf0f5",
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "1.1rem"
    },
    select: {
        '& .MuiSelect-select': {
            padding: "10px",
            fontSize: "0.9rem"
        }
    }
}))

const SharedListItem = ({ userObj, groupId }) => {
    const dispatch = useDispatch()

    const [shareObj, setShareObj] = useState({
        userIds: [],
        BreedingPipelineId: "",
        marketSegmentIds: [],
        breedingSchemeIds: [],
        productProfileIds: [],
        groupId: "",
        permission: "VIEWER"
    })
    const [listItemHidden, setListItemHidden] = useState(false)
    useEffect(() => {
        if (Object.keys(userObj).length > 0) {
            setShareObj({
                userIds: [userObj.id],
                BreedingPipelineId: userObj.SharedContents[0].BreedingPipelineId,
                marketSegmentIds: userObj.SharedContents.filter(share => share.MarketSegmentId !== null).map(filteredShare => filteredShare.MarketSegmentId),
                breedingSchemeIds: userObj.SharedContents.filter(share => share.BreedingSchemeId !== null).map(filteredShare => filteredShare.BreedingSchemeId),
                productProfileIds: userObj.SharedContents.filter(share => share.ProductProfileId !== null).map(filteredShare => filteredShare.ProductProfileId),
                groupId: userObj.SharedContents[0].groupId,
                permission: userObj.SharedContents[0].permission
            })
        }
    }, [userObj, groupId])

    const handlePermissionChange = async (e) => {
        if (e.target.value === "REMOVE") {
            const response = await dispatch(removeShare(shareObj))
            if (response.success)
                setListItemHidden(true)
        } else {
            const response = await dispatch(
                shareContent({ ...shareObj, groupId: groupId, permission: e.target.value })
            );
            if (response.success)
                setShareObj({ ...shareObj, permission: response.data.permission })
        }
    }

    const classes = useStyles()
    return (
        <ListItem style={{ display: listItemHidden ? "none" : "flex" }}>
            <ListItemAvatar>
                <Avatar className={classes.avatar}>
                    {userObj.fullName.split(" ").map((name, idx) => idx < 2 && name.charAt(0).toUpperCase())}
                </Avatar>
            </ListItemAvatar>
            <ListItemText primary={userObj.fullName} secondary={userObj.email} />
            <ListItemSecondaryAction className={classes.listAction} style={{ display: listItemHidden ? "none" : "inherit" }}>
                <FormControl variant="outlined" className={classes.formControl}>
                    <Select
                        className={classes.select}
                        labelId="sharePermission-label"
                        id="sharePermission"
                        value={shareObj.permission}
                        renderValue={(value) => <Typography variant="caption">{value}</Typography>}
                        onChange={handlePermissionChange}
                    >
                        {sharingPermissions.map((permission, idx) =>
                            permission.value === "OWNER" ?
                                <MenuItem key={idx} value={permission.value}><b>{permission.name}</b></MenuItem>
                                :
                                <MenuItem key={idx} value={permission.value}>{permission.name}</MenuItem>
                        )}
                        <MenuItem value="REMOVE">Remove</MenuItem>
                    </Select>
                </FormControl>
            </ListItemSecondaryAction>
        </ListItem >
    )
}

export default SharedListItem
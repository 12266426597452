import {
    FETCH_ALL_BREEDING_PIPELINE_REQUEST, FETCH_ALL_BREEDING_PIPELINE_RESPONSE,
    LOADING_BREEDING_PIPELINE, CREATE_BREEDING_PIPELINE_RESPONSE, UPDATE_BREEDING_PIPELINE_RESPONSE,
    CONFIRM_REMOVE_BREEDING_PIPELINE, CANCEL_REMOVE_BREEDING_PIPELINE, REMOVE_BREEDING_PIPELINE_RESPONSE,
    ADD_RENDERED_MARKET_SEGMENTS, REMOVE_RENDERED_MARKET_SEGMENTS, ADD_RENDERED_BREEDING_SCHEMES, REMOVE_RENDERED_BREEDING_SCHEMES,
    ADD_RENDERED_PRODUCT_PROFILES, REMOVE_RENDERED_PRODUCT_PROFILES
} from '../constants/actionTypes'

const initialState = {
    isLoading: false,
    success: false,
    message: null,
    openTour: false,
    removePipelineForm: {
        breedingPipelineId: "",
        open: false
    },
    renderedMarketSegments: [],
    renderedBreedingSchemes: [],
    renderedProductProfiles: []
}
const reducer = (breedingPipelines = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_BREEDING_PIPELINE_REQUEST:
            return {
                ...breedingPipelines,
                isLoading: true,
                openTour: false,
                removePipelineForm: {
                    breedingPipelineId: "",
                    open: false
                },
            }
        case FETCH_ALL_BREEDING_PIPELINE_RESPONSE:
            return {
                ...breedingPipelines,
                ...action.payload,
                isLoading: false
            }
        case LOADING_BREEDING_PIPELINE:
            return {
                ...breedingPipelines,
                isLoading: true
            }
        case CREATE_BREEDING_PIPELINE_RESPONSE:
            return {
                ...breedingPipelines,
                isLoading: false
            }
        case UPDATE_BREEDING_PIPELINE_RESPONSE:
            return {
                ...breedingPipelines,
                isLoading: false
            }
        case CONFIRM_REMOVE_BREEDING_PIPELINE:
            return {
                ...breedingPipelines,
                ...action.payload
            }
        case CANCEL_REMOVE_BREEDING_PIPELINE:
            return {
                ...breedingPipelines,
                ...action.payload
            }
        case REMOVE_BREEDING_PIPELINE_RESPONSE:
            return {
                ...breedingPipelines,
                ...action.payload,
                isLoading: false
            }
        case ADD_RENDERED_MARKET_SEGMENTS:
            breedingPipelines.renderedMarketSegments.push(action.payload)
            return breedingPipelines
        case REMOVE_RENDERED_MARKET_SEGMENTS:
            const filteredMarketSegments = breedingPipelines.renderedMarketSegments.filter(id => id !== action.payload)
            return {
                ...breedingPipelines,
                renderedMarketSegments: filteredMarketSegments
            }
        case ADD_RENDERED_BREEDING_SCHEMES:
            breedingPipelines.renderedBreedingSchemes.push(action.payload)
            return breedingPipelines
        case REMOVE_RENDERED_BREEDING_SCHEMES:
            const filteredBreedingSchemes = breedingPipelines.renderedBreedingSchemes.filter(id => id !== action.payload)
            return {
                ...breedingPipelines,
                renderedBreedingSchemes: filteredBreedingSchemes
            }
        case ADD_RENDERED_PRODUCT_PROFILES:
            breedingPipelines.renderedProductProfiles.push(action.payload)
            return breedingPipelines
        case REMOVE_RENDERED_PRODUCT_PROFILES:
            const filteredProductProfiles = breedingPipelines.renderedProductProfiles.filter(id => id !== action.payload)
            return {
                ...breedingPipelines,
                renderedProductProfiles: filteredProductProfiles
            }
        default:
            return breedingPipelines
    }
}

export default reducer
import { useDispatch, useSelector } from 'react-redux'
import UserDialog from '../../molecules/UserDialog'
import {
    cancelDuplicateBreedingStage, duplicateBreedingStage, removeBreedingStage, cancelRemoveBreedingStage, removeTraits, cancelRemoveTraits,
    removePools, cancelRemovePools
} from '../../../actions/breedingStage';


const BreedingStageDialog = (props) => {
    const dispatch = useDispatch()
    const breedingStageFormsState = useSelector((state) => state.breedingStageForms)

    const handleDuplicateBreedingStage = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid
        dispatch(duplicateBreedingStage(breedingStageFormsState.duplicateStageForm.breedingStageId, props.breedingSchemeId))
    }

    const handleCancelDuplicateBreedingStage = () => {
        dispatch(cancelDuplicateBreedingStage())
    }

    const handleRemoveBreedingStage = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid
        dispatch(removeBreedingStage(breedingStageFormsState.removeStageForm.breedingStageId, props.breedingSchemeId))
    }

    const handleCancelRemoveBreedingStage = () => {
        dispatch(cancelRemoveBreedingStage())
    }

    const handleRemoveTrait = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid
        dispatch(removeTraits(breedingStageFormsState.removeTraitsForm.traitsGroupId, props.breedingSchemeId))
    }

    const handleCancelRemoveTrait = () => {
        dispatch(cancelRemoveTraits())
    }

    const handleRemovePool = () => {
        //Passed arguments are the breeding stage ID & scheme Id for refreshing the grid
        dispatch(removePools(breedingStageFormsState.removePoolsForm.poolsGroupId, props.breedingSchemeId))
    }

    const handleCancelRemovePool = () => {
        dispatch(cancelRemovePools())
    }

    return (
        <>
            <UserDialog
                open={breedingStageFormsState.duplicateStageForm.open}
                contentTitle="Are you sure you want to create a copy of the selected breeding stage?"
                contentText=""
                handleAction={handleDuplicateBreedingStage}
                handleCancel={handleCancelDuplicateBreedingStage}
            />
            <UserDialog
                open={breedingStageFormsState.removeStageForm.open}
                contentTitle="Are you sure you want to remove the selected breeding stage?"
                contentText=""
                handleAction={handleRemoveBreedingStage}
                handleCancel={handleCancelRemoveBreedingStage}
            />
            <UserDialog
                open={breedingStageFormsState.removeTraitsForm.open}
                contentTitle="Are you sure you want to remove the selected trait?"
                contentText="Note: This will remove the selected trait from all breeding stages under this breeding scheme"
                handleAction={handleRemoveTrait}
                handleCancel={handleCancelRemoveTrait}
            />
            <UserDialog
                open={breedingStageFormsState.removePoolsForm.open}
                contentTitle="Are you sure you want to remove the selected pool?"
                contentText="Note: This will remove the selected pool from all breeding stages under this breeding scheme"
                handleAction={handleRemovePool}
                handleCancel={handleCancelRemovePool}
            />
        </>
    )
}

export default BreedingStageDialog
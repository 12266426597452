import {
    FETCH_ALL_BREEDING_SCHEME_RESPONSE, CREATE_BREEDING_SCHEME_RESPONSE, UPDATE_BREEDING_SCHEME_RESPONSE,
    CONFIRM_DUPLICATE_BREEDING_SCHEME, CANCEL_DUPLICATE_BREEDING_SCHEME, DUPLICATE_BREEDING_SCHEME_RESPONSE,
    CONFIRM_REMOVE_BREEDING_SCHEME, CANCEL_REMOVE_BREEDING_SCHEME, REMOVE_BREEDING_SCHEME_RESPONSE
} from '../constants/actionTypes';

const initialState = {
    data: [],
    availableMarketSegments: [],
    breedingPipelineId: "",
    isLoading: false,
    success: false,
    message: null,
    duplicateSchemeForm: {
        breedingSchemeId: "",
        breedingPipelineId: "",
        open: false
    },
    removeSchemeForm: {
        breedingSchemeId: "",
        breedingPipelineId: "",
        open: false
    }
}
const reducer = (breedingSchemes = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_BREEDING_SCHEME_RESPONSE:
            if (action.payload.data)
                action.payload.data.map((bs, idx) => bs.schemeNo = idx + 1)
            return {
                ...breedingSchemes,
                ...action.payload,
                isLoading: false
            }
        case CREATE_BREEDING_SCHEME_RESPONSE:
            return {
                ...breedingSchemes,
                isLoading: false
            }
        case UPDATE_BREEDING_SCHEME_RESPONSE:
            return {
                ...breedingSchemes,
                isLoading: false
            }
        case CONFIRM_DUPLICATE_BREEDING_SCHEME:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case CANCEL_DUPLICATE_BREEDING_SCHEME:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case DUPLICATE_BREEDING_SCHEME_RESPONSE:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case CONFIRM_REMOVE_BREEDING_SCHEME:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case CANCEL_REMOVE_BREEDING_SCHEME:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        case REMOVE_BREEDING_SCHEME_RESPONSE:
            return {
                ...breedingSchemes,
                ...action.payload
            }
        default:
            return breedingSchemes
    }
}

export default reducer
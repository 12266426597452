import {
    FETCH_ALL_PRODUCT_PROFILE_TRAIT_REQUEST, FETCH_ALL_PRODUCT_PROFILE_TRAIT_RESPONSE,
    LOADING_PRODUCT_PROFILE_TRAIT, CREATE_PRODUCT_PROFILE_TRAIT_RESPONSE, UPDATE_PRODUCT_PROFILE_TRAIT_RESPONSE,
    CONFIRM_DUPLICATE_PRODUCT_PROFILE_TRAIT, CANCEL_DUPLICATE_PRODUCT_PROFILE_TRAIT, DUPLICATE_PRODUCT_PROFILE_TRAIT_RESPONSE,
    CONFIRM_REMOVE_PRODUCT_PROFILE_TRAIT, CANCEL_REMOVE_PRODUCT_PROFILE_TRAIT, REMOVE_PRODUCT_PROFILE_TRAIT_RESPONSE
} from '../constants/actionTypes';

const initialState = {
    data: [],
    productProfileId: "",
    permission: "VIEWER",
    isLoading: false,
    success: false,
    message: null,
    duplicateProductProfileTraitForm: {
        productProfileTraitId: "",
        open: false
    },
    removeProductProfileTraitForm: {
        productProfileTraitId: "",
        open: false
    }
}
const reducer = (productProfileTraits = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_PRODUCT_PROFILE_TRAIT_REQUEST:
            return {
                ...productProfileTraits,
                isLoading: true,
                permission: "VIEWER",
                duplicateProductProfileTraitForm: {
                    productProfileTraitId: "",
                    open: false
                },
                removeProductProfileTraitForm: {
                    productProfileTraitId: "",
                    open: false
                },
            }
        case FETCH_ALL_PRODUCT_PROFILE_TRAIT_RESPONSE:
            if (action.payload.data)
                action.payload.data.map((bs, idx) => bs.recordNo = idx + 1)
            return {
                ...productProfileTraits,
                ...action.payload,
                isLoading: false
            }
        case LOADING_PRODUCT_PROFILE_TRAIT:
            return {
                ...productProfileTraits,
                ...action.payload,
                isLoading: true
            }
        case CREATE_PRODUCT_PROFILE_TRAIT_RESPONSE:
            return {
                ...productProfileTraits,
                isLoading: false
            }
        case UPDATE_PRODUCT_PROFILE_TRAIT_RESPONSE:
            return {
                ...productProfileTraits,
                isLoading: false
            }
        case CONFIRM_DUPLICATE_PRODUCT_PROFILE_TRAIT:
            return {
                ...productProfileTraits,
                ...action.payload
            }
        case CANCEL_DUPLICATE_PRODUCT_PROFILE_TRAIT:
            return {
                ...productProfileTraits,
                ...action.payload
            }
        case DUPLICATE_PRODUCT_PROFILE_TRAIT_RESPONSE:
            return {
                ...productProfileTraits,
                ...action.payload
            }
        case CONFIRM_REMOVE_PRODUCT_PROFILE_TRAIT:
            return {
                ...productProfileTraits,
                ...action.payload
            }
        case CANCEL_REMOVE_PRODUCT_PROFILE_TRAIT:
            return {
                ...productProfileTraits,
                ...action.payload
            }
        case REMOVE_PRODUCT_PROFILE_TRAIT_RESPONSE:
            return {
                ...productProfileTraits,
                ...action.payload
            }
        default:
            return productProfileTraits
    }
}

export default reducer
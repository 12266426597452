import { useState } from 'react'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip'
import VisibilityIcon from '@material-ui/icons/Visibility'
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton'
import BreedingSchemeFlowchartColumnToggler from '../../molecules/BreedingSchemeFlowchartColumnToggler'
import SaveIcon from '@material-ui/icons/Save';
import SettingsBackupRestoreIcon from '@material-ui/icons/SettingsBackupRestore';
import WallpaperIcon from '@material-ui/icons/Wallpaper';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import UserDialog from '../../molecules/UserDialog'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    toolbar: {
        zIndex: 999,
        opacity: 0.4,
        left: 0,
        right: 0,
        marginLeft: "auto",
        marginRight: "auto",
        width: "50%",
        '&:hover': {
            opacity: 0.8
        }
    },
    list: {
        display: "inline-flex",
        '& li': {
            padding: "0px",
            width: "auto",
            '& button': {
                color: "#000"
            }
        },
        '& hr': {
            margin: "0px 2px"
        }
    },
}))

const BreedingSchemeFlowchartToolbar = (props) => {
    const [resetModalOpen, setResetModalOpen] = useState(false)
    const handleConfirmResetFlowchart = () => {
        setResetModalOpen(true)
    }

    const handleResetFlowchart = () => {
        props.resetFlowchart()
        setResetModalOpen(false)
    }

    const handleCancelResetFlowchart = () => {
        setResetModalOpen(false)
    }

    const classes = useStyles()

    return (
        <>
            <UserDialog
                open={resetModalOpen}
                contentTitle="Are you sure you want to reset this breeding scheme flowchart?"
                contentText="Note: All modifications made on this flowchart will be erased and restored to its original state"
                handleAction={handleResetFlowchart}
                handleCancel={handleCancelResetFlowchart}
            />
            <Box className={classes.toolbar} position="absolute" bgcolor="white">
                <List className={classes.list} dense disablePadding>
                    <ListItem>
                        <Box mx={4}>
                            {
                                props.permission === 'VIEWER' ?
                                    <Tooltip title="Viewer [readonly access]" placement="top">
                                        <VisibilityIcon color="primary" style={{ verticalAlign: "middle", marginRight: "7px" }} />
                                    </Tooltip> :
                                    <Tooltip title="Editor [read/write access]" placement="top">
                                        <EditIcon color="secondary" style={{ verticalAlign: "middle", marginRight: "7px" }} />
                                    </Tooltip>
                            }
                            <Typography variant="button">Breeding Scheme Flowchart</Typography>
                        </Box>
                    </ListItem>
                    <Divider orientation="vertical" flexItem />
                    <ListItem>
                        <BreedingSchemeFlowchartColumnToggler
                            toggleColumns={props.toggleColumns}
                            hiddenColumns={props.hiddenColumns}
                        />
                        <Divider orientation="vertical" flexItem />
                        <Tooltip title="Save Flowchart">
                            <span>
                                <IconButton disabled={props.permission === "VIEWER"} onClick={props.saveFlowchart}>
                                    <SaveIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title="Reset Flowchart">
                            <span>
                                <IconButton disabled={props.permission === "VIEWER"} onClick={handleConfirmResetFlowchart}>
                                    <SettingsBackupRestoreIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                    </ListItem>
                    <Divider orientation="vertical" flexItem />
                    <ListItem>
                        <Tooltip title="Save as Image">
                            <IconButton onClick={props.handleExportToImage} >
                                <WallpaperIcon />
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                    <ListItem>
                        <Tooltip title="Export to PDF">
                            <IconButton onClick={props.handleExportToPDF}>
                                <PictureAsPdfIcon />
                            </IconButton>
                        </Tooltip>
                    </ListItem>
                </List>
            </Box>
        </>
    )
}

export default BreedingSchemeFlowchartToolbar
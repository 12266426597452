import * as api from '../api';
import {
    LOGIN_REQUEST, LOGIN_RESPONSE, LOGOUT_RESPONSE, VERIFY_USER_REQUEST, VERIFY_USER_RESPONSE,
    EMAIL_VERIFICATION_REQUEST, EMAIL_VERIFICATION_RESPONSE, PASSWORD_RECOVER_REQUEST, PASSWORD_RECOVER_RESPONSE,
    PASSWORD_RESET_REQUEST, PASSWORD_RESET_RESPONSE, PASSWORD_TOKEN_VERIFY_REQUEST, PASSWORD_TOKEN_VERIFY_RESPONSE
} from '../constants/actionTypes'
import { showNotification } from './notification'
//Action Creators
export const login = (userAuth) => async (dispatch) => {
    try {
        dispatch({ type: LOGIN_REQUEST })
        const { data } = await api.login(userAuth)
        dispatch({ type: LOGIN_RESPONSE, payload: data })
    } catch (error) {
        dispatch({
            type: LOGIN_RESPONSE, payload: {
                user: {},
                isAuthenticated: false,
                message: "Error logging in. Please verify your email & password"
            }
        })
    }
}

export const logout = () => async (dispatch) => {
    try {
        const { data } = await api.logout()
        dispatch({ type: LOGOUT_RESPONSE, payload: data })
    } catch (error) {
        console.log(error.message)
    }
}

export const checkUser = () => async (dispatch) => {
    try {
        dispatch({ type: VERIFY_USER_REQUEST })
        const { data } = await api.checkUser()
        dispatch({ type: VERIFY_USER_RESPONSE, payload: data })
    } catch (error) {
        dispatch({ type: VERIFY_USER_RESPONSE })
    }
}

export const sendEmailVerification = () => async (dispatch) => {
    try {
        dispatch({ type: EMAIL_VERIFICATION_REQUEST })
        const { data } = await api.resendVerification()
        dispatch({ type: EMAIL_VERIFICATION_RESPONSE, payload: data })
        dispatch(showNotification({ type: data.success ? "success" : "error", message: data.message }))
    } catch (error) {
        dispatch({ type: EMAIL_VERIFICATION_RESPONSE })
        dispatch(showNotification({ type: "error", message: error }))
    }
}

export const recoverPassword = (email) => async (dispatch) => {
    try {
        dispatch({ type: PASSWORD_RECOVER_REQUEST })
        const { data } = await api.recoverPassword({ email: email })
        dispatch({ type: PASSWORD_RECOVER_RESPONSE })
        dispatch(showNotification({ type: data.success ? "success" : "error", message: data.message }))
    } catch (error) {
        dispatch({ type: PASSWORD_RECOVER_RESPONSE })
        dispatch(showNotification({ type: "error", message: error }))
    }
}

export const reset = (token) => async (dispatch) => {
    try {
        dispatch({ type: PASSWORD_TOKEN_VERIFY_REQUEST })
        const { data } = await api.reset(token)
        dispatch({ type: PASSWORD_TOKEN_VERIFY_RESPONSE, payload: data })
        if (!data.success)
            dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: PASSWORD_TOKEN_VERIFY_RESPONSE, payload: { success: false } })
        dispatch(showNotification({ type: "error", message: error }))
    }
}

export const resetPassword = (token, newPassword) => async (dispatch) => {
    try {
        dispatch({ type: PASSWORD_RESET_REQUEST })
        const { data } = await api.resetPassword(token, { password: newPassword })
        dispatch({ type: PASSWORD_RESET_RESPONSE, payload: data })
        dispatch(showNotification({ type: data.success ? "success" : "error", message: data.message }))
    } catch (error) {
        dispatch({ type: PASSWORD_RESET_RESPONSE, payload: { success: false } })
        dispatch(showNotification({ type: "error", message: error }))
    }
}
import {
    FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_REQUEST, FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_RESPONSE, LOADING_BREEDING_SCHEME_BLUEPRINT,
    SAVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE, REMOVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE, FETCH_BREEDING_PIPELINE_FLOWCHART_RESPONSE
} from '../constants/actionTypes';

const initialState = {
    data: {},
    breedingScheme: {},
    isLoading: false,
    success: false,
    message: null
}
const reducer = (breedingSchemeBlueprint = initialState, action) => {
    switch (action.type) {
        case FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_REQUEST:
            return {
                ...breedingSchemeBlueprint,
                ...action.payload,
                isLoading: true,
            }
        case FETCH_BREEDING_PIPELINE_FLOWCHART_RESPONSE:
            return {
                ...breedingSchemeBlueprint,
                breedingScheme: action.payload.data
            }
        case FETCH_ONE_BREEDING_SCHEME_BLUEPRINT_RESPONSE:
            return {
                ...breedingSchemeBlueprint,
                ...action.payload,
                isLoading: false
            }
        case LOADING_BREEDING_SCHEME_BLUEPRINT:
            return {
                ...breedingSchemeBlueprint,
                ...action.payload,
                isLoading: true
            }

        case SAVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE:
            return {
                ...breedingSchemeBlueprint,
                isLoading: false
            }

        case REMOVE_BREEDING_SCHEME_BLUEPRINT_RESPONSE:
            return {
                ...breedingSchemeBlueprint,
                ...action.payload
            }
        default:
            return breedingSchemeBlueprint
    }
}

export default reducer
import { flatten } from 'flat'
import { BreedingStageColumnProperties } from '../constants'

//Breeding Scheme Excluded Columns List
const excludedColumns = ['id', 'BreedingPipelineId', 'BreedingSchemeId', 'BreedingStageId', 'groupId', 'costForPhenotypingTrait',
    'columnName', 'Quality', 'Agronomic', 'Morphological', 'Phenological', 'AbioticStress', 'BioticStress',
    'MultiplicationStrategyId', 'order', 'CrossingStrategyId', 'createdAt', 'updatedAt', 'deletedAt']

const isObject = (item) => {
    return (typeof item === "object" && !Array.isArray(item) && item !== null);
}

//Default Breeding Scheme table header row level is 3
export const tableColumnsGenerator = (breedingSchemeData, headerRowLevel = 3, hiddenColumns) => {
    const structuredColumns = []
    const recurse = (breedingSchemeObj, rowLevel = 0) => {
        let columnObj = { columnSpan: 0, rowSpan: 0 }
        Object.keys(breedingSchemeObj).filter(objKey => (!excludedColumns.includes(objKey) && !hiddenColumns.includes(objKey))).map(objectKey => {
            let colObj = { columnSpan: 0, rowSpan: 0 }
            if (Array.isArray(breedingSchemeObj[objectKey])) {
                breedingSchemeObj[objectKey].map(arrayObj => {
                    const newColumnObj = recurse(arrayObj, rowLevel + 1)
                    colObj.columnSpan += newColumnObj.columnSpan
                    colObj.rowSpan = newColumnObj.rowSpan
                    return colObj
                })
            }
            else if (isObject(breedingSchemeObj[objectKey])) {
                colObj = recurse(breedingSchemeObj[objectKey], rowLevel + 1)
                if (breedingSchemeObj[objectKey].groupId) colObj.groupId = breedingSchemeObj[objectKey].groupId
            }
            columnObj.columnSpan += colObj.columnSpan ? colObj.columnSpan : 1
            const columnRowSpan = (headerRowLevel - (rowLevel + colObj.rowSpan))
            //Calculate the number of rows already rendered to calculate for the parent column rowspan
            columnObj.rowSpan = columnRowSpan + colObj.rowSpan
            // Check if Columns like Traits have a group Id. If so, it will be passed to the columns renderer
            const groupId = colObj.groupId ? colObj.groupId : null
            return structuredColumns.push({
                name: objectKey,
                //For Traits Column, we will include the value of the cost for phenotyping a trait
                value: breedingSchemeObj[objectKey] ? breedingSchemeObj[objectKey].costForPhenotypingTrait : null,
                colSpan: colObj.columnSpan,
                rowSpan: columnRowSpan,
                level: rowLevel,
                groupId: groupId
            })
        })
        return columnObj
    }
    recurse(breedingSchemeData)
    return structuredColumns
}

//Breeding Scheme Excluded Data Columns List
const excludedDataColumns = ['id', 'BreedingPipelineId', 'BreedingSchemeId', 'BreedingStageId', 'groupId', 'costForPhenotypingTrait',
    'columnName', 'MultiplicationStrategyId', 'order', 'createdAt', 'updatedAt', 'deletedAt']

const generateRecyclingGenerationLookup = (breedingStages) => {
    const generationLookup = { '-': "-" }
    breedingStages.forEach(bs =>
        generationLookup[`${bs.EvaluationDatum.stageName}-${bs.EvaluationDatum.generationMaterialPlanted}`] = `${bs.EvaluationDatum.stageName}-${bs.EvaluationDatum.generationMaterialPlanted}`
    )
    return generationLookup
}
const generatePopulationStructureLookup = (pipelineSettings) => {
    const populationStructureLookup = { '-': "-" }
    populationStructureLookup[pipelineSettings.constLevel1] = pipelineSettings.constLevel1
    populationStructureLookup[pipelineSettings.constLevel2] = pipelineSettings.constLevel2
    populationStructureLookup[pipelineSettings.constLevel3] = pipelineSettings.constLevel3
    populationStructureLookup[pipelineSettings.constLevel4] = pipelineSettings.constLevel4
    return populationStructureLookup
}
//Flatten Breeding Scheme JSON Data for Material Table
export const flattenBreedingSchemeData = (breedingSchemeData, hiddenColumns, permission, pipelineSettings) => {
    const [columns, data, totalCosts] = [[], [], {
        evaluationSeasonCost: 0,
        genotypingCost: 0,
        selectionSeasonCost: 0,
        crossingSeasonCost: 0
    }]
    const recyclingGenerationLookups = generateRecyclingGenerationLookup(breedingSchemeData)
    const populationStructureLookups = generatePopulationStructureLookup(pipelineSettings)

    const dynamicLookups = {
        recyclingGeneration: recyclingGenerationLookups,
        fromPlantPortionOrigin: populationStructureLookups,
        multiplicationUnit: populationStructureLookups
    }

    breedingSchemeData.forEach((stage, idx) => {
        //To Rearrange the rendered columns with the top level header, we sort the breeding stage data
        if (idx === 0) {
            let sortedStageObj = {}
            sortedStageObj = {
                stageNo: stage.stageNo,
                ...stage,
                SelectionStrategy: {
                    id: stage.SelectionStrategy.id,
                    QualityTraits: stage.SelectionStrategy.QualityTraits,
                    AgronomicTraits: stage.SelectionStrategy.AgronomicTraits,
                    MorphologicalTraits: stage.SelectionStrategy.MorphologicalTraits,
                    PhenologicalTraits: stage.SelectionStrategy.PhenologicalTraits,
                    AbioticStressTraits: stage.SelectionStrategy.AbioticStressTraits,
                    BioticStressTraits: stage.SelectionStrategy.BioticStressTraits
                },
                RecyclingStrategy: {
                    id: stage.RecyclingStrategy.id,
                    recyclingGeneration: stage.RecyclingStrategy.recyclingGeneration,
                    recyclingUnit: stage.RecyclingStrategy.recyclingUnit,
                    noOfUnitsRecycled: stage.RecyclingStrategy.noOfUnitsRecycled,
                    kpi: stage.RecyclingStrategy.kpi,
                    evaluationSeasonCost: stage.RecyclingStrategy.evaluationSeasonCost,
                    selectionSeasonCost: stage.RecyclingStrategy.selectionSeasonCost,
                },
                MultiplicationStrategy: {
                    id: stage.MultiplicationStrategy.id,
                    multiplicationUnit: stage.MultiplicationStrategy.multiplicationUnit,
                    multiplicationMethod: stage.MultiplicationStrategy.multiplicationMethod,
                    multiplicationRateValue: stage.MultiplicationStrategy.multiplicationRateValue,
                    multiplicationRateUnit: stage.MultiplicationStrategy.multiplicationRateUnit,
                    multiplicationRatePer: stage.MultiplicationStrategy.multiplicationRatePer,
                    multiplicationPortionMultiplied: stage.MultiplicationStrategy.multiplicationPortionMultiplied,
                },
                CrossingStrategy: {
                    id: stage.CrossingStrategy.id,
                    CrossingPools: stage.CrossingStrategy.CrossingPools,
                    matingDesign: stage.CrossingStrategy.matingDesign
                }
            }
            stage = sortedStageObj
        }
        //Calculate total amount for columns with cost value        
        totalCosts.evaluationSeasonCost += stage.RecyclingStrategy.evaluationSeasonCost
        totalCosts.genotypingCost += stage.Molecular.genotypingCost
        totalCosts.selectionSeasonCost += stage.RecyclingStrategy.selectionSeasonCost
        totalCosts.crossingSeasonCost += 0//stage.CrossingStrategy.crossingSeasonCost
        data.push(flatten(stage))
    })

    if (data.length) {
        Object.keys(data[0]).forEach(key => {

            //Filter out the last segment of the object key
            const fieldName = key.split(".").pop()
            //Filter out excluded fields
            if (!excludedDataColumns.includes(key.split(".")[0]) && !hiddenColumns.includes(key.split(".")[0]) && !hiddenColumns.includes(fieldName)) {
                let columnProps = BreedingStageColumnProperties[fieldName]
                if (columnProps) {
                    let columnObj = {
                        title: "",
                        field: key,
                        editable: permission === 'VIEWER' ? "never" : columnProps.editable ? columnProps.editable : "always",
                        cellStyle: columnProps.styles,
                        lookup: columnProps.lookup ? columnProps.lookup : dynamicLookups[fieldName] ? dynamicLookups[fieldName] : null,
                        freeSolo: columnProps.freeSolo ? true : false,
                        maxLength: columnProps.maxLength ? columnProps.maxLength : 24,
                        modelName: columnProps.modelName ? columnProps.modelName : null,
                        render: rowData => typeof rowData[key] !== "undefined" ?
                            (rowData[key] !== "" && rowData[key] !== null) ?
                                columnProps.prepend ?
                                    `${columnProps.prepend}${rowData[key].toLocaleString()}` :
                                    rowData[key].toLocaleString() :
                                "-" : "per"
                    }
                    columns.push(columnObj)
                }
            }
        })
    }
    return {
        columns,
        data,
        totalCosts
    }
}

export const generateBreedingSchemeSchema = (breedingSchemeData, nodesDraggable) => {
    const bluprintSchema = []
    const nodeVerticalMargin = 75
    const nodeHorizontalMargin = 200

    bluprintSchema.push(...generateBreedingSchemeRuler(breedingSchemeData, { x: 0, y: 100 }, nodeVerticalMargin, nodesDraggable))
    const breedingStageSchema = generateBreedingStageSchema(breedingSchemeData, { x: 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable)
    bluprintSchema.push(...breedingStageSchema.schema)
    bluprintSchema.push(...generateEvaluationSchema(breedingSchemeData, { x: (breedingStageSchema.maxParallelNodes * 220) + 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable))
    bluprintSchema.push(...generateSelectionSchema(breedingSchemeData, { x: (breedingStageSchema.maxParallelNodes * 2 * 223) + 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable))
    bluprintSchema.push(...generateCrossingSchema(breedingSchemeData, { x: (breedingStageSchema.maxParallelNodes * 3 * 222) + 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable))
    bluprintSchema.push(...generateMolecularSchema(breedingSchemeData, { x: (breedingStageSchema.maxParallelNodes * 4 * 221) + 200, y: 100 }, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable))

    return bluprintSchema
}

const generateBreedingSchemeRuler = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodesDraggable) => {
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.EvaluationDatum.year, season: stage.EvaluationDatum.season }))
        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize !== uniqueSeasons.size) {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
            schema.push({
                id: `{0-${stage.id}`,
                columnId: 0,
                type: 'rulerNode',
                draggable: nodesDraggable,
                data: {
                    label: `Year: ${stage.EvaluationDatum.year}`,
                    description: `Season: ${stage.EvaluationDatum.season}`
                },
                position: position,
            })
        }
    })
    schema.unshift({
        id: '0',
        columnId: 0,
        type: 'rulerHeader',
        draggable: nodesDraggable,
        data: { label: 'TIME', description: "", width: 120 },
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return schema
}

const generateBreedingStageSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.EvaluationDatum.year, season: stage.EvaluationDatum.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.EvaluationDatum.season.trim() && JSON.parse(st).year === stage.EvaluationDatum.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }

        schema.push({
            id: stage.id,
            columnId: 1,
            type: 'stageNode',
            data: {
                label: stage.EvaluationDatum.generationMaterialPlanted,
                description: `${stage.EvaluationDatum.noLevel1}-${stage.EvaluationDatum.noLevel2}-${stage.EvaluationDatum.noLevel3}-${stage.EvaluationDatum.noLevel4}`
            },
            draggable: nodesDraggable,
            sourcePosition: "right",
            targetPosition: "left",
            position: position,
        })
    })
    schema.unshift({
        id: '1',
        columnId: 1,
        type: 'header',
        data: { label: 'Scheme', description: "", width: (172 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
        isHidden: false,
        draggable: nodesDraggable,
        sourcePosition: "right",
        targetPosition: "left",
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return { schema, maxParallelNodes }
}

const generateEvaluationSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const emptyValues = ['', '-', 'none', '?']
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.EvaluationDatum.year, season: stage.EvaluationDatum.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.EvaluationDatum.season.trim() && JSON.parse(st).year === stage.EvaluationDatum.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }

        schema.push({
            id: stage.EvaluationStrategy.id,
            columnId: 2,
            type: 'stageNode',
            data: {
                label: stage.EvaluationStrategy.experimentalDesign,
                description: `Locs/reps ${stage.EvaluationStrategy.totalNoOfLocations}/${stage.EvaluationStrategy.noOfReplicationsPerLoc}`
            },
            isHidden: emptyValues.includes(stage.EvaluationStrategy.experimentalDesign),
            emptyNode: emptyValues.includes(stage.EvaluationStrategy.experimentalDesign),
            selectable: !emptyValues.includes(stage.EvaluationStrategy.experimentalDesign),
            draggable: nodesDraggable && !emptyValues.includes(stage.EvaluationStrategy.experimentalDesign),
            sourcePosition: "right",
            targetPosition: "left",
            position: position,
        })
    })
    schema.unshift({
        id: '2',
        columnId: 2,
        type: 'header',
        data: { label: 'Evaluation Decisions', description: "", width: (180 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
        isHidden: false,
        draggable: nodesDraggable,
        sourcePosition: "right",
        targetPosition: "left",
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return schema
}

const generateSelectionSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const emptyValues = ['', '-']
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.EvaluationDatum.year, season: stage.EvaluationDatum.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.EvaluationDatum.season.trim() && JSON.parse(st).year === stage.EvaluationDatum.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }
        const uniqueTraitSelectionMethods = new Set()
        const traitNames = []
        stage.SelectionStrategy.QualityTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.AgronomicTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.MorphologicalTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.PhenologicalTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.AbioticStressTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })
        stage.SelectionStrategy.BioticStressTraits.forEach(t => {
            if (!emptyValues.includes(t.selectionMethod))
                uniqueTraitSelectionMethods.add(BreedingStageColumnProperties.selectionMethod.lookup[t.selectionMethod])
            if (t.noOfEnvironments > 0)
                traitNames.push(`${t.columnName}(${t.breedingSurrogate})`)
        })

        schema.push({
            id: stage.SelectionStrategy.id,
            columnId: 3,
            type: "stageNode",
            data: {
                label: Array.from(uniqueTraitSelectionMethods).join(", "),
                description: traitNames.length > 0 && `[${traitNames.join(", ")}]`
            },
            isHidden: uniqueTraitSelectionMethods.size === 0,
            emptyNode: uniqueTraitSelectionMethods.size === 0,
            selectable: uniqueTraitSelectionMethods.size !== 0,
            draggable: nodesDraggable && uniqueTraitSelectionMethods.size !== 0,
            sourcePosition: "right",
            targetPosition: "left",
            position: position,
        })
    })
    schema.unshift({
        id: '3',
        columnId: 3,
        type: 'header',
        data: { label: 'Selection Decisions', description: "", width: (172 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
        isHidden: false,
        draggable: nodesDraggable,
        sourcePosition: "right",
        targetPosition: "left",
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return schema
}

const generateCrossingSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const emptyValues = ['', '-', 'none', '?']
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.EvaluationDatum.year, season: stage.EvaluationDatum.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.EvaluationDatum.season.trim() && JSON.parse(st).year === stage.EvaluationDatum.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }

        // schema.push({
        //     id: stage.CrossingStrategy.id,
        //     columnId: 4,
        //     type: "stageNode",
        //     data: {
        //         label: `${stage.CrossingStrategy.crossingMethod} ${!emptyValues.includes(stage.CrossingStrategy.parentCouplingMethod) && ` / ${stage.CrossingStrategy.parentCouplingMethod}`}`,
        //         description: `#Cross/#Progeny: ${stage.CrossingStrategy.totalNoOfCrosses}/${stage.CrossingStrategy.noOfProgenyPerCross} ${stage.CrossingStrategy.crossingUnit}`
        //     },
        //     isHidden: emptyValues.includes(stage.CrossingStrategy.crossingMethod),
        //     emptyNode: emptyValues.includes(stage.CrossingStrategy.crossingMethod),
        //     selectable: !emptyValues.includes(stage.CrossingStrategy.crossingMethod),
        //     draggable: nodesDraggable && !emptyValues.includes(stage.CrossingStrategy.crossingMethod),
        //     sourcePosition: "right",
        //     targetPosition: "left",
        //     position: position,
        // })
    })
    // schema.unshift({
    //     id: '4',
    //     columnId: 4,
    //     type: 'header',
    //     data: { label: 'Crossing / Multiplication Decisions', description: "", width: (172 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
    //     isHidden: false,
    //     draggable: nodesDraggable,
    //     sourcePosition: "right",
    //     targetPosition: "left",
    //     position: { x: startingPosition.x - 10, y: startingPosition.y - (maxParallelNodes === 1 ? 110 : 75) },
    // })
    return schema
}

const generateMolecularSchema = (breedingSchemeData, startingPosition, nodeVerticalMargin, nodeHorizontalMargin, nodesDraggable) => {
    const emptyValues = ['', '-', 'none', '?']
    const schema = []
    //Check Breeding Stage occuring on same year/season
    // SET Javascript Object saves unique values only: For our usecase, we stringfy the object (Year & Season) to evaluate
    let uniqueSeasons = new Set()
    let stageLevel = 0
    let nodePositions = []
    let maxParallelNodes = 1
    breedingSchemeData.forEach((stage) => {
        const currentUniqueSeasonSize = uniqueSeasons.size
        uniqueSeasons.add(JSON.stringify({ year: stage.EvaluationDatum.year, season: stage.EvaluationDatum.season }))

        let position = { x: startingPosition.x, y: startingPosition.y + (nodeVerticalMargin * stageLevel) }
        if (currentUniqueSeasonSize === uniqueSeasons.size) {
            const nodeIndex = Array.from(uniqueSeasons).findIndex(st =>
                JSON.parse(st).season.trim() === stage.EvaluationDatum.season.trim() && JSON.parse(st).year === stage.EvaluationDatum.year
            )
            const parallelNodes = nodePositions.filter(p => p.row === nodeIndex).length
            position = { x: startingPosition.x + (nodeHorizontalMargin * parallelNodes), y: startingPosition.y + (nodeVerticalMargin * nodeIndex) }
            nodePositions.push({ row: nodeIndex })
            maxParallelNodes = maxParallelNodes < (parallelNodes + 1) ? parallelNodes + 1 : maxParallelNodes
        } else {
            nodePositions.push({ row: stageLevel })
            stageLevel += 1
        }

        schema.push({
            id: stage.Molecular.id,
            columnId: 5,
            type: "stageNode",
            data: {
                label: `${stage.Molecular.molecularTechPurpose} (${stage.Molecular.molecularTechnologyAvailable})`
            },
            isHidden: emptyValues.includes(stage.Molecular.molecularTechPurpose),
            emptyNode: emptyValues.includes(stage.Molecular.molecularTechPurpose),
            selectable: !emptyValues.includes(stage.Molecular.molecularTechPurpose),
            draggable: nodesDraggable && !emptyValues.includes(stage.Molecular.molecularTechPurpose),
            sourcePosition: "right",
            targetPosition: "left",
            position: position,
        })
    })
    schema.unshift({
        id: '5',
        columnId: 5,
        type: 'header',
        data: { label: 'Molecular Decisions', description: "", width: (172 * maxParallelNodes) + (29 * (maxParallelNodes - 1)) },
        isHidden: false,
        draggable: nodesDraggable,
        sourcePosition: "right",
        targetPosition: "left",
        position: { x: startingPosition.x - 10, y: startingPosition.y - 75 },
    })
    return schema
}
import * as api from '../api';
import { showNotification } from './notification'
import { CHANGE_PASSWORD_REQUEST, CHANGE_PASSWORD_RESPONSE, CHANGE_PASSWORD_RESET } from '../constants/actionTypes'

//Action Creators
export const changePassword = (passwordObj) => async (dispatch) => {
    try {
        dispatch({ type: CHANGE_PASSWORD_REQUEST })
        const { data } = await api.changePassword(passwordObj)
        dispatch({ type: CHANGE_PASSWORD_RESPONSE, payload: data })
        if (data.success)
            dispatch(showNotification({
                type: "success",
                message: data.message
            }))
    } catch (error) {
        dispatch({
            type: CHANGE_PASSWORD_RESPONSE, payload: {
                success: false,
                message: "Error!"
            }
        })
    }
}

export const resetChangePassword = () => async (dispatch) => {
    try { dispatch({ type: CHANGE_PASSWORD_RESET }) }
    catch (error) { console.log(error) }
}
import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ALL_BREEDING_PIPELINE_REQUEST, FETCH_ALL_BREEDING_PIPELINE_RESPONSE,
    LOADING_BREEDING_PIPELINE, CREATE_BREEDING_PIPELINE_RESPONSE, UPDATE_BREEDING_PIPELINE_RESPONSE,
    CONFIRM_REMOVE_BREEDING_PIPELINE, CANCEL_REMOVE_BREEDING_PIPELINE, REMOVE_BREEDING_PIPELINE_RESPONSE,
    ADD_RENDERED_MARKET_SEGMENTS, REMOVE_RENDERED_MARKET_SEGMENTS, ADD_RENDERED_BREEDING_SCHEMES, REMOVE_RENDERED_BREEDING_SCHEMES,
    ADD_RENDERED_PRODUCT_PROFILES, REMOVE_RENDERED_PRODUCT_PROFILES
} from '../constants/actionTypes';

//Action Creators
export const getBreedingPipelines = (query) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_BREEDING_PIPELINE_REQUEST })
        const { data } = await api.fetchBreedingPipelines(query)
        //Trigger the guided tour for new users                
        dispatch({ type: FETCH_ALL_BREEDING_PIPELINE_RESPONSE, payload: { openTour: (!data.data.count && query.search === "") } })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
        return data
    } catch (error) {
        dispatch({ type: FETCH_ALL_BREEDING_PIPELINE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const createBreedingPipeline = (breedingPipelineObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_PIPELINE })
        await api.createBreedingPipeline(breedingPipelineObj).then(({ data }) => {
            dispatch({ type: CREATE_BREEDING_PIPELINE_RESPONSE })
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
        })
    } catch (error) {
        dispatch({ type: CREATE_BREEDING_PIPELINE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateBreedingPipeline = (breedingPipelineObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_PIPELINE })
        api.updateBreedingPipeline(breedingPipelineObj.id, breedingPipelineObj).then(({ data }) => {
            dispatch({ type: UPDATE_BREEDING_PIPELINE_RESPONSE })
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
        })
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_PIPELINE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveBreedingPipeline = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_BREEDING_PIPELINE, payload: {
            removePipelineForm: {
                breedingPipelineId: id,
                open: true
            }
        }
    })
}

export const cancelRemoveBreedingPipeline = (id) => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_BREEDING_PIPELINE, payload: {
            removePipelineForm: {
                breedingPipelineId: "",
                open: false
            }
        }
    })
}

export const removeBreedingPipeline = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_PIPELINE })
        const { data } = await api.removeBreedingPipeline(id)
        dispatch({
            type: REMOVE_BREEDING_PIPELINE_RESPONSE, payload: {
                removePipelineForm: {
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_BREEDING_PIPELINE_RESPONSE, payload: {
                removePipelineForm: {
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateRenderedMarketSegment = (id, isAdded) => async (dispatch) => {
    try {
        isAdded ? await dispatch({ type: ADD_RENDERED_MARKET_SEGMENTS, payload: id }) : await dispatch({ type: REMOVE_RENDERED_MARKET_SEGMENTS, payload: id })
    } catch (error) {
        console.log(error)
    }
}

export const updateRenderedBreedingSchemes = (id, isAdded) => async (dispatch) => {
    try {
        isAdded ? await dispatch({ type: ADD_RENDERED_BREEDING_SCHEMES, payload: id }) : await dispatch({ type: REMOVE_RENDERED_BREEDING_SCHEMES, payload: id })
    } catch (error) {
        console.log(error)
    }
}

export const updateRenderedProductProfiles = (id, isAdded) => async (dispatch) => {
    try {
        isAdded ? await dispatch({ type: ADD_RENDERED_PRODUCT_PROFILES, payload: id }) : await dispatch({ type: REMOVE_RENDERED_PRODUCT_PROFILES, payload: id })
    } catch (error) {
        console.log(error)
    }
}
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { login } from '../../../actions/userAuth'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress';
import AccountCircle from '@material-ui/icons/AccountCircle'
import LockIcon from '@material-ui/icons/Lock'
import Alert from '@material-ui/lab/Alert';
import { Link } from "react-router-dom"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    loginForm: {
        marginTop: "10px",
        padding: "30px 15px",
        borderRadius: "10px",
        border: "1px solid #d3d3d3",
        backgroundColor: "white"
    },
    textField: {
        marginBottom: "20px",
    }
}))

const SignInForm = (props) => {

    const [userAccount, setUserAccount] = useState({
        email: "",
        password: ""
    })

    const dispatch = useDispatch()

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const classes = useStyles()

    const handleChange = (e) => {
        setUserAccount({
            ...userAccount,
            [e.target.id]: e.target.value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (props.loginFormState.isLoading) return;
        dispatch(login(userAccount))
    }

    return (
        <Box width="90%" maxWidth="480px" mt={smallScreen ? 4 : 0} >
            <Typography variant="h5">Log In</Typography>
            {
                (props.loginFormState.message && !props.loginFormState.success) &&
                <Alert severity="error">{props.loginFormState.message}</Alert>
            }
            <form className={classes.loginForm} autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><AccountCircle /></Grid>
                    <Grid item xs={10}>
                        <TextField id="email" type="email" label="Email Address" placeholder="Enter Your Email" fullWidth onChange={handleChange} required />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><LockIcon /></Grid>
                    <Grid item xs={10}>
                        <TextField id="password" type="password" label="Password" placeholder="Enter Password" fullWidth onChange={handleChange} required />
                    </Grid>
                </Grid>
                <Box display="flex" flexDirection="row-reverse" mb={2} mr={4}>
                    <Typography variant="body2">
                        <Link to="/forgot-password" color="secondary">Forgot Password?</Link>
                    </Typography>
                </Box>
                <Box mb={1}>
                    <Button type="submit"
                        disabled={props.loginFormState.isLoading}
                        variant="contained" color="secondary" fullWidth>
                        Login
                        {
                            props.loginFormState.isLoading &&
                            <Box position="absolute" top="20%" right="25%">
                                <CircularProgress color="primary" size={20} thickness={5} />
                            </Box>
                        }
                    </Button>
                </Box>
            </form>
            <Box textAlign="center" mt={2}>
                <Typography>
                    Don't have an account? <Link to="/signup" color="secondary">Sign Up</Link>
                </Typography>
            </Box>
        </Box>
    )
}

export default SignInForm;
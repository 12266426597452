import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import BreedingSchemeFlowchartToolbar from "../BreedingSchemeFlowchartToolbar"
import BreedingSchemeFlowchartNodeForm from "../BreedingSchemeFlowchartNodeForm"
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddBoxIcon from '@material-ui/icons/AddBox'
import TimelineIcon from '@material-ui/icons/Timeline'
import BreedingSchemeFlowchartTooltip from '../../molecules/BreedingSchemeFlowchartTooltip';
import ReactFlow, { Background, Controls, addEdge, removeElements, Handle } from 'react-flow-renderer'
import { saveBreedingSchemeBlueprint, removeBreedingSchemeBlueprint } from '../../../actions/breedingSchemeFlowchart'
import { generateBreedingSchemeSchema } from '../../../utils'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { v4 as uuid4 } from 'uuid'
import html2canvas from 'html2canvas'
import jsPDF from 'jspdf'

const useStyles = makeStyles((theme) => ({
    root: {
        '& .react-flow__selection': {
            backgroundColor: "#33ab9f",
            opacity: 0.3
        },
        background: "radial-gradient(#2386C1, #0B64B1)",
        '& .react-flow__node': {
            borderRadius: "3px",
            backgroundColor: "transparent",
            border: "1px solid #fff",
            fontWeight: "bold",
            color: "#fff"
        },
        '& .react-flow__node.selected': {
            border: "1px dashed #fff",
            backgroundColor: "rgb(51, 171, 159, 0.4)"

        },
        '& .react-flow__handle': {
            backgroundColor: "#fff"
        },
        '& .react-flow__edge-path': {
            stroke: "#fff",
            strokeWidth: 1
        },
    },
    rulerHeaderNode: {
        color: "#0B64B1",
        border: "2px solid #0B64B1",
        backgroundColor: "#fff",
        textAlign: "center",
        '& svg': {
            color: "#0B64B1"
        }
    },
    rulerNode: {
        padding: "4px 10px",
        width: "100px",
        maxWidth: "100px",
        minHeight: "40px",
        maxHeight: "40px",
        verticalAlign: "middle",
        '& h6': {
            lineHeight: 1.4,
            fontSize: "0.9rem",
            fontWeight: "bolder"
        }
    },
    headerNode: {
        color: "#fff",
        border: "2px solid #fff",
        textAlign: "center"
    },
    nodeBtn: {
        padding: "0px",
        color: "#fff"
    },
    stageNode: {
        padding: "4px 10px",
        width: "152px",
        maxWidth: "172px",
        minHeight: "40px",
        maxHeight: "40px",
        verticalAlign: "middle",
        '& h6': {
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
        }
    }
}))

const BreedingSchemeFlowchart = (props) => {
    const dispatch = useDispatch()
    const [breedingSchemePermission, setBreedingSchemePermission] = useState("VIEWER")
    const [flowchartInstance, setFlowchartInstance] = useState(null)
    const [flowchartSchema, setFlowchartSchema] = useState([])
    const [nodesDraggable, setNodesDraggable] = useState(true)
    const [hiddenColumns, setHiddenColumns] = useState([])
    const [updatedNode, setUpdatedNode] = useState({
        id: "",
        type: "",
        position: {},
        data: { label: "", description: "" }
    })
    const [nodeFormModal, setNodeFormModal] = useState(false)

    const RulerNode = ({ data }) => {
        const classes = useStyles()
        return (
            <BreedingSchemeFlowchartTooltip title={data.label} description={data.description}>
                <Box className={classes.rulerNode} textAlign="center">
                    <Typography variant="subtitle2">{data.label}</Typography>
                    {data.description && <Typography variant="subtitle2">{data.description}</Typography>}
                </Box>
            </BreedingSchemeFlowchartTooltip>
        );
    };

    const RulerHeaderNode = ({ id, data, xPos }) => {
        const classes = useStyles()
        return (
            <Box display="flex" className={classes.rulerHeaderNode} p={1} width={data.width}>
                <TimelineIcon className={classes.nodeBtn} />
                <Typography style={{ flexGrow: 1, fontWeight: "bolder" }} variant="button">{data.label}</Typography>
            </Box>
        );
    };

    const HeaderNode = ({ id, data, xPos }) => {
        const classes = useStyles()
        return (
            <Box display="flex" className={classes.headerNode} p={1} width={data.width}>
                <Typography style={{ flexGrow: 1 }} variant="button">{data.label}</Typography>
                <Tooltip title="Add Node">
                    <IconButton className={classes.nodeBtn} onClick={() => handleNodeAdd(id, xPos)}>
                        <AddBoxIcon />
                    </IconButton>
                </Tooltip>
            </Box>
        );
    };

    const StageNode = ({ data }) => {
        const classes = useStyles()
        return (
            <BreedingSchemeFlowchartTooltip title={data.label} description={data.description}>
                <Box className={classes.stageNode} textAlign="center">
                    <Handle
                        type="source"
                        position="left"
                    />
                    <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>{data.label}</Typography>
                    {data.description && <Typography variant="subtitle2">{data.description}</Typography>}
                </Box>
            </BreedingSchemeFlowchartTooltip>
        );
    };

    const nodeTypes = {
        rulerHeader: RulerHeaderNode,
        rulerNode: RulerNode,
        header: HeaderNode,
        stageNode: StageNode
    };

    useEffect(() => {
        if (props.savedData && Object.keys(props.savedData).length > 0) {
            setFlowchartSchema(props.savedData.schema.elements)
            setBreedingSchemePermission(props.permission)
        } else if (props.schemeData.length > 0) {
            const breedingSchemeSchema = generateBreedingSchemeSchema(props.schemeData, nodesDraggable)
            setFlowchartSchema(breedingSchemeSchema)
            setBreedingSchemePermission(props.permission)
        }
    }, [props, nodesDraggable])

    const handleNodesDraggable = (draggableState) => {
        setNodesDraggable(draggableState)
    }

    const handleNodeAdd = (columnId = 1, xPos = -50) => {
        const newNode = {
            id: uuid4(),
            columnId: parseInt(columnId),
            type: "stageNode",
            data: {
                label: "[Untitled]",
                description: "[Text Placeholder]"
            },
            sourcePosition: "right",
            targetPosition: "left",
            position: { x: xPos, y: -40 },
        }
        setFlowchartSchema((schema) => schema.concat(newNode))
    }

    const handleNodeUpdate = (updatedNode) => {
        setFlowchartSchema((schema) =>
            schema.map((node) => {
                if (node.id === updatedNode.id) {
                    node.data = updatedNode.data
                }
                return node
            })
        )
        setNodeFormModal(false)
    }

    const handleElementsConnect = (params) => {
        params.animated = true
        setFlowchartSchema((els) => addEdge(params, els))
    };

    const handleElementsRemove = (elementToRemove) => {
        //Filter out Header & Ruler nodes from being removed
        const filteredNodes = elementToRemove.filter(n => n.type === "stageNode")
        setFlowchartSchema((els) => removeElements(filteredNodes, els));
    }

    const handleSaveFlowchart = async () => {
        if (setFlowchartInstance) {
            const breedingSchemeBlueprint = {
                BreedingSchemeId: props.BreedingSchemeId,
                schema: flowchartInstance.toObject()
            }
            await dispatch(saveBreedingSchemeBlueprint(breedingSchemeBlueprint))
            props.refreshFlowhcart()
        }
    }

    const handleResetFlowchart = async () => {
        await dispatch(removeBreedingSchemeBlueprint(props.BreedingSchemeId))
        const breedingSchemeSchema = await generateBreedingSchemeSchema(props.schemeData)
        setFlowchartSchema((schema) => breedingSchemeSchema.map(node => node))
    }

    const toggleColumns = (hiddenColumnsList) => {
        setHiddenColumns(hiddenColumnsList)
        setFlowchartSchema((schema) =>
            schema.map(node => {
                node.isHidden = hiddenColumnsList.includes(node.columnId) || node.emptyNode
                node.selectable = !(hiddenColumnsList.includes(node.columnId) || node.emptyNode)
                node.draggable = nodesDraggable && !(hiddenColumnsList.includes(node.columnId) || node.emptyNode)
                return node
            }))
    }

    const handleUpdateNodeFormModal = (e, updatedNodeObj) => {
        setUpdatedNode(updatedNodeObj)
        setNodeFormModal(true)
    }

    const handleCancelNodeFormModal = () => {
        setNodeFormModal(false)
    }

    const handleExportToPDF = () => {
        const input = document.getElementById("breedingSchemeBlueprint");
        const elementWidth = input.offsetWidth * 0.2645 * 2
        const elementHeight = input.offsetHeight * 0.2645 * 2
        html2canvas(input, {
            logging: false,
            scale: 2,
        }).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const pdf = new jsPDF({
                orientation: "landscape",
                format: [elementWidth, elementHeight]
            });
            pdf.addImage(imgData, 'PNG', 0, 0);
            pdf.save("Breeding_Scheme_Blueprint.pdf");
        })
    }

    const handleExportToImage = () => {
        const input = document.getElementById("breedingSchemeBlueprint");
        const saveAs = (blob, fileName) => {
            var elem = window.document.createElement('a');
            elem.href = blob
            elem.download = fileName;
            elem.style = 'display:none;';
            (document.body || document.documentElement).appendChild(elem);
            if (typeof elem.click === 'function') {
                elem.click();
            } else {
                elem.target = '_blank';
                elem.dispatchEvent(new MouseEvent('click', {
                    view: window,
                    bubbles: true,
                    cancelable: true
                }));
            }
            URL.revokeObjectURL(elem.href);
            elem.remove()
        }
        html2canvas(input).then((canvas) => {
            const imgData = canvas.toDataURL('image/png')
            const fileName = "Breeding-Scheme-Blueprint.png"
            saveAs(imgData, fileName)
        })
    }

    const contentHeight = document.getElementById("root").offsetHeight - 147

    const classes = useStyles()
    return (
        <Box>
            <BreedingSchemeFlowchartToolbar
                toggleColumns={toggleColumns}
                hiddenColumns={hiddenColumns}
                saveFlowchart={handleSaveFlowchart}
                resetFlowchart={handleResetFlowchart}
                handleExportToImage={handleExportToImage}
                handleExportToPDF={handleExportToPDF}
                permission={breedingSchemePermission}
            />
            <BreedingSchemeFlowchartNodeForm open={nodeFormModal} updatedNode={updatedNode} handleSubmit={handleNodeUpdate} handleCancel={handleCancelNodeFormModal} />
            <Box mt={2} px={0.3} height={contentHeight}>
                <ReactFlow
                    id="breedingSchemeBlueprint"
                    className={classes.root}
                    elements={flowchartSchema}
                    paneMoveable={true}
                    nodesDraggable={nodesDraggable}
                    nodeTypes={nodeTypes}
                    defaultZoom={0.82}
                    defaultPosition={[50, 80]}
                    onLoad={setFlowchartInstance}
                    onElementsRemove={handleElementsRemove}
                    onConnect={handleElementsConnect}
                    onNodeDoubleClick={handleUpdateNodeFormModal}
                    connectionMode="loose"
                >
                    <Background
                        variant="lines"
                        gap={15}
                        size={0.4}
                        color="#3194CF"
                    />
                    <Controls onInteractiveChange={handleNodesDraggable} />
                </ReactFlow>
            </Box>
        </Box>
    )
}

export default BreedingSchemeFlowchart
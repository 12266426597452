import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom'
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';

const useStyles = makeStyles((theme) => ({
    root: {
        maxWidth: 300,
    },
    media: {
        textAlign: "center",
        backgroundColor: "#ecf0f5",
        padding: "10px 0px",
        border: "1px solid #e3e3e3",
        '& svg': {
            fontSize: "70px"
        },
    },
}))

const UserCard = (props) => {
    const history = useHistory()
    const classes = useStyles();

    return (
        <Card className={classes.root}>
            <CardActionArea onClick={() => history.push("/profile")}>
                <CardMedia className={classes.media}>
                    <AccountCircleIcon />
                    <Typography>{props.user.fullName}</Typography>
                    <Typography variant="caption"> {props.user.email}</Typography>
                    <Typography variant="subtitle2"> {props.user.position}</Typography>
                </CardMedia>
            </CardActionArea>
            <CardActions>
                <Button size="small" color="primary" variant="outlined" onClick={() => history.push('/change-password')}>
                    Change Password
                </Button>
                <Button size="small" color="primary" variant="outlined" onClick={props.handleLogout}>
                    Logout
                </Button>
            </CardActions>
        </Card>
    );
}

export default UserCard
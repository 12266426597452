import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ALL_PRODUCT_PROFILE_RESPONSE, CREATE_PRODUCT_PROFILE_RESPONSE,
    UPDATE_PRODUCT_PROFILE_RESPONSE, CONFIRM_DUPLICATE_PRODUCT_PROFILE, CANCEL_DUPLICATE_PRODUCT_PROFILE,
    DUPLICATE_PRODUCT_PROFILE_RESPONSE, CONFIRM_REMOVE_PRODUCT_PROFILE, CANCEL_REMOVE_PRODUCT_PROFILE,
    REMOVE_PRODUCT_PROFILE_RESPONSE
} from '../constants/actionTypes'

//Action Creators
export const getProductProfiles = (id, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.fetchProductProfiles(id, queryObj)        
        dispatch({ type: FETCH_ALL_PRODUCT_PROFILE_RESPONSE, payload: { ...data, breedingPipelineId: id } })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_ALL_PRODUCT_PROFILE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const createProductProfile = (productProfileObj, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.createProductProfile(productProfileObj)
        dispatch({ type: CREATE_PRODUCT_PROFILE_RESPONSE })
        if (data.success) dispatch(getProductProfiles(productProfileObj.BreedingPipelineId, queryObj))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: CREATE_PRODUCT_PROFILE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateProductProfile = (productProfileObj, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.updateProductProfile(productProfileObj.id, productProfileObj)
        dispatch({ type: UPDATE_PRODUCT_PROFILE_RESPONSE })
        if (data.success) dispatch(getProductProfiles(productProfileObj.BreedingPipelineId, queryObj))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_PRODUCT_PROFILE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const orderProductProfile = (orderObj, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.orderProductProfile(orderObj)
        if (data.success) dispatch(getProductProfiles(orderObj.BreedingPipelineId, queryObj))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_PRODUCT_PROFILE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmDuplicateProductProfile = (id, breedingPipelineId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_DUPLICATE_PRODUCT_PROFILE, payload: {
            duplicateProductProfileForm: {
                productProfileId: id,
                breedingPipelineId: breedingPipelineId,
                open: true
            }
        }
    })
}

export const cancelDuplicateProductProfile = () => async (dispatch) => {
    dispatch({
        type: CANCEL_DUPLICATE_PRODUCT_PROFILE, payload: {
            duplicateProductProfileForm: {
                productProfileId: "",
                breedingPipelineId: "",
                open: false
            }
        }
    })
}

export const duplicateProductProfile = (id, breedingPipelineId, queryObj) => async (dispatch) => {
    try {
        dispatch({
            type: DUPLICATE_PRODUCT_PROFILE_RESPONSE, payload: {
                duplicateProductProfileForm: {
                    productProfileId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        const { data } = await api.duplicateProductProfile(id)
        if (data.success) {
            await dispatch(getProductProfiles(breedingPipelineId, queryObj))
        }
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
    } catch (error) {
        dispatch({
            type: DUPLICATE_PRODUCT_PROFILE_RESPONSE, payload: {
                duplicateProductProfileForm: {
                    productProfileId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveProductProfile = (id, breedingPipelineId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_PRODUCT_PROFILE, payload: {
            removeProductProfileForm: {
                productProfileId: id,
                breedingPipelineId: breedingPipelineId,
                open: true
            }
        }
    })
}

export const cancelRemoveProductProfile = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_PRODUCT_PROFILE, payload: {
            removeProductProfileForm: {
                productProfileId: "",
                breedingPipelineId: "",
                open: false
            }
        }
    })
}

export const removeProductProfile = (id, breedingPipelineId, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.removeProductProfile(id)
        dispatch({
            type: REMOVE_PRODUCT_PROFILE_RESPONSE, payload: {
                removeProductProfileForm: {
                    productProfileId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        if (data.success) dispatch(getProductProfiles(breedingPipelineId, queryObj))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_PRODUCT_PROFILE_RESPONSE, payload: {
                removeProductProfileForm: {
                    productProfileId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const exportProductProfiles = (breedingPipelineId) => async (dispatch) => {
    try {
        const { data } = await api.exportProductProfile(breedingPipelineId)
        if (data.success) window.open(`${window.location.origin}/api/product-profile/export/${breedingPipelineId}`)
        else dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { recoverPassword, reset, resetPassword } from '../../../actions/userAuth'
import Box from '@material-ui/core/Box'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography'
import Backdrop from '@material-ui/core/Backdrop'
import Button from '@material-ui/core/Button'
import Divider from '@material-ui/core/Divider'
import CircularProgress from '@material-ui/core/CircularProgress'
import LockIcon from '@material-ui/icons/Lock';

import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        background: "#ecf0f5",
        alignItems: "initial"
    },
    body: {
        maxWidth: "700px"
    },
    icon: {
        fontSize: "6rem",
        marginBottom: "10px"
    },
    button: {
        paddingLeft: "35px",
        paddingRight: "35px"
    }
}));

const ForgotPassword = (props) => {
    const userAuth = useSelector((state) => state.userAuth)
    const [forgotPasswordForm, setForgotPasswordForm] = useState({
        email: "",
        password: "",
        confirmPassword: "",
        passwordMatch: true
    })
    const dispatch = useDispatch()
    const history = useHistory()
    let { token } = useParams()
    const classes = useStyles();

    useEffect(() => {
        if (token)
            dispatch(reset(token))
    }, [token, dispatch])

    const passwordMatch = () => {
        return forgotPasswordForm.password === forgotPasswordForm.confirmPassword
    }

    const handleChange = (e) => {
        setForgotPasswordForm({
            ...forgotPasswordForm,
            [e.target.name]: e.target.value
        })
    }

    const handlePasswordRecover = (e) => {
        e.preventDefault()
        if (userAuth.forgotPassword && userAuth.forgotPassword.isLoading) return;
        dispatch(recoverPassword(forgotPasswordForm.email))
    }

    const handlePasswordReset = (e) => {
        e.preventDefault()
        if (userAuth.forgotPassword && userAuth.forgotPassword.isLoading) return;
        if (passwordMatch()) {
            setForgotPasswordForm({
                ...forgotPasswordForm,
                passwordMatch: true
            })

            dispatch(resetPassword(token, forgotPasswordForm.password))
        } else
            setForgotPasswordForm({
                ...forgotPasswordForm,
                passwordMatch: false
            })
    }

    return (
        <Backdrop className={classes.backdrop} open={true}>
            <Box my={5} textAlign="center">
                <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="Excellence in Breeding Platform" width="250" />
                <Box mt={7}>
                    <LockIcon color="primary" className={classes.icon} />
                    <Typography variant="h4">{token ? (userAuth.forgotPassword && userAuth.forgotPassword.passwordReset) ? "Password Changed" :
                        (userAuth.forgotPassword && userAuth.forgotPassword.tokenExpired) ? "Password Reset Token Expired" : "Reset Password" : "Forgot Password?"}</Typography>
                </Box>
                {token ?
                    (userAuth.forgotPassword && userAuth.forgotPassword.passwordReset) ?
                        <Box my={2} px={2} className={classes.body}>
                            <Typography variant="body1">Hi <b>{userAuth.user.fullName}</b>, you have successfully updated your password.</Typography>
                            <Typography variant="body1">You can now login to the Breeding Pipeline Manager using your new updated password.</Typography>
                            <Box my={4}>
                                <Button variant="contained" onClick={() => history.push("/login")} color="secondary">Go To Login</Button>
                            </Box>
                        </Box>
                        : (userAuth.forgotPassword && userAuth.forgotPassword.tokenExpired) ?
                            <Box my={2} px={2} className={classes.body}>
                                <Typography variant="body1">Password reset token is invalid or has expired. Please request a new token to recover your password.</Typography>
                                <Box my={4}>
                                    <Button variant="contained" onClick={() => history.push("/forgot-password")} color="secondary">Resend Password Reset Email</Button>
                                </Box>
                            </Box>
                            : <Box my={2} px={2} className={classes.body}>
                                <form autoComplete="off" onSubmit={handlePasswordReset}>
                                    <Box my={3} width={300}>
                                        <TextField
                                            id="password"
                                            name="password"
                                            label="New Password"
                                            size="small"
                                            type="password"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={forgotPasswordForm.password}
                                            placeholder="Enter Your New Password" required fullWidth />
                                    </Box>
                                    <Box my={3} width={300}>
                                        <TextField
                                            id="confirmPassword"
                                            name="confirmPassword"
                                            label="Confirm Password"
                                            error={!forgotPasswordForm.passwordMatch}
                                            helperText={!forgotPasswordForm.passwordMatch && "Password doesn't match"}
                                            size="small"
                                            type="password"
                                            variant="outlined"
                                            onChange={handleChange}
                                            value={forgotPasswordForm.confirmPassword}
                                            placeholder="Confirm Password" required fullWidth />
                                    </Box>
                                    <Box>
                                        <Button className={classes.button} type="submit" fullWidth variant="contained" color="secondary">
                                            Reset
                                            {
                                                (userAuth.forgotPassword && userAuth.forgotPassword.isLoading) &&
                                                <Box position="absolute" top="20%" right="2%">
                                                    <CircularProgress color="inherit" size={20} thickness={5} />
                                                </Box>
                                            }
                                        </Button>
                                    </Box>
                                </form>
                            </Box> :
                    <Box my={2} px={2} className={classes.body}>
                        <form autoComplete="off" onSubmit={handlePasswordRecover}>
                            <Box my={3} width={300}>
                                <TextField
                                    id="email"
                                    name="email"
                                    label="Email Address"
                                    size="small"
                                    type="email"
                                    variant="outlined"
                                    onChange={handleChange}
                                    value={forgotPasswordForm.email}
                                    placeholder="Enter Your Email Address" required fullWidth />
                            </Box>
                            <Box>
                                <Button className={classes.button} type="submit" fullWidth variant="contained" color="secondary">
                                    Submit
                                    {
                                        (userAuth.forgotPassword && userAuth.forgotPassword.isLoading) &&
                                        <Box position="absolute" top="20%" right="2%">
                                            <CircularProgress color="inherit" size={20} thickness={5} />
                                        </Box>
                                    }
                                </Button>
                            </Box>
                        </form>
                    </Box>
                }
                {!(userAuth.forgotPassword && userAuth.forgotPassword.passwordReset) &&
                    <>
                        <br /><br />
                        <Divider orientation="horizontal" />
                        <Box my={2}>
                            <Box mb={2}>
                                <Typography variant="body2">Take a different action.</Typography>
                            </Box>
                            <Box display="inline">
                                <Button variant="outlined" size="small" onClick={() => history.push("/signup")} color="primary">Register!</Button>
                            </Box>
                            <Box display="inline" mx={1}>
                                <Typography display="inline" variant="body2">or</Typography>
                            </Box>
                            <Box display="inline">
                                <Button variant="outlined" size="small" onClick={() => history.push("/login")} color="primary">Login!</Button>

                            </Box>
                        </Box>
                    </>
                }
            </Box>
        </Backdrop >
    );
}

export default ForgotPassword
import { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import InputAdornment from '@material-ui/core/InputAdornment'
import SaveIcon from '@material-ui/icons/Save'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { updateTraits, cancelUpdateTraits } from '../../../actions/breedingStage'

const useStyles = makeStyles((theme) => ({
    form: {
        maxWidth: "340px"
    },
    dialogTitle: {
        paddingBottom: "0px"
    },
    textField: {
        margin: "10px 0px"
    }
}))

const TraitsForm = () => {

    const dispatch = useDispatch()

    const traitFormState = useSelector((state) => state.breedingStageForms.updateTraitsForm)


    const [traitObj, setTraitObj] = useState({
        columnName: "",
        costForPhenotypingTrait: 0
    })

    useEffect(() => {
        setTraitObj({
            columnName: traitFormState.columnName,
            costForPhenotypingTrait: traitFormState.costForPhenotypingTrait
        })
    }, [traitFormState])

    const handleInputChange = (e) => {
        setTraitObj({
            ...traitObj,
            [e.target.name]: e.target.value
        })
    }

    const handleUpdateTraits = (e) => {
        e.preventDefault()
        dispatch(updateTraits({
            ...traitFormState,
            ...traitObj
        }))
    }

    const handleCancelUpdateTraits = () => {
        dispatch(cancelUpdateTraits())
    }

    const classes = useStyles()

    return (
        <Dialog
            open={traitFormState.open}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <form className={classes.form} onSubmit={handleUpdateTraits} autoComplete="off">
                <DialogTitle className={classes.dialogTitle} id="alert-dialog-title">Update Trait</DialogTitle>
                <DialogContent>
                    <TextField
                        autoFocus={true}
                        autoComplete="off"
                        margin="dense"
                        id="columnName"
                        name="columnName"
                        label="Trait Name"
                        value={traitObj.columnName}
                        className={classes.textField}
                        type="text"
                        required
                        onChange={handleInputChange}
                        fullWidth
                    />
                    <TextField
                        autoFocus={true}
                        autoComplete="off"
                        margin="dense"
                        id="costForPhenotypingTrait"
                        name="costForPhenotypingTrait"
                        label="Cost For Phenotyping Trait per Sample"
                        value={traitObj.costForPhenotypingTrait}
                        className={classes.textField}
                        type="number"
                        inputProps={{
                            step: "0.01"
                        }}
                        InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                        }}
                        required
                        onChange={handleInputChange}
                        fullWidth
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCancelUpdateTraits} color="primary" size="small" autoFocus>
                        Cancel
                </Button>
                    <Button
                        variant="contained"
                        type="submit"
                        color="secondary"
                        size="small"
                        startIcon={<SaveIcon />}
                    >
                        Save
                 </Button>
                </DialogActions>
            </form>
        </Dialog>
    )
}

export default TraitsForm;
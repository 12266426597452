import { useState } from 'react'
import Avatar from '@material-ui/core/Avatar';
import Typography from '@material-ui/core/Typography'
import Button from '@material-ui/core/Button'
import Popover from '@material-ui/core/Popover'
import UserCard from '../UserCard'
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles((theme) => ({
    button: {

    },
    avatar: {
        width: theme.spacing(3),
        height: theme.spacing(3),
        marginRight: "10px"
    },
    popover: {
        '& .MuiPopover-paper': {
            top: "53px !important"
        }
    }
}))
const LoggedAccount = (props) => {
    const classes = useStyles()
    const [anchorEl, setAnchorEl] = useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget)
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <>
            <Button className={props.className} color="inherit" onClick={handleClick}>
                <Avatar className={classes.avatar} />
                <Typography variant="subtitle2">{props.user.fullName}</Typography>
            </Button>
            <Popover
                open={open}
                className={classes.popover}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
            >
                <UserCard user={props.user} handleLogout={props.handleLogout} />
            </Popover>
        </>
    )
}

export default LoggedAccount;
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import TextField from '@material-ui/core/TextField'
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Checkbox from '@material-ui/core/Checkbox';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import PeopleIcon from '@material-ui/icons/People';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import Chip from '@material-ui/core/Chip';
import Autocomplete, { createFilterOptions } from '@material-ui/lab/Autocomplete';
import Avatar from '@material-ui/core/Avatar';
import SharedList from '../SharedList'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { sharingPermissions } from '../../../constants'
import { getUnsharedUsers, shareContent } from '../../../actions/share'

const useStyles = makeStyles((theme) => ({
    dialog: {
        backgroundColor: "transparent"
    },
    dialogContent: {
        padding: "8px 20px"
    },
    dialogTitle: {
        padding: "20px 20px 0px 20px",
        '& .MuiAvatar-root': {
            backgroundColor: "#2962ff",
            height: "36px",
            width: "36px"
        },
        '& .MuiDialogTitle-root': {
            fontSize: "2rem",
            padding: "0px",
            margin: "5px 0 0 10px"
        }
    },
    textField: {
        margin: "10px 0px",
        flexGrow: 1
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 100,

    },
    select: {
        '& .MuiSelect-select': {
            padding: "10px",
            fontSize: "0.9rem"
        }
    }
}))

const UserOption = ({ userObj, selected }) => {
    const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
    const checkedIcon = <CheckBoxIcon fontSize="small" />;
    return (
        <>
            <Checkbox icon={icon} checkedIcon={checkedIcon} checked={selected} style={{ marginRight: "8px" }} />
            <Box>
                <Typography variant="body1">{userObj.fullName}</Typography>
                <Typography variant="subtitle2">{userObj.email}</Typography>
            </Box>
        </>
    )
}

const filterOptions = createFilterOptions({
    stringify: (option) => `${option.fullName} ${option.email}`,
});
//contentId will be used as a unique identifier to the sharing group along with contentType
const ShareDialog = ({ isOpen, BreedingPipelineId, marketSegmentIds = [], breedingSchemeIds = [], productProfileIds = [], groupId, handleCloseShare, handleCancelShare }) => {
    const dispatch = useDispatch()
    const [userQuery, setUserQuery] = useState("")
    const [users, setUsers] = useState([])
    const [shareObj, setShareObj] = useState({
        userIds: [],
        BreedingPipelineId: "",
        marketSegmentIds: [],
        breedingSchemeIds: [],
        productProfileIds: [],
        groupId: "",
        permission: "VIEWER"
    })

    useEffect(() => {
        if (groupId !== shareObj.groupId)
            setShareObj({
                ...shareObj,
                BreedingPipelineId: BreedingPipelineId,
                marketSegmentIds: marketSegmentIds,
                breedingSchemeIds: breedingSchemeIds,
                productProfileIds: productProfileIds,
                groupId: groupId
            })
    }, [shareObj, BreedingPipelineId, marketSegmentIds, breedingSchemeIds, productProfileIds, groupId])

    useEffect(() => {
        const fetchUnsharedUsers = async (query, groupId, marketSegmentId, breedingSchemeId, productProfileId) => {
            const filteredUsers = await dispatch(getUnsharedUsers({ query, groupId, marketSegmentId, breedingSchemeId, productProfileId }))
            setUsers(filteredUsers.data)
        }
        if (userQuery) {
            //Pass contentId for line items in order to show to whom it's shared with
            const marketSegmentId = shareObj.marketSegmentIds.length === 1 ? shareObj.marketSegmentIds[0] : null
            const breedingSchemeId = shareObj.breedingSchemeIds.length === 1 ? shareObj.breedingSchemeIds[0] : null
            const productProfileId = shareObj.productProfileIds.length === 1 ? shareObj.productProfileIds[0] : null
            fetchUnsharedUsers(userQuery, shareObj.groupId, marketSegmentId, breedingSchemeId, productProfileId)
        }
    }, [userQuery, shareObj, dispatch])

    const handleChange = (value) => {
        const userIds = value.map(user => user.id)
        setShareObj({ ...shareObj, userIds: userIds })
        setUserQuery(" ")
    }

    const handleSubmit = async () => {
        const response = await dispatch(shareContent(shareObj))
        if (response.success) {
            setUsers([])
            handleCloseShare()
        }
    }

    const classes = useStyles()
    return (
        <Dialog
            open={isOpen}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            PaperProps={{
                className: classes.dialog
            }}
        >
            <Box borderRadius="6px" bgcolor="#fff">
                <Box display="flex" className={classes.dialogTitle}>
                    <Avatar>
                        <PersonAddIcon />
                    </Avatar>
                    <DialogTitle id="alert-dialog-title">
                        Share with people
                    </DialogTitle>
                </Box>
                <DialogContent className={classes.dialogContent}>
                    <Box display="flex">
                        <Autocomplete
                            multiple
                            className={classes.textField}
                            id="userId"
                            name="userId"
                            options={users}
                            filterOptions={filterOptions}
                            onChange={(e, newValue) => handleChange(newValue)}
                            getOptionLabel={user => user.fullName}
                            getOptionSelected={(option, value) => option.id === value.id}
                            renderOption={(user, { selected }) => <UserOption userObj={user} selected={selected} />}
                            renderTags={(value, getTagProps) =>
                                value.map((user, index) => (
                                    <Chip variant="default" label={user.fullName} {...getTagProps({ index })} />
                                ))
                            }
                            renderInput={(params) => (
                                <TextField required {...params} variant="standard" placeholder="Add people"
                                    onChange={(e) => setUserQuery(e.target.value)} />
                            )}
                        />
                        <FormControl variant="outlined" className={classes.formControl}>
                            <Select
                                className={classes.select}
                                labelId="sharePermission-label"
                                id="sharePermission"
                                value={shareObj.permission}
                                renderValue={(value) => <Typography variant="caption">{value}</Typography>}
                                onChange={(e) => setShareObj({ ...shareObj, permission: e.target.value })}
                            >
                                {sharingPermissions.map((permission, idx) =>
                                    permission.value === "OWNER" ?
                                        <MenuItem key={idx} value={permission.value} style={{ borderTop: "1px solid RGB(0,0,0,0.2)" }}>
                                            <b>{permission.name}</b>
                                        </MenuItem>
                                        :
                                        <MenuItem key={idx} value={permission.value}>{permission.name}</MenuItem>
                                )}
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={handleCancelShare} color="primary" size="small" autoFocus>
                        Cancel
                    </Button>
                    <Button
                        disabled={shareObj.userIds.length === 0}
                        variant="contained"
                        type="button"
                        color="secondary"
                        size="medium"
                        onClick={handleSubmit}
                    >
                        Share
                    </Button>
                </DialogActions>
            </Box>
            {/* Access List Section */}
            <Box mt={1.5} borderRadius="8px" bgcolor="#fff">
                <Box display="flex" className={classes.dialogTitle}>
                    <Avatar style={{ backgroundColor: "#152733" }}>
                        <PeopleIcon />
                    </Avatar>
                    <DialogTitle id="alert-dialog-title">
                        Access List
                    </DialogTitle>
                </Box>
                <DialogContent className={classes.dialogContent}>
                    <SharedList
                        groupId={groupId}
                        BreedingPipelineId={BreedingPipelineId}
                        marketSegmentIds={marketSegmentIds}
                        breedingSchemeIds={breedingSchemeIds}
                        productProfileIds={productProfileIds} />
                </DialogContent>
                <DialogActions className={classes.dialogActions}>
                    <Button onClick={handleCloseShare} variant="contained" color="secondary" size="medium">
                        Done
                    </Button>
                </DialogActions>
            </Box>
        </Dialog>
    )
}

export default ShareDialog
import {
    CONFIRM_DUPLICATE_STAGE, CANCEL_DUPLICATE_STAGE, DUPLICATE_STAGE_RESPONSE,
    OPEN_UPDATE_TRAITS, UPDATE_TRAITS_RESPONSE, CANCEL_UPDATE_TRAITS,
    CONFIRM_REMOVE_ITEM, CANCEL_REMOVE_ITEM, REMOVE_ITEM_RESPONSE
} from '../constants/actionTypes';

const initialState = {
    success: false,
    message: null,
    duplicateStageForm: {
        breedingStageId: "",
        open: false
    },
    removeStageForm: {
        breedingStageId: "",
        open: false
    },
    updateTraitsForm: {
        traitsGroupId: "",
        BreedingSchemeId: "",
        columnName: "",
        costForPhenotypingTrait: 0,
        open: false
    },
    removeTraitsForm: {
        traitsGroupId: "",
        open: false
    },
    removePoolsForm: {
        poolsGroupId: "",
        open: false
    }
}
const reducer = (breedingStageForms = initialState, action) => {
    switch (action.type) {
        // case FETCH_ALL_BREEDING_STAGE_REQUEST:
        //     return {
        //         data: [],
        //         breedingPipeline: {},
        //         isLoading: true,
        //         duplicateStageForm: {
        //             breedingStageId: "",
        //             open: false
        //         },
        //         removeStageForm: {
        //             breedingStageId: "",
        //             open: false
        //         },
        //         updateTraitsForm: {
        //             traitsGroupId: "",
        //             BreedingSchemeId: "",
        //             columnName: "",
        //             costForPhenotypingTrait: 0,
        //             open: false
        //         },
        //         removeTraitsForm: {
        //             traitsGroupId: "",
        //             open: false
        //         },
        //     }        
        case CONFIRM_DUPLICATE_STAGE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_DUPLICATE_STAGE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case DUPLICATE_STAGE_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case OPEN_UPDATE_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case UPDATE_TRAITS_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_UPDATE_TRAITS:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CONFIRM_REMOVE_ITEM:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case CANCEL_REMOVE_ITEM:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        case REMOVE_ITEM_RESPONSE:
            return {
                ...breedingStageForms,
                ...action.payload
            }
        default:
            return breedingStageForms
    }
}

export default reducer
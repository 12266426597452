import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import Box from "@material-ui/core/Box"
import Typography from "@material-ui/core/Typography"
import makeStyles from "@material-ui/core/styles/makeStyles"

const useStyles = makeStyles(() => ({
    root: {
        width: "100%"
    },
    costContainer: {
        textAlign: "center",
        display: "inline-block",
        borderLeft: "1px solid #e3e3e3",
        borderRight: "1px solid #e3e3e3"

    },
    label: {
        color: "rgba(0, 0, 0, 0.87)",
        fontSize: "0.89rem",
        textDecoration: "underline",
        textUnderlineOffset: "4px",
        paddingBottom: "12px",
        display: "block"
    },
    value: {
        color: "rgba(0, 0, 0, 0.87)",
        fontWeight: "bold",
        fontSize: "1.25rem"
    }
}))

const BreedingStageSummary = (props) => {
    const [tableSummary, setTableSummary] = useState({ ...props })
    const breedingStageTableState = useSelector((state) => state.breedingStageTable.data)

    useEffect(() => {
        if (breedingStageTableState.length > 0) {
            reCalculateTableSummary()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breedingStageTableState])

    const reCalculateTableSummary = () => {
        const totalCosts = {
            evaluationSeasonCost: 0,
            genotypingCost: 0,
            selectionSeasonCost: 0,
            crossingSeasonCost: 0
        }
        breedingStageTableState.forEach((stage) => {
            //Calculate total amount for columns with cost value        
            totalCosts.evaluationSeasonCost += stage.RecyclingStrategy.evaluationSeasonCost
            totalCosts.genotypingCost += stage.Molecular.genotypingCost
            totalCosts.selectionSeasonCost += stage.RecyclingStrategy.selectionSeasonCost
            totalCosts.crossingSeasonCost += 0//stage.CrossingStrategy.crossingSeasonCost
        })
        setTableSummary({ ...totalCosts })
    }

    const classes = useStyles()
    return (
        <Box className={classes.root} ml={1}>
            <Box className={classes.costContainer} px={3}>
                <Typography className={classes.label} variant="caption">Total Evaluation Season Cost</Typography>
                <span className={classes.value}>$ {tableSummary.evaluationSeasonCost && tableSummary.evaluationSeasonCost.toLocaleString()} </span>
            </Box>
            <Box className={classes.costContainer} px={3}>
                <Typography className={classes.label} variant="caption">Total Genotyping Cost</Typography>
                <span className={classes.value}>$ {tableSummary.genotypingCost && tableSummary.genotypingCost.toLocaleString()}</span>
            </Box>
            <Box className={classes.costContainer} px={3}>
                <Typography className={classes.label} variant="caption">Total Selection Season Cost</Typography>
                <span className={classes.value}>$ {tableSummary.selectionSeasonCost && tableSummary.selectionSeasonCost.toLocaleString()}</span>
            </Box>
            {/* <Box className={classes.costContainer} px={3}>
                <Typography className={classes.label} variant="caption">Total Crossing Season Cost</Typography>
                <span className={classes.value}>$ {tableSummary.crossingSeasonCost && tableSummary.crossingSeasonCost.toLocaleString()}</span>
            </Box> */}
        </Box>
    )
}

export default BreedingStageSummary
import { UPDATE_BREEDING_STAGE_CELLS_REQUEST, UPDATE_BREEDING_STAGE_CELLS_RESPONSE, CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST } from '../constants/actionTypes';

const initialState = {
    data: [],
    isLoading: false,
}
const reducer = (breedingStageTable = initialState, action) => {
    switch (action.type) {
        case UPDATE_BREEDING_STAGE_CELLS_REQUEST:
            return {
                data: [],
                isLoading: true
            }
        case UPDATE_BREEDING_STAGE_CELLS_RESPONSE:
            if (action.payload.data)
                action.payload.data.map((bs, idx) => bs.stageNo = idx + 1)
            return {
                ...breedingStageTable,
                ...action.payload,
                isLoading: false
            }
        case CANCEL_UPDATE_BREEDING_STAGE_CELLS_REQUEST:
            return {
                ...breedingStageTable,
                isLoading: false
            }
        default:
            return breedingStageTable
    }
}

export default reducer
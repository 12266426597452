import { useState, useEffect } from 'react'
import { Link, useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Header from '../../organisms/Header'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Footer from '../../organisms/Footer'
import Typography from '@material-ui/core/Typography'
import ProductProfileTrait from '../../organisms/ProductProfileTrait'
import AppsIcon from '@material-ui/icons/Apps';
import ViewListIcon from '@material-ui/icons/ViewList';
import makeStyles from '@material-ui/core/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    divider: {
        height: 28,
        margin: 0,
        background: "#999"
    },
    breadcrumb: {
        color: "#333",
        '& a': {
            textDecoration: "none",
            color: "inherit"
        }
    },
    breadcrumbIcon: {
        marginRight: theme.spacing(0.5),
        width: 20,
        height: 20,
    }
}))

const ProductProfilePage = () => {
    const productProfileTraitState = useSelector((state) => state.productProfileTraits)
    const [headerContent, setHeaderContent] = useState({
        breedingPipeline: "",
        name: "",
        marketSegment: "",
        headerPage: "productProfile"
    })
    let { id } = useParams();

    useEffect(() => {
        if (productProfileTraitState.data.length) {
            const marketSegmentName = productProfileTraitState.data[0] ?
                productProfileTraitState.data[0].ProductProfile.ProductProfileMarketSegments.map(pm => pm.MarketSegment.name).join(", ")
                : ""
            setHeaderContent({
                ...headerContent,
                breedingPipeline: productProfileTraitState.data[0].ProductProfile.BreedingPipeline.specie,
                marketSegment: marketSegmentName,
                name: productProfileTraitState.data[0].ProductProfile.name
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productProfileTraitState])

    const classes = useStyles()
    return (
        <>
            <Header content={productProfileTraitState.data.length > 0 ? headerContent : {}} />
            <Box m={2} pb={7}>
                <Box m={0} position="absolute">
                    <Breadcrumbs aria-label="breadcrumb" className={classes.breadcrumb}>
                        <Link color="inherit" to={productProfileTraitState.permission === "OWNER" ? "/" : "/shared"} className={classes.link}>
                            <Box display="flex">
                                <AppsIcon className={classes.breadcrumbIcon} />
                                <Typography variant="subtitle2">
                                    {productProfileTraitState.permission !== "OWNER" && "Shared"} Breeding Pipelines
                                </Typography>
                            </Box>
                        </Link>
                        <Box display="flex">
                            <ViewListIcon className={classes.breadcrumbIcon} />
                            <Typography variant="subtitle2" color="textPrimary" className={classes.link}>
                                Product Profiles
                            </Typography>
                        </Box>
                    </Breadcrumbs>
                </Box>
                <Box marginLeft="25%" textAlign="center">
                    {productProfileTraitState.data.length > 0 ?
                        <Typography variant="subtitle2">
                            Market Segment: <u>{headerContent.marketSegment}</u>
                        </Typography> :
                        <Typography>&nbsp;</Typography>
                    }
                </Box>
                <Box my={3}>
                    <Grid container spacing={0}>
                        <Grid item xs={12} md={12}>
                            <ProductProfileTrait productProfileId={id} />
                        </Grid>
                    </Grid>
                </Box>
            </Box>
            <Footer />
        </>
    )
}

export default ProductProfilePage;
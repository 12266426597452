import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Box from '@material-ui/core/Box'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Navigation from '../../organisms/Navigation'
import UsersTable from '../../organisms/UsersTable'

const UsersPage = () => {
    const userAuth = useSelector((state) => state.userAuth)
    const history = useHistory()

    useEffect(() => {
        if (userAuth.user.role !== "admin")
            history.push("/")
    }, [userAuth.user.role, history])

    return (
        <>
            <Header />
            <Box my={2} pb={7} display="flex">
                <Navigation />
                <UsersTable />
            </Box>
            <Footer />
        </>
    )
}

export default UsersPage;
import { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux';
import List from '@material-ui/core/List';
import makeStyles from '@material-ui/core/styles/makeStyles'
import { getSharedUsers } from '../../../actions/share'
import SharedListItem from '../SharedListItem';

const useStyles = makeStyles((theme) => ({
    list: {
        width: '100%',
        minWidth: "560px",
        maxHeight: "240px",
        overflow: "auto",
        '& .MuiListItem-root': {
            padding: "3px 0px"
        },
        '& .MuiListItemText-primary': {
            fontSize: "0.9rem",
            fontWeight: "bold"
        },
        '& .MuiListItemText-secondary': {
            fontSize: "0.875rem"
        }
    }
}))

const SharedList = ({ groupId, BreedingPipelineId, marketSegmentIds, breedingSchemeIds, productProfileIds }) => {
    const dispatch = useDispatch()
    const [sharedListObj, setSharedListObj] = useState({
        groupId: "",
        BreedingPipelineId: "",
        marketSegmentIds: [],
        breedingSchemeIds: [],
        productProfileIds: []
    })
    const [sharedUsers, setSharedUsers] = useState([])

    useEffect(() => {
        if (groupId !== sharedListObj.groupId) {
            setSharedListObj({
                ...sharedListObj,
                groupId: groupId,
                BreedingPipelineId: BreedingPipelineId,
                marketSegmentIds: marketSegmentIds,
                breedingSchemeIds: breedingSchemeIds,
                productProfileIds: productProfileIds
            })
        }
    }, [groupId, sharedListObj, BreedingPipelineId, marketSegmentIds, breedingSchemeIds, productProfileIds])

    useEffect(() => {
        const fetchSharedUsers = async (shareObj) => {
            const filteredUsers = await dispatch(getSharedUsers(shareObj))
            setSharedUsers(filteredUsers.data)
        }
        if (sharedListObj.groupId) {
            //Pass contentId for line items in order to show to whom it's shared with            
            const marketSegmentId = sharedListObj.marketSegmentIds.length === 1 ? sharedListObj.marketSegmentIds[0] : null
            const breedingSchemeId = sharedListObj.breedingSchemeIds.length === 1 ? sharedListObj.breedingSchemeIds[0] : null
            const productProfileId = sharedListObj.productProfileIds.length === 1 ? sharedListObj.productProfileIds[0] : null
            fetchSharedUsers({ groupId: sharedListObj.groupId, BreedingPipelineId: sharedListObj.BreedingPipelineId, marketSegmentId, breedingSchemeId, productProfileId })
        }
    }, [sharedListObj, dispatch])

    const classes = useStyles()
    return (
        <List className={classes.list}>
            {sharedUsers.map((userObj, idx) =>
                <SharedListItem key={idx} userObj={userObj} groupId={groupId} />
            )}
        </List >
    )
}

export default SharedList
export const countries = [
    { code: 'AD', label: 'Andorra', phone: '376' },
    { code: 'AE', label: 'United Arab Emirates', phone: '971' },
    { code: 'AF', label: 'Afghanistan', phone: '93' },
    { code: 'AG', label: 'Antigua and Barbuda', phone: '1-268' },
    { code: 'AI', label: 'Anguilla', phone: '1-264' },
    { code: 'AL', label: 'Albania', phone: '355' },
    { code: 'AM', label: 'Armenia', phone: '374' },
    { code: 'AO', label: 'Angola', phone: '244' },
    { code: 'AQ', label: 'Antarctica', phone: '672' },
    { code: 'AR', label: 'Argentina', phone: '54' },
    { code: 'AS', label: 'American Samoa', phone: '1-684' },
    { code: 'AT', label: 'Austria', phone: '43' },
    { code: 'AU', label: 'Australia', phone: '61', suggested: true },
    { code: 'AW', label: 'Aruba', phone: '297' },
    { code: 'AX', label: 'Alland Islands', phone: '358' },
    { code: 'AZ', label: 'Azerbaijan', phone: '994' },
    { code: 'BA', label: 'Bosnia and Herzegovina', phone: '387' },
    { code: 'BB', label: 'Barbados', phone: '1-246' },
    { code: 'BD', label: 'Bangladesh', phone: '880' },
    { code: 'BE', label: 'Belgium', phone: '32' },
    { code: 'BF', label: 'Burkina Faso', phone: '226' },
    { code: 'BG', label: 'Bulgaria', phone: '359' },
    { code: 'BH', label: 'Bahrain', phone: '973' },
    { code: 'BI', label: 'Burundi', phone: '257' },
    { code: 'BJ', label: 'Benin', phone: '229' },
    { code: 'BL', label: 'Saint Barthelemy', phone: '590' },
    { code: 'BM', label: 'Bermuda', phone: '1-441' },
    { code: 'BN', label: 'Brunei Darussalam', phone: '673' },
    { code: 'BO', label: 'Bolivia', phone: '591' },
    { code: 'BR', label: 'Brazil', phone: '55' },
    { code: 'BS', label: 'Bahamas', phone: '1-242' },
    { code: 'BT', label: 'Bhutan', phone: '975' },
    { code: 'BV', label: 'Bouvet Island', phone: '47' },
    { code: 'BW', label: 'Botswana', phone: '267' },
    { code: 'BY', label: 'Belarus', phone: '375' },
    { code: 'BZ', label: 'Belize', phone: '501' },
    { code: 'CA', label: 'Canada', phone: '1', suggested: true },
    { code: 'CC', label: 'Cocos (Keeling) Islands', phone: '61' },
    { code: 'CD', label: 'Congo, Democratic Republic of the', phone: '243' },
    { code: 'CF', label: 'Central African Republic', phone: '236' },
    { code: 'CG', label: 'Congo, Republic of the', phone: '242' },
    { code: 'CH', label: 'Switzerland', phone: '41' },
    { code: 'CI', label: "Cote d'Ivoire", phone: '225' },
    { code: 'CK', label: 'Cook Islands', phone: '682' },
    { code: 'CL', label: 'Chile', phone: '56' },
    { code: 'CM', label: 'Cameroon', phone: '237' },
    { code: 'CN', label: 'China', phone: '86' },
    { code: 'CO', label: 'Colombia', phone: '57' },
    { code: 'CR', label: 'Costa Rica', phone: '506' },
    { code: 'CU', label: 'Cuba', phone: '53' },
    { code: 'CV', label: 'Cape Verde', phone: '238' },
    { code: 'CW', label: 'Curacao', phone: '599' },
    { code: 'CX', label: 'Christmas Island', phone: '61' },
    { code: 'CY', label: 'Cyprus', phone: '357' },
    { code: 'CZ', label: 'Czech Republic', phone: '420' },
    { code: 'DE', label: 'Germany', phone: '49', suggested: true },
    { code: 'DJ', label: 'Djibouti', phone: '253' },
    { code: 'DK', label: 'Denmark', phone: '45' },
    { code: 'DM', label: 'Dominica', phone: '1-767' },
    { code: 'DO', label: 'Dominican Republic', phone: '1-809' },
    { code: 'DZ', label: 'Algeria', phone: '213' },
    { code: 'EC', label: 'Ecuador', phone: '593' },
    { code: 'EE', label: 'Estonia', phone: '372' },
    { code: 'EG', label: 'Egypt', phone: '20' },
    { code: 'EH', label: 'Western Sahara', phone: '212' },
    { code: 'ER', label: 'Eritrea', phone: '291' },
    { code: 'ES', label: 'Spain', phone: '34' },
    { code: 'ET', label: 'Ethiopia', phone: '251' },
    { code: 'FI', label: 'Finland', phone: '358' },
    { code: 'FJ', label: 'Fiji', phone: '679' },
    { code: 'FK', label: 'Falkland Islands (Malvinas)', phone: '500' },
    { code: 'FM', label: 'Micronesia, Federated States of', phone: '691' },
    { code: 'FO', label: 'Faroe Islands', phone: '298' },
    { code: 'FR', label: 'France', phone: '33', suggested: true },
    { code: 'GA', label: 'Gabon', phone: '241' },
    { code: 'GB', label: 'United Kingdom', phone: '44' },
    { code: 'GD', label: 'Grenada', phone: '1-473' },
    { code: 'GE', label: 'Georgia', phone: '995' },
    { code: 'GF', label: 'French Guiana', phone: '594' },
    { code: 'GG', label: 'Guernsey', phone: '44' },
    { code: 'GH', label: 'Ghana', phone: '233' },
    { code: 'GI', label: 'Gibraltar', phone: '350' },
    { code: 'GL', label: 'Greenland', phone: '299' },
    { code: 'GM', label: 'Gambia', phone: '220' },
    { code: 'GN', label: 'Guinea', phone: '224' },
    { code: 'GP', label: 'Guadeloupe', phone: '590' },
    { code: 'GQ', label: 'Equatorial Guinea', phone: '240' },
    { code: 'GR', label: 'Greece', phone: '30' },
    { code: 'GS', label: 'South Georgia and the South Sandwich Islands', phone: '500' },
    { code: 'GT', label: 'Guatemala', phone: '502' },
    { code: 'GU', label: 'Guam', phone: '1-671' },
    { code: 'GW', label: 'Guinea-Bissau', phone: '245' },
    { code: 'GY', label: 'Guyana', phone: '592' },
    { code: 'HK', label: 'Hong Kong', phone: '852' },
    { code: 'HM', label: 'Heard Island and McDonald Islands', phone: '672' },
    { code: 'HN', label: 'Honduras', phone: '504' },
    { code: 'HR', label: 'Croatia', phone: '385' },
    { code: 'HT', label: 'Haiti', phone: '509' },
    { code: 'HU', label: 'Hungary', phone: '36' },
    { code: 'ID', label: 'Indonesia', phone: '62' },
    { code: 'IE', label: 'Ireland', phone: '353' },
    { code: 'IL', label: 'Israel', phone: '972' },
    { code: 'IM', label: 'Isle of Man', phone: '44' },
    { code: 'IN', label: 'India', phone: '91' },
    { code: 'IO', label: 'British Indian Ocean Territory', phone: '246' },
    { code: 'IQ', label: 'Iraq', phone: '964' },
    { code: 'IR', label: 'Iran, Islamic Republic of', phone: '98' },
    { code: 'IS', label: 'Iceland', phone: '354' },
    { code: 'IT', label: 'Italy', phone: '39' },
    { code: 'JE', label: 'Jersey', phone: '44' },
    { code: 'JM', label: 'Jamaica', phone: '1-876' },
    { code: 'JO', label: 'Jordan', phone: '962' },
    { code: 'JP', label: 'Japan', phone: '81', suggested: true },
    { code: 'KE', label: 'Kenya', phone: '254' },
    { code: 'KG', label: 'Kyrgyzstan', phone: '996' },
    { code: 'KH', label: 'Cambodia', phone: '855' },
    { code: 'KI', label: 'Kiribati', phone: '686' },
    { code: 'KM', label: 'Comoros', phone: '269' },
    { code: 'KN', label: 'Saint Kitts and Nevis', phone: '1-869' },
    { code: 'KP', label: "Korea, Democratic People's Republic of", phone: '850' },
    { code: 'KR', label: 'Korea, Republic of', phone: '82' },
    { code: 'KW', label: 'Kuwait', phone: '965' },
    { code: 'KY', label: 'Cayman Islands', phone: '1-345' },
    { code: 'KZ', label: 'Kazakhstan', phone: '7' },
    { code: 'LA', label: "Lao People's Democratic Republic", phone: '856' },
    { code: 'LB', label: 'Lebanon', phone: '961' },
    { code: 'LC', label: 'Saint Lucia', phone: '1-758' },
    { code: 'LI', label: 'Liechtenstein', phone: '423' },
    { code: 'LK', label: 'Sri Lanka', phone: '94' },
    { code: 'LR', label: 'Liberia', phone: '231' },
    { code: 'LS', label: 'Lesotho', phone: '266' },
    { code: 'LT', label: 'Lithuania', phone: '370' },
    { code: 'LU', label: 'Luxembourg', phone: '352' },
    { code: 'LV', label: 'Latvia', phone: '371' },
    { code: 'LY', label: 'Libya', phone: '218' },
    { code: 'MA', label: 'Morocco', phone: '212' },
    { code: 'MC', label: 'Monaco', phone: '377' },
    { code: 'MD', label: 'Moldova, Republic of', phone: '373' },
    { code: 'ME', label: 'Montenegro', phone: '382' },
    { code: 'MF', label: 'Saint Martin (French part)', phone: '590' },
    { code: 'MG', label: 'Madagascar', phone: '261' },
    { code: 'MH', label: 'Marshall Islands', phone: '692' },
    { code: 'MK', label: 'Macedonia, the Former Yugoslav Republic of', phone: '389' },
    { code: 'ML', label: 'Mali', phone: '223' },
    { code: 'MM', label: 'Myanmar', phone: '95' },
    { code: 'MN', label: 'Mongolia', phone: '976' },
    { code: 'MO', label: 'Macao', phone: '853' },
    { code: 'MP', label: 'Northern Mariana Islands', phone: '1-670' },
    { code: 'MQ', label: 'Martinique', phone: '596' },
    { code: 'MR', label: 'Mauritania', phone: '222' },
    { code: 'MS', label: 'Montserrat', phone: '1-664' },
    { code: 'MT', label: 'Malta', phone: '356' },
    { code: 'MU', label: 'Mauritius', phone: '230' },
    { code: 'MV', label: 'Maldives', phone: '960' },
    { code: 'MW', label: 'Malawi', phone: '265' },
    { code: 'MX', label: 'Mexico', phone: '52' },
    { code: 'MY', label: 'Malaysia', phone: '60' },
    { code: 'MZ', label: 'Mozambique', phone: '258' },
    { code: 'NA', label: 'Namibia', phone: '264' },
    { code: 'NC', label: 'New Caledonia', phone: '687' },
    { code: 'NE', label: 'Niger', phone: '227' },
    { code: 'NF', label: 'Norfolk Island', phone: '672' },
    { code: 'NG', label: 'Nigeria', phone: '234' },
    { code: 'NI', label: 'Nicaragua', phone: '505' },
    { code: 'NL', label: 'Netherlands', phone: '31' },
    { code: 'NO', label: 'Norway', phone: '47' },
    { code: 'NP', label: 'Nepal', phone: '977' },
    { code: 'NR', label: 'Nauru', phone: '674' },
    { code: 'NU', label: 'Niue', phone: '683' },
    { code: 'NZ', label: 'New Zealand', phone: '64' },
    { code: 'OM', label: 'Oman', phone: '968' },
    { code: 'PA', label: 'Panama', phone: '507' },
    { code: 'PE', label: 'Peru', phone: '51' },
    { code: 'PF', label: 'French Polynesia', phone: '689' },
    { code: 'PG', label: 'Papua New Guinea', phone: '675' },
    { code: 'PH', label: 'Philippines', phone: '63' },
    { code: 'PK', label: 'Pakistan', phone: '92' },
    { code: 'PL', label: 'Poland', phone: '48' },
    { code: 'PM', label: 'Saint Pierre and Miquelon', phone: '508' },
    { code: 'PN', label: 'Pitcairn', phone: '870' },
    { code: 'PR', label: 'Puerto Rico', phone: '1' },
    { code: 'PS', label: 'Palestine, State of', phone: '970' },
    { code: 'PT', label: 'Portugal', phone: '351' },
    { code: 'PW', label: 'Palau', phone: '680' },
    { code: 'PY', label: 'Paraguay', phone: '595' },
    { code: 'QA', label: 'Qatar', phone: '974' },
    { code: 'RE', label: 'Reunion', phone: '262' },
    { code: 'RO', label: 'Romania', phone: '40' },
    { code: 'RS', label: 'Serbia', phone: '381' },
    { code: 'RU', label: 'Russian Federation', phone: '7' },
    { code: 'RW', label: 'Rwanda', phone: '250' },
    { code: 'SA', label: 'Saudi Arabia', phone: '966' },
    { code: 'SB', label: 'Solomon Islands', phone: '677' },
    { code: 'SC', label: 'Seychelles', phone: '248' },
    { code: 'SD', label: 'Sudan', phone: '249' },
    { code: 'SE', label: 'Sweden', phone: '46' },
    { code: 'SG', label: 'Singapore', phone: '65' },
    { code: 'SH', label: 'Saint Helena', phone: '290' },
    { code: 'SI', label: 'Slovenia', phone: '386' },
    { code: 'SJ', label: 'Svalbard and Jan Mayen', phone: '47' },
    { code: 'SK', label: 'Slovakia', phone: '421' },
    { code: 'SL', label: 'Sierra Leone', phone: '232' },
    { code: 'SM', label: 'San Marino', phone: '378' },
    { code: 'SN', label: 'Senegal', phone: '221' },
    { code: 'SO', label: 'Somalia', phone: '252' },
    { code: 'SR', label: 'Suriname', phone: '597' },
    { code: 'SS', label: 'South Sudan', phone: '211' },
    { code: 'ST', label: 'Sao Tome and Principe', phone: '239' },
    { code: 'SV', label: 'El Salvador', phone: '503' },
    { code: 'SX', label: 'Sint Maarten (Dutch part)', phone: '1-721' },
    { code: 'SY', label: 'Syrian Arab Republic', phone: '963' },
    { code: 'SZ', label: 'Swaziland', phone: '268' },
    { code: 'TC', label: 'Turks and Caicos Islands', phone: '1-649' },
    { code: 'TD', label: 'Chad', phone: '235' },
    { code: 'TF', label: 'French Southern Territories', phone: '262' },
    { code: 'TG', label: 'Togo', phone: '228' },
    { code: 'TH', label: 'Thailand', phone: '66' },
    { code: 'TJ', label: 'Tajikistan', phone: '992' },
    { code: 'TK', label: 'Tokelau', phone: '690' },
    { code: 'TL', label: 'Timor-Leste', phone: '670' },
    { code: 'TM', label: 'Turkmenistan', phone: '993' },
    { code: 'TN', label: 'Tunisia', phone: '216' },
    { code: 'TO', label: 'Tonga', phone: '676' },
    { code: 'TR', label: 'Turkey', phone: '90' },
    { code: 'TT', label: 'Trinidad and Tobago', phone: '1-868' },
    { code: 'TV', label: 'Tuvalu', phone: '688' },
    { code: 'TW', label: 'Taiwan, Province of China', phone: '886' },
    { code: 'TZ', label: 'United Republic of Tanzania', phone: '255' },
    { code: 'UA', label: 'Ukraine', phone: '380' },
    { code: 'UG', label: 'Uganda', phone: '256' },
    { code: 'US', label: 'United States', phone: '1', suggested: true },
    { code: 'UY', label: 'Uruguay', phone: '598' },
    { code: 'UZ', label: 'Uzbekistan', phone: '998' },
    { code: 'VA', label: 'Holy See (Vatican City State)', phone: '379' },
    { code: 'VC', label: 'Saint Vincent and the Grenadines', phone: '1-784' },
    { code: 'VE', label: 'Venezuela', phone: '58' },
    { code: 'VG', label: 'British Virgin Islands', phone: '1-284' },
    { code: 'VI', label: 'US Virgin Islands', phone: '1-340' },
    { code: 'VN', label: 'Vietnam', phone: '84' },
    { code: 'VU', label: 'Vanuatu', phone: '678' },
    { code: 'WF', label: 'Wallis and Futuna', phone: '681' },
    { code: 'WS', label: 'Samoa', phone: '685' },
    { code: 'XK', label: 'Kosovo', phone: '383' },
    { code: 'YE', label: 'Yemen', phone: '967' },
    { code: 'YT', label: 'Mayotte', phone: '262' },
    { code: 'ZA', label: 'South Africa', phone: '27' },
    { code: 'ZM', label: 'Zambia', phone: '260' },
    { code: 'ZW', label: 'Zimbabwe', phone: '263' },
];

export const sharingPermissions = [
    { name: "Can view", value: "VIEWER" },
    { name: "Can edit", value: "EDITOR" },
    { name: "Make owner", value: "OWNER" },
]

export const researchCenters = [
    {
        center: "Africa Rice Center",
        location: "Abidjan - Côte d'Ivoire"
    },
    {
        center: "Center for International Forestry Research",
        location: "Bogor - Indonesia"
    },
    {
        center: "CGIAR System Organization",
        location: "Montpellier, France"
    },
    {
        center: "International Center for Agricultural Research in the Dry Areas",
        location: "Beirut - Lebanon"
    },
    {
        center: "International Crops Research Institute for the Semi-Arid Tropics",
        location: "Patancheru - India"
    },
    {
        center: "International Food Policy Research Institute",
        location: "Washington DC - USA"
    },
    {
        center: "International Institute of Tropical Agriculture",
        location: "Ibadan - Nigeria"
    },
    {
        center: "International Livestock Research Institute",
        location: "Co-hosted by Kenya and Ethiopia"
    },
    {
        center: "International Maize and Wheat Improvement Center",
        location: "Texcoco - Mexico"
    },
    {
        center: "International Potato Center",
        location: "Lima - Peru"
    },
    {
        center: "International Rice Research Institute",
        location: "Los Baños - Philippines"
    },
    {
        center: "International Water Management Institute",
        location: "Colombo - Sri Lanka"
    },
    {
        center: "The Alliance of Bioversity International and the International Center for Tropical Agriculture (CIAT)",
        location: "Cali, Colombia"
    },
    // {
    //     center: "The Alliance of Bioversity International and the International Center for Tropical Agriculture (CIAT)",
    //     location: "Rome, Italy"
    // },
    {
        center: "World Agroforestry (ICRAF)",
        location: "Nairobi - Kenya"
    },
    {
        center: "WorldFish",
        location: "Penang - Malaysia"
    },
]

export const marketSegmentLookups =
{
    center: {
        "Africa-Rice": "Africa-Rice",
        CIMMYT: "CIMMYT",
        CIP: "CIP",
        "CIAT-Bio": "CIAT-Bio",
        CIFOR: "CIFOR",
        ICARDA: "ICARDA",
        ICRISAT: "ICRISAT",
        IITA: "IITA",
        ILRI: "ILRI",
        IRRI: "IRRI",
        IWMI: "IWMI",
        WF: "WF"
    },
    crop: {
        Apple: "Apple",
        Banana: "Banana",
        Beans: "Beans",
        Blackberry: "Blackberry",
        Blueberry: "Blueberry",
        Canola: "Canola",
        Cassava: "Cassava",
        Chickpea: "Chickpea",
        "Egg-Plant": "Egg-Plant",
        Forages: "Forages",
        Groundnut: "Groundnut",
        Kiwi: "Kiwi",
        Kumquat: "Kumquat",
        Lettuce: "Lettuce",
        Maize: "Maize",
        Nectarine: "Nectarine",
        Orange: "Orange",
        Peach: "Peach",
        Plantain: "Plantain",
        Plum: "Plum",
        Potato: "Potato",
        Raspberry: "Raspberry",
        Rice: "Rice",
        Sorghum: "Sorghum",
        Strawberry: "Strawberry",
        "Sweet-Potato": "Sweet-Potato",
        Tomato: "Tomato",
        Wheat: "Wheat"
    },
    region: {
        "-": "-",
        East: "East",
        North: "North",
        South: "South",
        West: "West",
        "East-South": "East-South",
        "East-West": "East-West",
        "East-North": "East-North",
        "North-West": "North-West",
        "North-East": "North-East",
        "North-South": "North-South",
        "West-South": "West-South",
        "West-East": "West-East",
        "West-North": "West-North",
        "South-East": "South-East",
        "South-West": "South-West",
        "South-North": "South-North",
        Latin: "Latin"
    },
    subRegion: {
        "-": "-",
        East: "East",
        North: "North",
        South: "South",
        West: "West"
    },
    continent: {
        "-": "-",
        Africa: "Africa",
        America: "America",
        Caribbean: "Caribbean",
        Asia: "Asia",
        Europe: "Europe",
        Australia: "Australia",
        Antarctica: "Antarctica"
    },
    cropType: {
        Food: "Food",
        Forage: "Forage",
        Industrial: "Industrial"
    },
    color: {
        Black: "Black",
        White: "White",
        Red: "Red",
        Yellow: "Yellow",
        Green: "Green",
        Orange: "Orange",
        Blue: "Blue",
        Purple: "Purple",
        Pink: "Pink",
        Tan: "Tan",
        None: "None"
    },
    where: {
        "-": "-",
        "Sea-level (<500)": "Sea-level (<500)",
        "Low (500-2000)": "Low (500-2000)",
        "Mid (2000-3000)": "Mid (2000-3000)",
        "High (3000-5500)": "High (3000-5500)",
        "Extreme (>5500)": "Extreme (>5500)"
    },
    how: {
        "Deep-water": "Deep-water",
        Waterlogged: "Waterlogged",
        "High-rainfall(=wet)": "High-rainfall(=wet)",
        "Medium-rainfall": "Medium-rainfall",
        "Drought-prone": "Drought-prone",
        Dry: "Dry",
        "Heat-stressed": "Heat-stressed",
        "Normal-temperature": "Normal-temperature",
        "Cold-stressed": "Cold-stressed",
        Hand: "hand",
        "Machine-harvest": "Machine-harvest",
        Rainfed: "Rainfed",
        "Partially-irrigated": "Partially-irrigated",
        Irrigated: "Irrigated",
        "Direct-seeded": "Direct-seeded",
        Transplanted: "Transplanted",
        "Main-season": "Main-season",
        "Dry-season": "Dry-season",
        "Main-crop": "Main-crop",
        "Second-crop": "Second-crop",
        Kharif: "Kharif",
        Rabi: "Rabi",
        Winter: "Winter",
        Short: "Short",
        Medium: "Medium",
        Long: "Long"
    },
    maturity: {
        "-": "-",
        Early: "Early",
        "Early-intermediate": "Early-intermediate",
        Intermediate: "Intermediate",
        "Intermediate-late": "Intermediate-late",
        Late: "Late",
    },
}

export const productProfileTraitLookups = {
    traitType: {
        Quality: "Quality",
        Agronomic: "Agronomic",
        Morphological: "Morphological",
        Phenological: "Phenological",
        AbioticStress: "Abiotic Stress",
        BioticStress: "Biotic Stress"
    },
    unit1: {
        Milligrams: "Milligrams",
        Grams: "Grams",
        Kilograms: "Kilograms",
        Tons: "Tons",
        Centimeters: "Centimeters",
        Meters: "Meters",
        "Score; best=min": "Score; best=min",
        "Score; best=max": "Score; best=max",
        "%": "%",
        "% over check": "% over check",
        Days: "Days",
        Other: "Other",
        Brix: "Brix",
        Degrees: "Degrees",
        mm: "mm",
        "mm²": "mm²",
        cm: "cm",
        "cm²": "cm²",
        m: "m",
        "m²": "m²",
        Acre: "Acre",
        Hectare: "Hectare",
        "Seed, flower, inflorescence": "Seed, flower, inflorescence",
        "Plant, ear, bunch, branch": "Plant, ear, bunch, branch",
        "Spike, cob": "Spike, cob",
        "Tuber, fruit": "Tuber, fruit",
        "-": "-"
    },
    unit2: {
        per: "per",
        "-": "-",
    },
    unit3: {
        Milligrams: "Milligrams",
        Grams: "Grams",
        Kilograms: "Kilograms",
        Tons: "Tons",
        Centimeters: "Centimeters",
        Meters: "Meters",
        "Score; best=min": "Score; best=min",
        "Score; best=max": "Score; best=max",
        "%": "%",
        "% over check": "% over check",
        Days: "Days",
        Other: "Other",
        Brix: "Brix",
        Degrees: "Degrees",
        mm: "mm",
        "mm²": "mm²",
        cm: "cm",
        "cm²": "cm²",
        m: "m",
        "m²": "m²",
        Acre: "Acre",
        Hectare: "Hectare",
        "Seed, flower, inflorescence": "Seed, flower, inflorescence",
        "Plant, ear, bunch, branch": "Plant, ear, bunch, branch",
        "Spike, cob": "Spike, cob",
        "Tuber, fruit": "Tuber, fruit",
        "-": "-"
    }
}
export const breedingSchemeLookups = {
    recyclingTimeUnit: {
        Year: "Year",
        Month: "Month",
        "": "-"
    },
    product: {
        Hybrid: "Hybrid",
        OPV: "OPV",
        Line: "Line",
        Variety: "Variety",
        Synthetic: "Synthetic",
        "": "-"
    },
    multiplicationMethod: {
        "Single-seed-descent": "Single-seed-descent",
        Pedigree: "Pedigree",
        RGA: "RGA",
        SAH: "SAH",
        Cloning: "Cloning",
        "": "-"
    },
    schemeType: {
        "trait discovery": "trait discovery",
        "population improvement": "population improvement",
        "product development": "product development",
        introgresion: "introgresion",
        "": "-"
    },
    maturity: {
        "extra-early": "extra-early",
        early: "early",
        "early/medium": "early/medium",
        medium: "medium",
        "medium/late": "medium/late",
        late: "late",
        "": "-"
    },
    treatment: {
        rainfed: "rainfed",
        irrigated: "irrigated",
        drought: "drought",
        optimal: "optimal",
        "low-input": "low-input",
        stress: "stress",
        "direct-seeded": "direct-seeded",
        transplanted: "transplanted",
        "": "-"
    }
}

export const breedingSchemeSettingLookups = {
    level1: ["-", "HSfamilies", "testers", "donors"],
    level2: ["-", "FSfamilies", "F1.or.cross", "crosses", "F1"],
    level3: ["-", "Fx(x>1)ind", "lines"],
    level4: ["-", "sublines", "submaterials"],
}

const gridColorPalette = {
    selectableColumns: {
        background: "#019688",
        color: "#fff"
    },
    numberColumns: {
        background: "#fff",
        color: "rgba(0, 0, 0, 0.87)"
    },
    calculatedFields: {
        background: "#e3e3e3",
        color: "#000"
    },
    additionalFields: "#FFF2CC"
}

export const BreedingStageColumnProperties = {
    actions: {
        columnName: "Actions",
        verticalOrientation: true,
        styles: {
            width: "auto",
            verticalAlign: "middle",
            color: "#0071C0",
            fontWeight: "bold",
        },
        helpText: "Breeding Stage Actions"
    },
    stageNo: {
        columnName: "No.",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bold",
            position: "sticky",
            minWidth: "57px",
            padding: 0,
            left: 70,
            zIndex: 10
        },
        helpText: "Breeding Stage No.",
        editable: "never"
    },
    EvaluationDatum: {
        columnName: "POPULATION STRUCTURE",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        //helpText: "This is column help text in popover."
    },
    EvaluationStrategy: {
        columnName: "EVALUATION STRATEGY",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        //helpText: "This is column help text in popover."
    },
    Molecular: {
        columnName: "MOLECULAR",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        //helpText: "This is column help text in popover."
    },
    SelectionStrategy: {
        columnName: "PHENOTYPING AND SELECTION STRATEGY (estimateType, #locations, method)",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        //helpText: "This is column help text in popover."
    },
    RecyclingStrategy: {
        columnName: "RECYCLING & ADVANCEMENT STRATEGY",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        //helpText: "This is column help text in popover."
    },
    MultiplicationStrategy: {
        columnName: "MULTIPLICATION",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        //helpText: "This is column help text in popover."
    },
    CrossingStrategy: {
        columnName: "CROSSING",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0",
            fontWeight: "bolder",
        },
        //helpText: "This is column help text in popover."
    },
    stageName: {
        columnName: "Stage Name",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            position: "sticky",
            minWidth: "58px",
            left: 128,
            zIndex: 10
        },
        lookup: {
            "-": "-",
            STG0: "STG0",
            STG1: "STG1",
            STG2: "STG2",
            STG3: "STG3",
            STG4: "STG4",
            STG5: "STG5",
            STG6: "STG6",
            STG7: "STG7",
            STG8: "STG8",
            STG9: "STG9",
            STG10: "STG10"
        },
        modelName: "EvaluationData",
        helpText: "The generation of material planted this season."
    },
    commonName: {
        columnName: "Common Name",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.numberColumns.background,
            color: gridColorPalette.numberColumns.color,
            position: "sticky",
            minWidth: "51px",
            left: 203,
            zIndex: 10,
        },
        lookup: {},
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell
        maxLength: 4, //Max length of chars
        modelName: "EvaluationData",
        helpText: "The name that the breeding teams normally use for each stage of evaluation"
    },
    generationMaterialPlanted: {
        columnName: "Generation Material Planted",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color,
            position: "sticky",
            left: 270,
            minWidth: "65px",
            zIndex: 10
        },
        lookup: {
            "-": "-",
            PAR: "PAR",
            F1: "F1",
            F2: "F2",
            F3: "F3",
            F4: "F4",
            F5: "F5",
            F6: "F6",
            F7: "F7",
            F8: "F8",
            F9: "F9",
            F10: "F10",
            BC1: "BC1",
            BC2: "BC2",
            BC3: "BC3",
            BC4: "BC4",
            BC5: "BC5",
            BC6: "BC6",
            TC: "TC",
            SCA: "SCA",
            S0: "S0",
            S1: "S1",
            S2: "S2",
            S3: "S3",
            S4: "S4",
            S5: "S5",
            S6: "S6",
            S7: "S7",
            Hap: "Hap",
            DH0: "DH0",
            DH1: "DH1",
            DH2: "DH2",
            DH3: "DH3",
            HSfamily: "HSfamily",
            OP: "OP",
            composite: "composite",
            synthetic: "synthetic",
            CP: "CP",
            C1: "C1",
            C2: "C2",
            C3: "C3",
            C4: "C4",
            C5: "C5",
            "1X": "1X",
            "2X": "2X",
            "3X": "3X",
            "4X": "4X",
            "?": "?"
        },
        modelName: "EvaluationData",
        helpText: "The generation of material planted this season. Feel free to call it differently."
    },
    derivedFrom: {
        columnName: "Derived From",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
            position: "sticky",
            left: 351,
            zIndex: 10
        },
        lookup: {
            "-": "-",
            PAR: "PAR",
            F1: "F1",
            F2: "F2",
            F3: "F3",
            F4: "F4",
            F5: "F5",
            F6: "F6",
            F7: "F7",
            F8: "F8",
            F9: "F9",
            F10: "F10",
            BC1: "BC1",
            BC2: "BC2",
            BC3: "BC3",
            BC4: "BC4",
            BC5: "BC5",
            BC6: "BC6",
            TC: "TC",
            SCA: "SCA",
            S0: "S0",
            S1: "S1",
            S2: "S2",
            S3: "S3",
            S4: "S4",
            S5: "S5",
            S6: "S6",
            S7: "S7",
            Hap: "Hap",
            DH0: "DH0",
            DH1: "DH1",
            DH2: "DH2",
            DH3: "DH3",
            HSfamily: "HSfamily",
            OP: "OP",
            composite: "composite",
            synthetic: "synthetic",
            CP: "CP",
            C1: "C1",
            C2: "C2",
            C3: "C3",
            C4: "C4",
            C5: "C5",
            "1X": "1X",
            "2X": "2X",
            "3X": "3X",
            "4X": "4X",
            "?": "?"
        },
        modelName: "EvaluationData",
        helpText: "Generation of the material used to derive the material planted."
    },
    year: {
        columnName: "Year",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationData",
        helpText: "Year when this material is planted."
    },
    season: {
        columnName: "Season",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background,
            color: gridColorPalette.selectableColumns.color,
        },
        lookup: {
            "-": "-",
            main: "main",
            off: "off",
            " 1": 1,
            " 2": 2,
            " 3": 3,
            " 4": 4,
            " 5": 5,
            " 6": 6,
            " 7": 7,
            " 8": 8,
            " 9": 9,
        },
        modelName: "EvaluationData",
        helpText: "Season when this material is planted."
    },
    noLevel1: {
        verticalOrientation: true,
        backgroundColor: "#FFF",
        modelName: "EvaluationData",
        helpText: "Average number of unique half sib families or testers (if applies). Genetic unit can be modified in cell O1."
    },
    noLevel2: {
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationData",
        helpText: "Average number of unique full sib families, crosses or F1s (if applies). Genetic unit can be modified in cell O2."
    },
    noLevel3: {
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationData",
        helpText: "Average number of unique F2s or lines per family/cross/F1 (if applies). Genetic unit can be modified in cell O3.v"
    },
    noLevel4: {
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationData",
        helpText: "Average number of unique sublines derived from each F2 if pedigree breeding is done  (if applies). Genetic unit can be modified in cell O4."
    },
    totalGenotypesPlanted: {
        columnName: "Total Genotypes Planted",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        modelName: "EvaluationData",
        helpText: "Number of total unique genotypes. Don't modify, is a calculated guiding number.",
        editable: "never"
    },
    plotSize: {
        columnName: "Plot Size",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0"
        },
        helpText: ""
    },
    experimentalDesignWithinEnvironment: {
        columnName: "Experimental Design (within environment)",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0"
        },
        helpText: ""
    },
    experimentalDesignAcrossEnvironments: {
        columnName: "Exp. Design (across)",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0"
        },
        helpText: ""
    },
    plantPortionToBePlanted: {
        columnName: "Plant Portion To Be Planted",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            seed: "seed",
            head: "head",
            ear: "ear",
            panicle: "panicle",
            fruit: "fruit",
            pod: "pod",
            vine: "vine",
            tuber: "tuber",
            cutting: "cutting",
            runner: "runner",
            sucker: "sucker",
            plant: "plant",
            family: "family",
            "?": "?"
        },
        modelName: "EvaluationStrategy",
        helpText: "Portion of the plant taken last season and to be planted this season."
    },
    noOfPlantPortions: {
        columnName: "Number Of Plant Portions",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationStrategy",
        helpText: "Portion of the plant taken last season and to be planted this season."
    },
    plantPortionPlantedIn: {
        columnName: "Plant Portion Planted In",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            fieldSpot: "fieldSpot",
            pot: "pot",
            tube: "tube",
            row: "row",
            plot: "plot",
            nursery: "nursery",
            petri: "petri",
            "?": "?",
        },
        modelName: "EvaluationStrategy",
        helpText: "How the plant portion from last season will be planted this season."
    },
    experimentalDesign: {
        columnName: "Experimental Design",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            none: "none",
            crd: "crd",
            rcbd: "rcbd",
            alpha: "alpha",
            augm: "augm",
            "p.rep": "p.rep",
            family: "family",
            "?": "?"
        },
        modelName: "EvaluationStrategy",
        helpText: "Experimental design (randomization used in the evaluation)."
    },
    noSpatialChecks: {
        columnName: "# Spatial Checks",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationStrategy",
        helpText: ""
    },
    noTemporalChecks: {
        columnName: "# Temporal Checks",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationStrategy",
        helpText: ""
    },
    connectivityMethod: {
        columnName: "Connectivity Method",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            GRM: "GRM",
            overlappedChecks: "overlappedChecks",
            overlappedEntries: "overlappedEntries",
            none: "none",
        },
        modelName: "EvaluationStrategy",
        helpText: ""
    },
    // totalNoOfLocations: {
    //     columnName: "Total # of Locations",
    //     verticalOrientation: true,
    //     styles: { backgroundColor: "#FFF" },
    //     modelName: "EvaluationStrategy",
    //     helpText: "Maximum number of locations where the individuals are grown."
    // },
    // noOfReplicationsPerLoc: {
    //     columnName: "# of Replications Per Loc",
    //     verticalOrientation: true,
    //     styles: { backgroundColor: "#FFF" },
    //     modelName: "EvaluationStrategy",
    //     helpText: "Average number of replicates per environment grown for each individual."
    // },
    plotWidth: {
        columnName: "Plot Width",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationStrategy",
        helpText: "Width of plot in the unit specified (i.e. meters)."
    },
    plotWidthUnits: {
        columnName: "Plot Width Units",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            plant: "plant",
            row: "row",
            ft: "ft",
            m: "m",
            cm: "cm",
            pot: "pot",
            dish: "dish",
            "?": "?"
        },
        modelName: "EvaluationStrategy",
        helpText: "Unit for the width."
    },
    plotLength: {
        columnName: "Plot Length",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationStrategy",
        helpText: "Length of plot in the unit specified (i.e. meters)."
    },
    plotLengthUnits: {
        columnName: "Plot Length Units",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            plant: "plant",
            row: "row",
            ft: "ft",
            m: "m",
            cm: "cm",
            pot: "pot",
            dish: "dish",
            "?": "?"
        },
        modelName: "EvaluationStrategy",
        helpText: "Units for the length."
    },
    fromPlantPortionOrigin: {
        columnName: "From (Plant Portion Origin)",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color
        },
        modelName: "EvaluationStrategy",
        helpText: ""
    },
    sparseTesting: {
        columnName: "Sparse Testing %(0-100)",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "EvaluationStrategy",
        helpText: "If purpose is >entries, 0.25 means each entry doesn't show in 25% of locations (fixed) to accomodate more entries (variable). If purpose is >locations 0.25 means each entry (fixed) doesn't show in 25% of locations to go to more locations(variable)."
    },
    evaluationSeasonCost: {
        columnName: "Evaluation Season Cost",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        prepend: "$",
        modelName: "RecyclingStrategy",
        helpText: "Approximate cost for evaluation at this stage. Don't modify, is a calculated guiding number.",
        editable: "never"
    },
    molecularTechnologyAvailable: {
        columnName: "Molecular Technology Available",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            none: "none",
            RFLP: "RFLP",
            AFLP: "AFLP",
            STR: "STR",
            SSR: "SSR",
            KASP: "KASP",
            "GBS-SNP": "GBS-SNP",
            SNParray: "SNParray",
            flowCyt: "flowCyt",
            "?": "?",
        },
        modelName: "Molecular",
        helpText: "Molecular technology used at this stage to enhance one of the breeders' equation."
    },
    noOfMarkers: {
        columnName: "# Of Markers (if Applicable)",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "Molecular",
        helpText: "When genotyping, it specifies how many markers are run in each individual."
    },
    molecularTechPurpose: {
        columnName: "Molecular Tech Purpose",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            none: "none",
            paternity: "paternity",
            identity: "identity",
            MABC: "MABC",
            MAS: "MAS",
            diversity: "diversity",
            fixation: "fixation",
            GS: "GS",
            QTLprofile: "QTLprofile",
            ploidy: "ploidy",
            heterosis: "heterosis",
            "?": "?"
        },
        modelName: "Molecular",
        helpText: "What's the purpose of using this molecular technology?"
    },
    usedInGSas: {
        columnName: "Used In GSas",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            TP: "TP",
            PP: "PP",
            "TP&PP": "TP&PP"
        },
        modelName: "Molecular",
        helpText: "How are the phenotypes & genotypes generated at this stage used in the GS approach? TP: as training population; PP: as predicted population; TP&PP: both"
    },
    genotypingCost: {
        columnName: "Genotyping Cost",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        prepend: "$",
        modelName: "Molecular",
        helpText: "Approximate cost for genotyping. Don't modify, is a calculated guiding number.",
        editable: "never"
    },
    QualityTraits: {
        columnName: "Quality",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddQualityTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    AgronomicTraits: {
        columnName: "Agronomic",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddAgronomicTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    MorphologicalTraits: {
        columnName: "Morphological",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddMorphologicalTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    PhenologicalTraits: {
        columnName: "Phenological",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddPhenologicalTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    AbioticStressTraits: {
        columnName: "Abiotic Stress",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddAbioticStressTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    BioticStressTraits: {
        columnName: "Biotic Stress",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddBioticStressTrait"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    recyclingGeneration: {
        columnName: "Recycling Generation",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color
        },
        modelName: "RecyclingStrategy",
        //helpText: "The generation of material planted this season. Feel free to call it differently."
    },
    recyclingUnit: {
        columnName: "Recycling Unit",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            HSfamilies: "HSfamilies",
            "F1.or.cross": "F1.or.cross",
            "Fx(x≥2)ind": "Fx(x≥2)ind",
            sublines: "sublines"
        },
        modelName: "RecyclingStrategy",
        helpText: "The unit to be recycled (i.e. family, line, etc.)."
    },
    noOfUnitsRecycled: {
        columnName: "# of Units Recycled",
        verticalOrientation: true,
        styles: { backgroundColor: "#FFF" },
        modelName: "RecyclingStrategy",
        helpText: "Number of selection units (i.e. families, lines, etc.) to be recycled and become parents for the new crossing block."
    },
    kpi: {
        columnName: "Key Performance Indicator (KPI)",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            "genetic gain": "genetic gain",
            lines: "lines",
            hybrids: "hybrids",
            haplotypes: "haplotypes",
            QTLs: "QTLs",
            "augmented line": "augmented line",
            variety: "variety",
            OPV: "OPV"
        },
        modelName: "RecyclingStrategy",
        helpText: "Type of product handed by the breeder to the leadership."
    },
    selectionSeasonCost: {
        columnName: "Selection Season Cost",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.calculatedFields.background, color: gridColorPalette.calculatedFields.color },
        prepend: "$",
        modelName: "RecyclingStrategy",
        helpText: "Approximate selection cost. Don't modify, is a guiding number.",
        editable: "never"
    },
    multiplicationUnit: {
        columnName: "Multiplication Unit",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        modelName: "MultiplicationStrategy",
        helpText: "Genetic unit to be used in the multiplication step"
    },
    multiplicationMethod: {
        columnName: "Multiplication Method",
        verticalOrientation: true,
        styles: {
            backgroundColor: gridColorPalette.numberColumns.background,
            color: gridColorPalette.numberColumns.color,
            minWidth: "58px",
        },
        lookup: {
            "-": "-",
            SSD: "SSD",
            RGA: "RGA",
            Cloning: "Cloning",
            Hap: "Hap",
            DH: "DH",
            self: "self"
        },
        freeSolo: true, //Enables a freeSolo autocomplete where user can type free text aswell
        modelName: "MultiplicationStrategy",
        helpText: "Type of multiplication method."
    },
    multiplicationRate: {
        columnName: "Multiplication Rate(Ratio)",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#F2F1F2",
            color: "#0071C0"
        },
        helpText: ""
    },
    multiplicationRateValue: {
        columnName: "Value",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.numberColumns.background, color: gridColorPalette.numberColumns.color },
        modelName: "MultiplicationStrategy",
        helpText: " "
    },
    multiplicationRateUnit: {
        columnName: "Unit",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            "ton(s)": "ton(s)",
            "kg(s)": "kg(s)",
            "gram(s)": "gram(s)",
            seed: "seed",
            plant: "plant",
            plot: "plot",
            head: "head",
            ear: "ear",
            panicle: "panicle",
            fruit: "fruit",
            pod: "pod",
            vine: "vine",
            tuber: "tuber",
            cutting: "cutting",
            runner: "runner",
            sucker: "sucker"
        },
        modelName: "MultiplicationStrategy",
        helpText: " "
    },
    multiplicationRatePer: {
        columnName: "per",
        verticalOrientation: false,
        styles: {
            backgroundColor: "#008394",
            color: "#FFF"
        },
        editable: "never",
        helpText: ""
    },
    multiplicationPortionMultiplied: {
        columnName: "Portion Multiplied",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            "ton(s)": "ton(s)",
            "kg(s)": "kg(s)",
            "gram(s)": "gram(s)",
            seed: "seed",
            plant: "plant",
            plot: "plot",
            head: "head",
            ear: "ear",
            panicle: "panicle",
            fruit: "fruit",
            pod: "pod",
            vine: "vine",
            tuber: "tuber",
            cutting: "cutting",
            runner: "runner",
            sucker: "sucker"
        },
        modelName: "MultiplicationStrategy",
        helpText: " "
    },
    matingDesign: {
        columnName: "Mating Design",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            factorial: "factorial",
            diallel: "diallel",
            "incomp. diallel": "incomp. diallel",
            "sparse testcross": "sparse testcross",
            testcross: "testcross",
            random: "random",
            custom: "custom",
            "optim. contribution": "optim. contribution",
            "max. avoidance": "max. avoidance",
            "optimal cross selection": "optimal cross selection"
        },
        modelName: "CrossingStrategy",
        helpText: ""
    },
    CrossingPools: {
        columnName: "Pools",
        verticalOrientation: false,
        action: {
            type: "Add",
            method: "handleAddCrossingPool"
        },
        styles: { backgroundColor: "#F2F1F2" },
        //helpText: "This is column help text in popover."
    },
    Pool: {
        verticalOrientation: false,
        action: {
            type: "Remove",
            method: "handleConfirmRemovePools"
        },
        styles: { backgroundColor: "#fff", borderLeft: "2px solid #aaa" },
        helpText: "1st Column: #Parents ------------------------------- 2nd Column: #Crosses ------------------------------ 3rd Column: #progeny/cross ---------------------"
    },
    noOfParents: {
        columnName: "#Parents",
        verticalOrientation: false,
        styles: { backgroundColor: gridColorPalette.numberColumns.background, color: gridColorPalette.numberColumns.color },
        modelName: "CrossingPool",
        helpText: " "
    },
    noOfCrosses: {
        columnName: "#Crosses",
        verticalOrientation: false,
        styles: { backgroundColor: gridColorPalette.numberColumns.background, color: gridColorPalette.numberColumns.color },
        modelName: "CrossingPool",
        helpText: " "
    },
    noOfProgenyPerCross: {
        columnName: "#Progeny/Cross",
        verticalOrientation: false,
        styles: { backgroundColor: gridColorPalette.numberColumns.background, color: gridColorPalette.numberColumns.color },
        modelName: "CrossingPool",
        helpText: " "
    },
    selectionUnit: {
        columnName: "Selection Unit",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            "l:": "l:line",
            "f:": "f:family",
            "n:": "n:clone",
            "s:": "s:single individual",
            "c:": "c:cross"
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    selectionValue: {
        columnName: "Selection Value",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            BV: "BV",
            GEBV: "GEBV",
            GCA: "GCA",
            GEGCA: "GEGCA",
            OCS: "OCS",
            GPCP: "GPCP"
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    breedingSurrogate: {
        columnName: "Breeding Surrogate",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.selectableColumns.background, color: gridColorPalette.selectableColumns.color },
        lookup: {
            "-": "-",
            "h:": "h:single-record",
            "a:": "a:average",
            "b:": "b:BLUE",
            "B:": "B:BLUP",
            "p:": "p:pBLUP",
            "g:": "g:gBLUP",
            "m:": "m:marker"
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    noOfEnvironments: {
        columnName: "No of Environments",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.numberColumns },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },
    selectionMethod: {
        columnName: "Selection Method",
        verticalOrientation: true,
        styles: { backgroundColor: gridColorPalette.additionalFields },
        lookup: {
            "-": "-",
            "m:": "m:mentalIndex",
            "p:": "p:participatory",
            "t:": "t:tandem",
            "c:": "c:cullingIndep",
            "i:": "i:indexSelection",
        },
        modelName: "Trait",
        //helpText: "This is column help text in popover."
    },

    //Assumes a column as trait by default since its the only column with arrays
    defaultColumn: {
        verticalOrientation: true,
        styles: { backgroundColor: "#fff", borderLeft: "2px solid #aaa" },
        action: {
            type: "Remove",
            method: "handleConfirmRemoveTraits"
        },
        isEditable: true,
        helpText: "1st column: Selection Unit; 2nd column: Selection Value; 3rd column: Surrogate of breeding value; 4th column: #of environments; 5th column: Selection method",
        //This model name is only used for rendering traits columns. No data transaction is being processed through this 
        modelName: "Traits"
    }
}
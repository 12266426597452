import { useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import {
    getBreedingSchemes, createBreedingScheme, updateBreedingScheme, orderBreedingScheme,
    cancelDuplicateBreedingScheme, confirmDuplicateBreedingScheme, duplicateBreedingScheme,
    cancelRemoveBreedingScheme, confirmRemoveBreedingScheme, removeBreedingScheme
} from '../../../actions/breedingScheme'
import { showNotification } from '../../../actions/notification'
import { updateRenderedBreedingSchemes } from '../../../actions/breedingPipelines'
import { breedingSchemeLookups } from '../../../constants'
import Box from '@material-ui/core/Box'
import TableCell from '@material-ui/core/TableCell';
import MaterialTable from 'material-table'
import { MTableBodyRow, MTableCell, MTableActions, MTableEditField } from 'material-table'
import TableIcons from '../../molecules/TableIcons'
import TableRowOrder from '../../molecules/TableRowOrder';
import ViewCompactIcon from '@material-ui/icons/ViewCompact'
import LibraryAddIcon from '@material-ui/icons/LibraryAdd';
import DeleteSweepIcon from '@material-ui/icons/DeleteSweep'
import UserDialog from '../../molecules/UserDialog'
import ShareContent from '../ShareContent';
import Divider from '@material-ui/core/Divider';
import makeStyles from '@material-ui/core/styles/makeStyles'
import TableCellTooltip from '../../molecules/TableCellTooltip'
import Chip from '@material-ui/core/Chip'
import Input from '@material-ui/core/Input'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import Checkbox from '@material-ui/core/Checkbox'
import Typography from '@material-ui/core/Typography'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'

const useStyles = makeStyles((theme) => ({
    table: {
        '& table': {
            margin: "1px 0px"
        },
        '& h6': {
            fontSize: "0.85rem"
        },
        '& th': {
            fontSize: "13px !important",
            border: "1px solid #b3b3b3 !important",
            lineHeight: "1rem"
        },
        '& tbody td': {
            padding: "2px 24px 2px 16px",
            fontSize: "13px !important",
            border: "1px solid #b9b9b9 !important",
            background: "#fff"
        },
        '& tbody > tr': {
            height: "auto !important"
        },
        '& tbody > tr:first-child': {
            height: "38px !important"
        },
        '& tbody > tr > td:first-child': {
            width: "30px !important"
        },
        '& td input': {
            width: "100%"
        },
        '& td > div': {
            width: "auto !important"
        },
    },
    multiselectInput: {
        '& > div': {
            width: "250px",
            display: "flex",
            flexWrap: "wrap"
        },
        '& .Mui-disabled > div': {
            backgroundColor: "#7e7e7e"
        }
    }
}))

const MarketSegmentChip = ({ name, code }) => {
    return (
        <TableCellTooltip title={name}>
            <Chip style={{ margin: "0px 5px 5px 0px" }} size="small" label={code} color="secondary" />
        </TableCellTooltip>
    )
}

const BreedingSchemeMarketSegmentsInput = (cellProps) => {
    const [marketSegmentOptions, setMarketSegmentOptions] = useState([])
    const [selectedMarketSegments, setSelectedMarketSegments] = useState([]);
    const [isDisabled, setIsDisabled] = useState(true)
    useEffect(() => {
        if (cellProps.rowData.id) {
            setSelectedMarketSegments(cellProps.value)
            setMarketSegmentOptions(cellProps.value.concat(cellProps.columnDef.availableMarketSegments))
            setIsDisabled(cellProps.rowData.Shares.length > 0 ? cellProps.rowData.Shares[0].permission === 'VIEWER' ? true : false : false)
        }
        else {
            setSelectedMarketSegments(cellProps.value || [])
            setMarketSegmentOptions(cellProps.columnDef.availableMarketSegments)
        }
    }, [cellProps])

    const handleChange = (event) => {
        setSelectedMarketSegments(event.target.value);
    };

    const handleSubmit = () => {
        if (cellProps.rowData.id)
            cellProps.handleSubmit(cellProps.rowData, selectedMarketSegments)
        else
            cellProps.onChange(selectedMarketSegments)
    }
    const classes = useStyles()
    return (
        <FormControl>
            <Select
                id="marketSegments"
                multiple
                disabled={isDisabled}
                className={classes.multiselectInput}
                value={selectedMarketSegments}
                onChange={handleChange}
                onClose={handleSubmit}
                input={<Input />}
                renderValue={(selected) => selected.map((marketSegment, idx) => <MarketSegmentChip key={idx} name={marketSegment.name} code={marketSegment.code} />)}
            >
                {marketSegmentOptions.map((marketSegment, idx) => (
                    <MenuItem key={idx} value={marketSegment}>
                        <Checkbox checked={selectedMarketSegments.map(ms => ms.id).indexOf(marketSegment.id) > -1} />
                        <Typography style={{ fontSize: "0.9rem" }}>{`${marketSegment.name} (${marketSegment.code})`}</Typography>
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    )
}

const AutocompleteInput = (cellProps) => {
    const autocompleteOptions = Object.keys(cellProps.columnDef.lookup)
    const [selectedValue] = useState(cellProps.value)
    const classes = useStyles()
    return (
        <Autocomplete
            id={cellProps.columnDef.field}
            freeSolo
            fullWidth
            style={{ width: "150px", margin: "0px" }}
            options={autocompleteOptions.map((option) => option)}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option;
            }}
            defaultValue={selectedValue}
            onInputChange={(e, newValue) => {
                cellProps.onChange(newValue)
            }}
            renderInput={(params) => (
                <TextField {...params}
                    margin="normal"
                    className={classes.soloAutocomplete}
                    variant="standard"
                    fullWidth
                />
            )}
            renderOption={(option) => (
                <Box flex={1}>
                    <Box flexGrow={1}><Typography>{option}</Typography></Box>
                </Box>
            )}
        />
    )
}

const BreedingScheme = (props) => {
    const breedingSchemeState = useSelector((state) => state.breedingScheme)
    const dispatch = useDispatch()
    const history = useHistory()
    const [breedingSchemes, setBreedingSchemes] = useState({
        data: [],
        availableMarketSegments: [],
        breedingPipelineId: "",
        isLoading: false,
        duplicateSchemeForm: {
            breedingSchemeId: "",
            breedingPipelineId: "",
            open: false
        },
        removeSchemeForm: {
            breedingSchemeId: "",
            breedingPipelineId: "",
            open: false
        }
    })

    useEffect(() => {
        dispatch(getBreedingSchemes(props.breedingPipelineId, { sharedOnly: props.sharedOnly }))
        //logic to check which pipelines are expanded
        dispatch(updateRenderedBreedingSchemes(props.breedingPipelineId, true))
        return () => {
            //pathname is being checked to verify if user has navigated to a different page. If so,
            //the rendered breeding schemes will not be removed from store
            if (history.location.pathname === "/" || history.location.pathname === "/shared")
                dispatch(updateRenderedBreedingSchemes(props.breedingPipelineId, false))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.breedingPipelineId, dispatch])

    useEffect(() => {
        if (breedingSchemeState.breedingPipelineId === props.breedingPipelineId) {
            setBreedingSchemes({ ...breedingSchemeState })
        }
    }, [breedingSchemeState, props.breedingPipelineId])

    const handleSortBreedingScheme = (newOrderNo, previousOrderNo, BreedingPipelineId) => {
        setBreedingSchemes({ ...breedingSchemes, isLoading: true })
        dispatch(orderBreedingScheme({ newOrderNo, previousOrderNo, BreedingPipelineId }, { sharedOnly: props.sharedOnly }))
    }

    const columns = [
        {
            title: 'No', field: 'schemeNo', align: "center",
            cellStyle: { fontWeight: "bold", width: "3%", padding: "2px 0" }, editable: "never",
            render: rowData => <TableRowOrder
                id={rowData.BreedingPipelineId}
                order={rowData.schemeNo}
                total={breedingSchemes.data.length}
                sort={handleSortBreedingScheme}
                isDisabled={props.sharedOnly ?
                    (rowData.Shares[0] && (rowData.Shares[0].permission === 'OWNER' &&
                        (rowData.Shares[0].groupId === `BreedingScheme-${breedingSchemes.breedingPipelineId}` || rowData.Shares[0].groupId === `BreedingPipeline-${breedingSchemes.breedingPipelineId}`)
                    ) ? false : true) : false}>
                {rowData.schemeNo}
            </TableRowOrder>
        },
        { title: 'Name', field: 'name', align: "center", cellStyle: { maxWidth: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }, editable: "never" },
        {
            title: 'Recycling Time',
            field: 'recyclingTime',            
            align: "center"
        },
        { title: 'Recycling Time Unit', field: 'recyclingTimeUnit', align: "center", lookup: breedingSchemeLookups.recyclingTimeUnit },
        { title: 'Product', field: 'product', align: "center", lookup: breedingSchemeLookups.product },
        { title: 'Multiplication Method', field: 'multiplicationMethod', align: "center", lookup: breedingSchemeLookups.multiplicationMethod },
        { title: 'Scheme Type', field: 'schemeType', align: "center", lookup: breedingSchemeLookups.schemeType, render: rowData => rowData.schemeType ? rowData.schemeType : "-" },
        { title: 'Maturity', field: 'maturity', align: "center", lookup: breedingSchemeLookups.maturity, render: rowData => rowData.maturity ? rowData.maturity : "-" },
        {
            title: 'Treatment', field: 'treatment', align: "center", cellStyle: {maxWidth: "300px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"}, lookup: breedingSchemeLookups.treatment, render: rowData => rowData.treatment ? rowData.treatment : "-",
            editComponent: inputProps => (
                <AutocompleteInput {...inputProps} />
            )
        },
        {
            title: 'Other',
            field: 'other',
            align: "center",
            cellStyle: {maxWidth: "200px", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis"},
            render: rowData => rowData.other ? rowData.other : "-"
        },
        {
            title: 'Market Segments',
            field: 'MarketSegments',
            align: "center",
            availableMarketSegments: breedingSchemes.availableMarketSegments,
            editComponent: inputProps => (
                <BreedingSchemeMarketSegmentsInput {...inputProps} />
            )
        }
    ];

    const handleUpdateAttendedMarketSegments = (rowData, selectedMarketSegments) => {
        const updatedBreedingSchemeObj = {
            ...rowData,
            MarketSegments: selectedMarketSegments
        }
        dispatch(updateBreedingScheme(updatedBreedingSchemeObj, { sharedOnly: props.sharedOnly }))
    }

    const handleDuplicateBreedingScheme = async () => {
        await dispatch(duplicateBreedingScheme(breedingSchemes.duplicateSchemeForm.breedingSchemeId, breedingSchemes.duplicateSchemeForm.breedingPipelineId, { sharedOnly: props.sharedOnly }))
        props.reloadGrid()
    }

    const handleCancelDuplicateBreedingScheme = () => {
        dispatch(cancelDuplicateBreedingScheme())
    }

    const handleRemoveBreedingScheme = async () => {
        await dispatch(removeBreedingScheme(breedingSchemes.removeSchemeForm.breedingSchemeId, breedingSchemes.removeSchemeForm.breedingPipelineId, { sharedOnly: props.sharedOnly }))
        props.reloadGrid()
    }

    const handleCancelRemoveBreedingScheme = () => {
        dispatch(cancelRemoveBreedingScheme())
    }

    const reloadBreedingSchemeGrid = () => {
        dispatch(getBreedingSchemes(props.breedingPipelineId, { sharedOnly: props.sharedOnly }))
    }

    const classes = useStyles()
    return (
        <Box px={0} className={classes.table} style={{ maxWidth: "92vw" }}>
            <UserDialog
                open={breedingSchemes.duplicateSchemeForm.open}
                contentTitle="Are you sure you want to create a copy of the selected breeding scheme?"
                contentText="Note: Breeding Scheme Settings and Breeding Stages under the selected Breeding Scheme will also be copied"
                handleAction={handleDuplicateBreedingScheme}
                handleCancel={handleCancelDuplicateBreedingScheme}
            />
            <UserDialog
                open={breedingSchemes.removeSchemeForm.open}
                contentTitle="Are you sure you want to remove the selected breeding scheme?"
                contentText=""
                handleAction={handleRemoveBreedingScheme}
                handleCancel={handleCancelRemoveBreedingScheme}
            />
            <MaterialTable
                title="Breeding Schemes"
                style={{ backgroundColor: "#ecf0f5" }}
                columns={columns}
                icons={TableIcons}
                isLoading={breedingSchemes.isLoading}
                options={{
                    search: false,
                    pageSize: 5,
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    exportButton: true,
                    columnsButton: false,
                    padding: "dense",
                    loadingType: "overlay",
                    toolbarButtonAlignment: "left",
                }}
                components={{
                    Row: rowProps => {
                        const breedingSchemeName = rowProps.data.name
                        return (
                            <TableCellTooltip title={breedingSchemeName}>
                                <MTableBodyRow {...rowProps} />
                            </TableCellTooltip>
                        )
                    },
                    Cell: cellProps => cellProps.columnDef.field === 'MarketSegments' ?
                        <TableCell>
                            <BreedingSchemeMarketSegmentsInput {...cellProps} handleSubmit={handleUpdateAttendedMarketSegments} />
                        </TableCell> : <MTableCell {...cellProps} />,
                    Actions: actionsProps =>
                        <>
                            <MTableActions {...actionsProps} />
                            {actionsProps.actions && ['toolbar', 'row'].includes(actionsProps.actions[0].position) &&
                                <>
                                    <Divider orientation="vertical" variant="fullWidth" flexItem style={{
                                        display: props.sharedOnly ? ((actionsProps.data && actionsProps.data.Shares[0]) && actionsProps.data.Shares[0].permission !== 'OWNER' ? "none" : "inline") : "inline"
                                    }} />
                                    <ShareContent
                                        count={actionsProps.data ? actionsProps.data.SharedCount : 0}
                                        reloadGrid={reloadBreedingSchemeGrid}
                                        isDisabled={props.sharedOnly ? ((actionsProps.data && actionsProps.data.Shares[0]) && actionsProps.data.Shares[0].permission !== 'OWNER' ? true : false) : false}
                                        isHidden={props.sharedOnly ? ((actionsProps.data && actionsProps.data.Shares[0]) ? (actionsProps.data.Shares[0].permission !== 'OWNER' ? true : false) : true) : false}
                                        groupId={actionsProps.data ? `BreedingScheme-${actionsProps.data.id}` : `BreedingScheme-${breedingSchemes.breedingPipelineId}`}
                                        BreedingPipelineId={breedingSchemes.breedingPipelineId}
                                        breedingSchemeIds={actionsProps.data ? [actionsProps.data.id] : breedingSchemes.data.map(breedingScheme => breedingScheme.id)}
                                        btnColor="primary"
                                        btnSize="small" />
                                </>
                            }
                        </>,
                    EditField: cellProps =>
                        (['treatment'].includes(cellProps.columnDef.field)) ?
                            <AutocompleteInput {...cellProps} /> : <MTableEditField {...cellProps} />
                }}
                data={breedingSchemes.data}
                actions={
                    [
                        rowData => ({
                            icon: () => <ViewCompactIcon color={parseInt(rowData.totalMarketSegments) === 0 ? "disabled" : "secondary"} />,
                            disabled: parseInt(rowData.totalMarketSegments) === 0,
                            tooltip: parseInt(rowData.totalMarketSegments) === 0 ? "Breeding Scheme Disabled (Must have atleast one market segment to enable breeding scheme)" : "Open Breeding Scheme",
                            isFreeAction: false,
                            onClick: (event) => history.push(`/breeding-stage/${rowData.id}`)
                        }),
                        rowData => ({
                            icon: () => <LibraryAddIcon color="primary" />,
                            tooltip: "Create Duplicate Breeding Scheme",
                            isFreeAction: false,
                            disabled: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            hidden: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            onClick: (event) => dispatch(confirmDuplicateBreedingScheme(rowData.id, rowData.BreedingPipelineId))
                        }),
                        rowData => ({
                            icon: () => <DeleteSweepIcon color="action" />,
                            tooltip: "Delete Breeding Scheme",
                            isFreeAction: false,
                            disabled: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            hidden: props.sharedOnly ? (rowData.Shares[0] && rowData.Shares[0].permission !== 'OWNER' ? true : false) : false,
                            onClick: (event) => dispatch(confirmRemoveBreedingScheme(rowData.id, rowData.BreedingPipelineId))
                        })
                    ]}
                editable={{
                    onRowAdd: newData =>
                        new Promise((resolve, reject) => {
                            newData.BreedingPipelineId = props.breedingPipelineId
                            if (props.sharedOnly && breedingSchemes.data[0].Shares[0].permission !== "OWNER") {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to add new breeding scheme to this breeding pipeline" }))
                                reject()
                            } else {
                                dispatch(createBreedingScheme(newData, { sharedOnly: props.sharedOnly }))
                                resolve(props.reloadGrid())
                            }
                        }),
                }}
                cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise((resolve, reject) => {
                            const updatedBreedingSchemeObj = {
                                ...rowData,
                                [columnDef.field]: newValue
                            }
                            if (props.sharedOnly && rowData.Shares[0].permission === "VIEWER") {
                                dispatch(showNotification({ type: "error", message: "Sorry, you dont have permission to make changes to this breeding scheme" }))
                                reject()
                            } else {
                                setBreedingSchemes({ ...breedingSchemes, isLoading: true })
                                dispatch(updateBreedingScheme(updatedBreedingSchemeObj, { sharedOnly: props.sharedOnly }))
                                resolve()
                            }
                        });
                    }
                }}
            />
        </Box >
    )
}

export default BreedingScheme;
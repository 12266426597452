import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { validateForm, signUp } from '../../../actions/userSignup'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import { DatePicker } from '@material-ui/pickers'
import Button from '@material-ui/core/Button'
import CountrySelector from '../../molecules/CountrySelector'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import AccountCircle from '@material-ui/icons/AccountCircle'
import EmailIcon from '@material-ui/icons/Email'
import EventNoteIcon from '@material-ui/icons/EventNote'
import BusinessIcon from '@material-ui/icons/Business'
import WorkIcon from '@material-ui/icons/Work'
import LockIcon from '@material-ui/icons/Lock'
import Alert from '@material-ui/lab/Alert';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { researchCenters } from '../../../constants'
import { Link } from "react-router-dom"
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { makeStyles, useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
    signUpForm: {
        marginTop: "10px",
        padding: "20px 15px",
        borderRadius: "10px",
        border: "1px solid #d3d3d3",
        backgroundColor: "white"
    },
    textField: {
        marginBottom: "20px",
    }
}))

const validateEmail = (email) => {
    const mailFormat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/
    return email.match(mailFormat)
}

const SignUpForm = (props) => {
    const [newUserAccount, setNewUserAccount] = useState({
        fullName: "",
        dateOfBirth: null,
        email: "",
        institution: "",
        position: "",
        country: "",
        password: "",
        confirmPassword: "",
        passwordMatch: true,
    });

    const [formValidator, setFormValidator] = useState({
        hasFullName: true,
        hasEmail: true,
        hasInstitution: true,
        hasPosition: true,
        hasCountry: true,
        hasPassword: true
    })

    const dispatch = useDispatch()

    const passwordMatch = () => {
        return newUserAccount.password === newUserAccount.confirmPassword
    }

    const validateSignupForm = () => {

        const hasFullName = newUserAccount.fullName.trim() !== ""
        const hasEmail = newUserAccount.email.trim() !== ""
        const hasInstitution = newUserAccount.institution.trim() !== ""
        const hasPosition = newUserAccount.position.trim() !== ""
        const hasCountry = newUserAccount.country.trim() !== ""
        const hasPassword = newUserAccount.password.trim() !== ""

        if (hasFullName && hasEmail && hasInstitution &&
            hasPosition && hasCountry && hasPassword) return true
        else return false
    }

    const handleChange = (e) => {
        setNewUserAccount({
            ...newUserAccount,
            [e.target.id]: e.target.value
        })
    }

    const handleDateChange = (date) => {
        setNewUserAccount({
            ...newUserAccount,
            dateOfBirth: date
        })
    }

    const handleCountryChange = (country) => {
        setNewUserAccount({
            ...newUserAccount,
            country: country
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        if (props.signUpFormState.isLoading) return;
        if (validateSignupForm()) {
            setFormValidator({
                hasFullName: true,
                hasEmail: true,
                hasInstitution: true,
                hasPosition: true,
                hasCountry: true,
                hasPassword: true
            })
            if (!validateEmail(newUserAccount.email))
                dispatch(validateForm("Invalid Email Address! Please provide a correct Email Address"))
            else if (!passwordMatch())
                setNewUserAccount({
                    ...newUserAccount,
                    passwordMatch: false
                })
            else {
                setNewUserAccount({
                    ...newUserAccount,
                    passwordMatch: true
                })
                dispatch(signUp(newUserAccount))
            }
        } else {
            setFormValidator({
                hasFullName: newUserAccount.fullName.trim() !== "",
                hasEmail: newUserAccount.email.trim() !== "",
                hasInstitution: newUserAccount.institution.trim() !== "",
                hasPosition: newUserAccount.position.trim() !== "",
                hasCountry: newUserAccount.country.trim() !== "",
                hasPassword: newUserAccount.password.trim() !== ""
            })
        }
    }

    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))
    const classes = useStyles()
    return (
        <Box width="90%" maxWidth="480px" my={smallScreen ? 4 : 2} >
            <Typography variant="h5">Sign Up</Typography>
            {
                (props.signUpFormState.message && !props.signUpFormState.success) &&
                <Alert severity="error">{props.signUpFormState.message}</Alert>
            }
            <form className={classes.signUpForm} noValidate autoComplete="off" onSubmit={handleSubmit}>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><AccountCircle /></Grid>
                    <Grid item xs={10}>
                        <TextField
                            id="fullName"
                            label="Full Name"
                            error={!formValidator.hasFullName}
                            helperText={!formValidator.hasFullName && "Field is required!"}
                            onChange={handleChange}
                            value={newUserAccount.fullName}
                            placeholder="Enter Your Full Name" required fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><EventNoteIcon /></Grid>
                    <Grid item xs={10}>
                        <DatePicker
                            id="dateOfBirth"
                            fullWidth
                            autoOk
                            required={false}
                            variant="inline"
                            label="Date of Birth"
                            maxDate={Date.now()}
                            placeholder="Enter Date of Birth"
                            value={newUserAccount.dateOfBirth}
                            onChange={handleDateChange}
                            format="MM/dd/yyyy"
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><EmailIcon /></Grid>
                    <Grid item xs={10}>
                        <TextField id="email" label="Email Address"
                            type="email"
                            error={!formValidator.hasEmail}
                            helperText={!formValidator.hasEmail && "Field is required!"}
                            onChange={handleChange}
                            value={newUserAccount.email}
                            placeholder="Enter Your Email" required fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><BusinessIcon /></Grid>
                    <Grid item xs={10}>
                        <Autocomplete
                            id="institution"
                            freeSolo
                            options={researchCenters.map((option) => option)}
                            getOptionLabel={(option) => {
                                // Value selected with enter, right from the input
                                if (typeof option === 'string') {
                                    return option;
                                }
                                // Add "xxx" option created dynamically
                                if (option.inputValue) {
                                    return option.inputValue;
                                }
                                // Regular option
                                return option.center;
                            }}
                            inputValue={newUserAccount.institution}
                            onInputChange={(e, newValue) => {
                                setNewUserAccount({
                                    ...newUserAccount,
                                    institution: newValue
                                })
                            }}
                            renderInput={(params) => (
                                <TextField {...params}
                                    label="Institution"
                                    margin="normal"
                                    error={!formValidator.hasInstitution}
                                    helperText={!formValidator.hasInstitution && "Field is required!"}
                                    variant="standard"
                                    placeholder="Select/Enter Your Institution"
                                    required fullWidth />
                            )}
                            renderOption={(option) => (
                                <Box flex={1}>
                                    <Box flexGrow={1}><Typography>{option.center}</Typography></Box>
                                    <Box flexGrow={1} display="none"><Typography display="block" variant="subtitle2">{option.location}</Typography></Box>
                                </Box>
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item>
                        <WorkIcon />
                    </Grid>
                    <Grid item xs={10}>
                        <TextField id="position" label="Position"
                            onChange={handleChange}
                            error={!formValidator.hasPosition}
                            helperText={!formValidator.hasPosition && "Field is required!"}
                            value={newUserAccount.position}
                            placeholder="Enter Your Position" required fullWidth />
                    </Grid>
                </Grid>
                <CountrySelector error={!formValidator.hasCountry} handleChange={handleCountryChange} value={newUserAccount.country} />
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><LockIcon /></Grid>
                    <Grid item xs={10}>
                        <TextField id="password" type="password" label="Password"
                            error={!formValidator.hasPassword}
                            helperText={!formValidator.hasPassword && "Field is required!"}
                            onChange={handleChange}
                            value={newUserAccount.password}
                            placeholder="Enter Password" required fullWidth />
                    </Grid>
                </Grid>
                <Grid container spacing={1} alignItems="flex-end" className={classes.textField}>
                    <Grid item><CheckCircleOutlineIcon /></Grid>
                    <Grid item xs={10}>
                        <TextField
                            id="confirmPassword"
                            type="password"
                            label="Confirm Password"
                            error={!newUserAccount.passwordMatch}
                            helperText={!newUserAccount.passwordMatch && "Password doesn't match"}
                            onChange={handleChange}
                            value={newUserAccount.confirmPassword}
                            placeholder="Confirm Password" required fullWidth />
                    </Grid>
                </Grid>
                <Box mb={1}>
                    <Button
                        type="submit"
                        disabled={props.signUpFormState.isLoading}
                        variant="contained"
                        color="secondary"
                        fullWidth>
                        Sign Up
                        {
                            props.signUpFormState.isLoading &&
                            <Box position="absolute" top="20%" right="25%">
                                <CircularProgress color="primary" size={20} thickness={5} />
                            </Box>
                        }
                    </Button>
                </Box>
            </form>
            <Box textAlign="center" mt={2}>
                <Typography>
                    Already have an account? <Link to="/login" color="secondary">Log In</Link>
                </Typography>
            </Box>
        </Box>
    )
}

export default SignUpForm;
import * as api from '../api';
import { login } from './userAuth'
import { SIGNUP_REQUEST, SIGNUP_RESPONSE, VERIFY_USER_REQUEST, SIGNUP_ERROR } from '../constants/actionTypes'
//Action Creators

export const validateForm = (validateResult) => async (dispatch) => {
    try {        
        dispatch({
            type: SIGNUP_ERROR, payload: {
                success: false,
                message: validateResult
            }
        })
    } catch (error) { console.log(error) }
}

export const signUp = (newAccountObj) => async (dispatch) => {
    try {
        dispatch({ type: SIGNUP_REQUEST })
        const { data } = await api.signup(newAccountObj)
        dispatch({ type: SIGNUP_RESPONSE, payload: data })
        //Automatically sign in user after successfull registration
        if (data.success) {
            dispatch({ type: VERIFY_USER_REQUEST }) //Show splash screen for 2 seconds
            setTimeout(() => {
                dispatch(login({ email: newAccountObj.email, password: newAccountObj.password }))
            }, 2000)
        }
    } catch (error) {
        dispatch({
            type: SIGNUP_ERROR, payload: {
                user: {},
                isAuthenticated: false,
                message: "Error signing up. Please try again"
            }
        })
    }
}

import { useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import {
    getUsers, updateUser, confirmRemoveUser, cancelRemoveUser, removeUser,
    confirmReactivateUser, cancelReactivateUser, reactivateUser
} from '../../../actions/users'
import { format } from 'date-fns'
import Box from '@material-ui/core/Box'
import MaterialTable from 'material-table'
import TableIcons from '../../molecules/TableIcons'
import BlockIcon from '@material-ui/icons/Block';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import makeStyles from '@material-ui/core/styles/makeStyles'
import UserDialog from '../../molecules/UserDialog'

const useStyles = makeStyles((theme) => ({
    table: {
        maxWidth: "93vw",
        '& th': {
            border: "1px solid #e3e3e3",
            background: "#ecf0f5"
        },
        '& td': {
            padding: "2px 24px 2px 16px",
            fontSize: "13px !important",
            border: "1px solid #e3e3e3",
        },
        '& thead > tr:first-child > th:first-child': {
            position: "sticky",
            left: 0,
            minWidth: "64px",
            backgroundColor: "#ecf0f5",
            zIndex: 99
        },
        '& tbody > tr > td:first-child': {
            position: "sticky",
            left: 0,
            minWidth: "64px",
            backgroundColor: "#fff",
            zIndex: 99
        },
        '& tbody > tr > td:nth-child(2), thead > tr:first-child > th:nth-child(2)': {
            position: "sticky",
            left: 64,
            minWidth: "65px",
            backgroundColor: "#ecf0f5",
            zIndex: 99
        },
        '& td input': {
            width: "100%"
        },
        '& td > div': {
            width: "auto !important"
        },
    }
}))

const UsersTable = () => {
    const tableRef = useRef()
    const usersState = useSelector((state) => state.users)
    const userAuth = useSelector((state) => state.userAuth)
    const [tablePageSize, setTablePageSize] = useState(20)

    const dispatch = useDispatch()
    const classes = useStyles()

    const columns = [
        {
            title: 'Full Name',
            field: 'fullName',
            align: "left",
            cellStyle: {
                whiteSpace: "nowrap",
                fontWeight: "bold",
                padding: "2px 5px",
                position: "sticky",
                backgroundColor: "#fff",
                zIndex: 10
            },
            removable: false,
            editable: "never"
        }, {
            title: 'Date of Birth',
            field: 'dateOfBirth',
            align: "center",
            cellStyle: { whiteSpace: "nowrap" },
            removable: false,
            render: rowData => rowData.dateOfBirth ? format(new Date(rowData.dateOfBirth), 'MMM dd, yyyy') : <b>-</b>,
            editable: "never"
        },
        { title: 'Email', field: 'email', align: "left", removable: false, editable: "never" },
        { title: 'Breeding Pipelines', field: 'totalBreedingPipelines', align: "center", removable: false, editable: "never" },
        { title: 'Market Segments', field: 'totalMarketSegments', align: "center", removable: true, editable: "never" },
        { title: 'Product Profiles', field: 'totalProductProfiles', align: "center", removable: true, editable: "never" },
        { title: 'Breeding Schemes', field: 'totalBreedingSchemes', align: "center", removable: true, editable: "never", hiddenByColumnsButton: true },
        { title: 'Institution', field: 'institution', align: "left", editable: "never" },
        { title: 'Position', field: 'position', align: "left", editable: "never" },
        {
            title: 'Country',
            field: 'country',
            align: "center",
            editable: "never"
        }, {
            title: 'Role',
            field: 'role',
            align: "center",
            removable: false,
            lookup: { 'admin': "admin", 'standard': "standard" }
        },
        {
            title: 'isVerified',
            field: 'isVerified',
            align: "center",
            editable: "never",
            removable: true
        },
        {
            title: 'Created At',
            field: 'createdAt',
            cellStyle: { whiteSpace: "nowrap" },
            hiddenByColumnsButton: true,
            render: rowData => format(new Date(rowData.createdAt), 'MMM dd, yyyy'),
            editable: "never"
        },
    ];

    const handleRemoveUser = async () => {
        await dispatch(removeUser(usersState.removeUserForm.userId))
        reloadGrid()
    }

    const handleCancelRemoveUser = () => {
        dispatch(cancelRemoveUser())
    }

    const handleReactivateUser = async () => {
        await dispatch(reactivateUser(usersState.reactivateUserForm.userId))
        reloadGrid()
    }

    const handleCancelReactivateUser = () => {
        dispatch(cancelReactivateUser())
    }

    const getUsersData = query => {
        setTablePageSize(query.pageSize)
        return new Promise((resolve, reject) => {
            Promise.resolve(dispatch(getUsers(query))
                .then((users) => {
                    const TableData = users.data.rows
                    const totalCount = users.data.count
                    resolve({
                        data: TableData,
                        page: query.page,
                        totalCount,
                    });
                }))
        });
    };

    const reloadGrid = () => {
        tableRef.current && tableRef.current.onQueryChange()
    }

    return (
        <Box px={2} className={classes.table} flexGrow={1}>
            <UserDialog
                open={usersState.removeUserForm.open}
                contentTitle="Are you sure you want to Deactivate the selected user?"
                contentText="Note: This user will not be able to access the system but their data will not be erased"
                handleAction={handleRemoveUser}
                handleCancel={handleCancelRemoveUser}
            />
            <UserDialog
                open={usersState.reactivateUserForm.open}
                contentTitle="Are you sure you want to Reactivate the selected user?"
                contentText="Note: This will allow the user to gain access to the system and their restored data."
                handleAction={handleReactivateUser}
                handleCancel={handleCancelReactivateUser}
            />
            <MaterialTable
                title="Users"
                tableRef={tableRef}
                columns={columns}
                icons={TableIcons}
                isLoading={usersState.isLoading}
                options={{
                    pageSize: tablePageSize,
                    pageSizeOptions: [20, 100, 200],
                    debounceInterval: 500,
                    searchAutoFocus: true,
                    sorting: false,
                    grouping: false,
                    draggable: false,
                    exportButton: true,
                    columnsButton: true,
                    addRowPosition: "first",
                    padding: "dense",
                    loadingType: "overlay",
                    toolbarButtonAlignment: "left",
                    rowStyle: rowData => ({
                        fontWeight: rowData.email === userAuth.user.email ? "bold" : "inherit",
                        backgroundColor: rowData.deletedAt === null ? rowData.isVerified ? "inherit" : "#fff3e0" : "#ffcdd2"
                    })
                }}
                data={query => getUsersData(query)}
                actions={[
                    rowData => ({
                        icon: () => <BlockIcon color={rowData.email === userAuth.user.email ? "disabled" : "error"} />,
                        tooltip: "Deactivate User",
                        hidden: rowData.deletedAt,
                        disabled: rowData.email === userAuth.user.email,
                        isFreeAction: false,
                        onClick: (event) => dispatch(confirmRemoveUser(rowData.id))
                    }),
                    rowData => ({
                        icon: () => <CheckCircleOutlineIcon style={{ color: "#43a047" }} />,
                        tooltip: "Reactivate User",
                        hidden: !rowData.deletedAt,
                        disabled: rowData.email === userAuth.user.email,
                        isFreeAction: false,
                        onClick: (event) => dispatch(confirmReactivateUser(rowData.id))
                    })
                ]}
                cellEditable={{
                    onCellEditApproved: (newValue, oldValue, rowData, columnDef) => {
                        return new Promise((resolve, reject) => {
                            const updatedUserObj = {
                                ...rowData,
                                [columnDef.field]: newValue
                            }
                            dispatch(updateUser(updatedUserObj))
                            resolve(reloadGrid())
                        });
                    }
                }}
            />
        </Box>
    )
}

export default UsersTable;
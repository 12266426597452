import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ALL_BREEDING_SCHEME_RESPONSE, LOADING_BREEDING_PIPELINE,
    CREATE_BREEDING_SCHEME_RESPONSE, UPDATE_BREEDING_SCHEME_RESPONSE,
    CONFIRM_DUPLICATE_BREEDING_SCHEME, CANCEL_DUPLICATE_BREEDING_SCHEME, DUPLICATE_BREEDING_SCHEME_RESPONSE,
    CONFIRM_REMOVE_BREEDING_SCHEME, CANCEL_REMOVE_BREEDING_SCHEME, REMOVE_BREEDING_SCHEME_RESPONSE
} from '../constants/actionTypes';

//Action Creators
export const getBreedingSchemes = (id, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.fetchBreedingSchemes(id, queryObj)
        dispatch({ type: FETCH_ALL_BREEDING_SCHEME_RESPONSE, payload: { ...data, breedingPipelineId: id } })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_ALL_BREEDING_SCHEME_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const createBreedingScheme = (breedingSchemeObj, queryObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_PIPELINE })
        const { data } = await api.createBreedingScheme(breedingSchemeObj)
        if (data.success) {
            await dispatch(getBreedingSchemes(breedingSchemeObj.BreedingPipelineId, queryObj))
            //TODO: Dipatch action to update the total number of Breeding Sschemes for the selected Breeding Pipeline            
        } else {
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
            dispatch({ type: CREATE_BREEDING_SCHEME_RESPONSE })
        }
    } catch (error) {
        dispatch({ type: CREATE_BREEDING_SCHEME_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateBreedingScheme = (breedingSchemeObj, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.updateBreedingScheme(breedingSchemeObj.id, breedingSchemeObj)
        if (data.success) dispatch(getBreedingSchemes(breedingSchemeObj.BreedingPipelineId, queryObj))
        else {
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
            dispatch({ type: UPDATE_BREEDING_SCHEME_RESPONSE })
        }
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_SCHEME_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const orderBreedingScheme = (orderObj, queryObj) => async (dispatch) => {
    try {
        const { data } = await api.orderBreedingScheme(orderObj)
        if (data.success) dispatch(getBreedingSchemes(orderObj.BreedingPipelineId, queryObj))
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
    } catch (error) {
        dispatch({ type: UPDATE_BREEDING_SCHEME_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmDuplicateBreedingScheme = (id, breedingPipelineId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_DUPLICATE_BREEDING_SCHEME, payload: {
            duplicateSchemeForm: {
                breedingSchemeId: id,
                breedingPipelineId: breedingPipelineId,
                open: true
            }
        }
    })
}

export const cancelDuplicateBreedingScheme = () => async (dispatch) => {
    dispatch({
        type: CANCEL_DUPLICATE_BREEDING_SCHEME, payload: {
            duplicateSchemeForm: {
                breedingSchemeId: "",
                breedingPipelineId: "",
                open: false
            }
        }
    })
}

export const duplicateBreedingScheme = (id, breedingPipelineId, queryObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_PIPELINE })
        dispatch({
            type: DUPLICATE_BREEDING_SCHEME_RESPONSE, payload: {
                duplicateSchemeForm: {
                    breedingSchemeId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        const { data } = await api.duplicateBreedingScheme(id)
        if (data.success) {
            await dispatch(getBreedingSchemes(breedingPipelineId, queryObj))
        }
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
        return data.success
    } catch (error) {
        dispatch({
            type: DUPLICATE_BREEDING_SCHEME_RESPONSE, payload: {
                duplicateSchemeForm: {
                    breedingSchemeId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveBreedingScheme = (id, breedingPipelineId) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_BREEDING_SCHEME, payload: {
            removeSchemeForm: {
                breedingSchemeId: id,
                breedingPipelineId: breedingPipelineId,
                open: true
            }
        }
    })
}

export const cancelRemoveBreedingScheme = () => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_BREEDING_SCHEME, payload: {
            removeSchemeForm: {
                breedingSchemeId: "",
                breedingPipelineId: "",
                open: false
            }
        }
    })
}

export const removeBreedingScheme = (id, breedingPipelineId, queryObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_BREEDING_PIPELINE })
        dispatch({
            type: REMOVE_BREEDING_SCHEME_RESPONSE, payload: {
                removeSchemeForm: {
                    breedingSchemeId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        const { data } = await api.removeBreedingScheme(id)
        if (data.success) {
            await dispatch(getBreedingSchemes(breedingPipelineId, queryObj))
            //TODO: Dipatch action to update the total number of Breeding Schemes for the selected Breeding Pipeline
        }
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
    } catch (error) {
        dispatch({
            type: REMOVE_BREEDING_SCHEME_RESPONSE, payload: {
                removeSchemeForm: {
                    breedingSchemeId: "",
                    breedingPipelineId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import Backdrop from '@material-ui/core/Backdrop';
import SpeedDial from '@material-ui/lab/SpeedDial';
import DirectionsWalkIcon from '@material-ui/icons/DirectionsWalk';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import MenuBookRoundedIcon from '@material-ui/icons/MenuBookRounded';
import HelpIcon from '@material-ui/icons/Help';
import Tour from 'reactour'
import Navigation from '../../organisms/Navigation'
import BreedingPipeline from '../../organisms/BreedingPipeline'
import makeStyles from '@material-ui/core/styles/makeStyles'
import { Button, Divider, Grid, Typography } from '@material-ui/core'
import ViewColumnIcon from '@material-ui/icons/ViewColumn'
import SaveAltIcon from '@material-ui/icons/SaveAlt'
import AddBoxIcon from '@material-ui/icons/AddBox'

const useStyles = makeStyles((theme) => ({
    root: {
        fontSize: "0.94rem",
        maxWidth: "550px",
        '& .eTpeTG': {
            top: 8,
            right: 12,
            width: 10,
            height: 12
        },
    },
    speedDial: {
        position: 'fixed',
        '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
            bottom: theme.spacing(8),
            right: theme.spacing(4),
            zIndex: 1202,
            '& svg': {
                fontSize: "1.7rem"
            }
        },
        '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
            top: theme.spacing(2),
            left: theme.spacing(2),
            zIndex: 1202
        },
    },
    fab: {
        color: "#fff",
        backgroundColor: "#152733"
    },
    backdrop: {
        zIndex: 1201
    }
}))

const BreedingPipelinePage = () => {
    const history = useHistory()
    let { shared } = useParams();
    const [isHelpOpen, setIsHelpOpen] = useState(false)
    const [isTourOpen, setIsTourOpen] = useState(false)
    const [reOpenTour, setReOpenTour] = useState(true)
    const userAuth = useSelector((state) => state.userAuth)
    const breedingPipelinesState = useSelector((state) => state.breedingPipelines)
   
    useEffect(() => {
        const sharedOnly = shared ? true : false
        reOpenTour && !sharedOnly &&
            setIsTourOpen(breedingPipelinesState.openTour)
    }, [shared, breedingPipelinesState, reOpenTour])

    const helpActions = [
        { icon: <DirectionsWalkIcon />, name: 'Tour', method: () => setIsTourOpen(true) },
        { icon: <MenuBookRoundedIcon />, name: 'Vocabulary', method: () => history.push("/vocabulary") },
    ];

    const steps = [
        {
            selector: '.appTour-step-0',
            action: () => setIsHelpOpen(false),
            content: ({ goTo, inDom }) => (
                <Grid container spacing={2}>
                    <Grid item xs={4} style={{ display: "flex", alignItems: "center" }}>
                        <Box alignContent="center">
                            <img src={process.env.PUBLIC_URL + '/logo.svg'} alt="Excellence in Breeding Platform" width="150" />
                        </Box>
                    </Grid>
                    <Grid item xs={1}>
                        <Divider orientation="vertical" />
                    </Grid>
                    <Grid item xs={7}>
                        <Box textAlign="center" mt={2}>
                            <Typography>Hi <b>{userAuth.user.fullName}</b>,</Typography>
                            <br />
                            <Typography>
                                Let's get started with a quick guided tour of the <b>Breeding Pipeline Manager</b>
                            </Typography>
                            <br />
                            <Typography variant="body2">
                                This tool helps you to formalize the market segments,
                                product profiles and breeding schemes associated to their breeding pipeline(s).
                            </Typography>
                        </Box>
                    </Grid>
                </Grid >
            ),
            style: {
                width: "550px"
            },
            position: 'center'
        }, {
            selector: '.appTour-step-1',
            action: () => setIsHelpOpen(false),
            content: ({ goTo, inDom }) => (
                <Box >
                    <Typography variant="button">User Profile</Typography>
                    <Divider orientation="horizontal" />
                    <Box mt={1}>
                        <Typography variant="body2">
                            Click on your name to update your profile
                        </Typography>
                        <Box textAlign="center" my={1}>
                            <img src={process.env.PUBLIC_URL + '/tour/profilePopover.png'} alt="Profile Popover" width="200" />
                        </Box>
                        <Typography variant="body2">
                            From the above form, you will be able to change password or logout from the current session
                        </Typography>
                    </Box>
                </Box >
            ),
            style: {
                width: "300px"
            },
            position: 'right',
        },
        {
            selector: '.appTour-step-2',
            action: () => setIsHelpOpen(false),
            content: ({ goTo, inDom }) => (
                <Box >
                    <Typography variant="button">Breeding Pipeline Toolbar</Typography>
                    <Divider orientation="horizontal" />
                    <Box mt={1}>
                        <Typography variant="body2">
                            Here is a toolbar to perform different operations on this page.
                        </Typography>
                        <Box mt={1}>
                            <Grid container spacing={1}>
                                <Grid item xs={2}>
                                    <ViewColumnIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2">
                                        Toggle show/hide Breeding Pipeline Columns
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <SaveAltIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2">
                                        Export Breeding Pipelines to Excel (.xlsx) or PDF
                                    </Typography>
                                </Grid>
                                <Grid item xs={2}>
                                    <AddBoxIcon />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography variant="body2">
                                        Add new Breeding Pipeline
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Box>
                    </Box>
                </Box >
            ),
            style: {
                width: "300px"
            },
            position: 'right',
        },
        {
            selector: '.tourBtn',
            action: () => setIsHelpOpen(true),
            content: "To reopen this tour guide, click here.",
            position: 'left'
        },
        {
            selector: '.appTour-step-3',
            action: () => setIsHelpOpen(false),
            content: ({ goTo, inDom }) => (
                <Box>
                    <Typography variant="body1">Ok, let's start by adding our first Breeding Pipeline.</Typography>
                    <Typography variant="body2" style={{ display: "flex", alignItems: "center" }}> Click&nbsp;<AddBoxIcon />&nbsp;to create a new Breeding Pipeline.</Typography>
                    <Box textAlign="center" mt={2}>
                        <Button variant="contained" color="secondary" size="small" onClick={() => setIsTourOpen(false)}>End Tour</Button>
                    </Box>
                </Box>
            ),
            style: {
                width: "320px"
            },
            position: 'right'
        },
    ]

    const classes = useStyles()

    return (
        <>
            <Tour
                className={classes.root}
                closeWithMask={false}
                steps={steps}
                showNumber={false}
                maskSpace={10}
                rounded={10}
                isOpen={isTourOpen}
                onRequestClose={() => {
                    setReOpenTour(false)
                    setIsTourOpen(false)
                    setIsHelpOpen(false)
                }}
            />
            <Header />
            <Box my={2} pb={7} display="flex">
                <Navigation />
                <BreedingPipeline sharedOnly={shared ? true : false}/>
            </Box>
            <Backdrop open={isHelpOpen} className={classes.backdrop} />
            <SpeedDial
                FabProps={{
                    color: "secondary"
                }}
                ariaLabel="HelpDial"
                className={classes.speedDial + " tourBtn"}
                icon={<HelpIcon />}
                onClose={() => setIsHelpOpen(false)}
                onOpen={() => setIsHelpOpen(true)}
                open={isHelpOpen}
                direction="up"
            >
                {helpActions.map((action) =>
                    <SpeedDialAction
                        className={classes.fab}
                        key={action.name}
                        icon={action.icon}
                        tooltipTitle={action.name}
                        onClick={action.method}
                    />
                )}
            </SpeedDial>
            <Footer />
        </>
    )
}

export default BreedingPipelinePage;
import { useState, useEffect } from 'react'
import Autocomplete from '@material-ui/lab/Autocomplete'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'

const AutocompleteField = (props) => {
    const [selectedValue, setSelectedValue] = useState(props.value)

    useEffect(() => {
        setSelectedValue(props.value)
    }, [props])

    return (
        <Autocomplete
            id={props.id}
            name={props.name}
            className={props.className}
            onBlur={props.onBlur}
            disabled={props.disabled}
            freeSolo
            options={props.lookup.map((option) => option)}
            getOptionLabel={(option) => {
                // Value selected with enter, right from the input
                if (typeof option === 'string') {
                    return option;
                }
                // Add "xxx" option created dynamically
                if (option.inputValue) {
                    return option.inputValue;
                }
                // Regular option
                return option;
            }}
            inputValue={selectedValue}
            onInputChange={(e, newValue) => {
                setSelectedValue(newValue)
            }}
            onSelect={props.onChange}
            renderInput={(params) => (
                <TextField {...params}
                    name={props.name}
                    fullWidth
                    onInput={(e) => {
                        e.target.value = e.target.value.toString().slice(0, props.maxLength)
                    }}
                />
            )}
            renderOption={(option) => (
                <Box flex={1}>
                    <Box flexGrow={1}><Typography>{option}</Typography></Box>
                </Box>
            )}
        />
    )
}

export default AutocompleteField
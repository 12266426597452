import Tooltip from '@material-ui/core/Tooltip'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

const CustomTooltip = withStyles((theme) => ({
    tooltip: {
        backgroundColor: theme.palette.primary.main,
        color: '#fff',
        maxWidth: 250,
        paddingTop: "7px",
        border: '1px solid #dadde9',
    },
    arrow: {
        color: theme.palette.primary.main,
    },
}))(Tooltip);

const TableCellTooltip = (props) => {

    return <CustomTooltip arrow placement="top" title={
        <Typography variant="button" style={{ fontSize: 11 }}>
            {props.title}
        </Typography>
    } >
        {props.children}
    </CustomTooltip>
}

export default TableCellTooltip
import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ALL_USERS_REQUEST, FETCH_ALL_USERS_RESPONSE, FETCH_PROFILE_REQUEST, FETCH_PROFILE_RESPONSE,
    LOADING_USERS, UPDATE_USER_RESPONSE, CONFIRM_REMOVE_USER, CANCEL_REMOVE_USER, REMOVE_USER_RESPONSE,
    CONFIRM_REACTIVATE_USER, CANCEL_REACTIVATE_USER, REACTIVATE_USER_RESPONSE, UPDATE_PROFILE_REQUEST, UPDATE_PROFILE_RESPONSE
} from '../constants/actionTypes';

//Action Creators
export const getProfile = () => async (dispatch) => {
    try {
        dispatch({ type: FETCH_PROFILE_REQUEST })
        const { data } = await api.fetchProfile()
        dispatch({ type: FETCH_PROFILE_RESPONSE, payload: data })
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const getUsers = (query) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_USERS_REQUEST })
        const { data } = await api.fetchUsers(query)
        dispatch({ type: FETCH_ALL_USERS_RESPONSE })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
        return data
    } catch (error) {
        dispatch({ type: FETCH_ALL_USERS_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateUser = (userObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_USERS })
        const { data } = await api.updateUser(userObj.id, userObj)
        dispatch({ type: UPDATE_USER_RESPONSE })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_USER_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateProfile = (userObj) => async (dispatch) => {
    try {
        dispatch({ type: UPDATE_PROFILE_REQUEST })
        const { data } = await api.updateProfile(userObj)
        data.success && dispatch(getProfile())
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_PROFILE_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveUser = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_USER, payload: {
            removeUserForm: {
                userId: id,
                open: true
            }
        }
    })
}

export const cancelRemoveUser = (id) => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_USER, payload: {
            removeUserForm: {
                userId: "",
                open: false
            }
        }
    })
}

export const removeUser = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_USERS })
        const { data } = await api.removeUser(id)
        dispatch({
            type: REMOVE_USER_RESPONSE, payload: {
                removeUserForm: {
                    userId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_USER_RESPONSE, payload: {
                removeUserForm: {
                    userId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmReactivateUser = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REACTIVATE_USER, payload: {
            reactivateUserForm: {
                userId: id,
                open: true
            }
        }
    })
}

export const cancelReactivateUser = (id) => async (dispatch) => {
    dispatch({
        type: CANCEL_REACTIVATE_USER, payload: {
            reactivateUserForm: {
                userId: "",
                open: false
            }
        }
    })
}

export const reactivateUser = (id) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_USERS })
        const { data } = await api.restoreUser(id)
        dispatch({
            type: REACTIVATE_USER_RESPONSE, payload: {
                reactivateUserForm: {
                    userId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REACTIVATE_USER_RESPONSE, payload: {
                removeUserForm: {
                    userId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
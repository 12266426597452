import { useState, useEffect } from 'react'
import TextField from '@material-ui/core/InputBase'

const GridTextField = (props) => {
    const [newValue, setNewValue] = useState("-")
    const [prevValue, setPrevValue] = useState(props.columnDef.render ? props.columnDef.render(props.rowData) : props.value)
    const [width, setWidth] = useState("4ch")
    const [backgroundColor, setBackgroundColor] = useState("inherit")

    useEffect(() => {
        setNewValue(props.columnDef.render ? props.columnDef.render(props.rowData) : props.value)
    }, [props])

    useEffect(() => {
        if (props.columnDef.field.split(".").pop() === "noOfEnvironments")
            setBackgroundColor(newValue > 0 ? "#ffd6c9" : "inherit")
        setWidth(newValue.length < 3 ? "4ch" : (newValue.length + 1) + "ch")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [newValue])

    const handleFocus = (e) => {
        setTimeout(() => {
            e.target === document.activeElement && e.target.select()
        }, 3)
    }

    const handleChange = (e) => {
        setNewValue(e.target.value)
        setWidth(e.target.value.length < 3 ? "3ch" : (e.target.value.length + 1) + "ch")
    }

    const handleSubmit = (e) => {
        // eslint-disable-next-line eqeqeq
        if (prevValue != newValue) {
            props.handleSubmit(newValue, prevValue, props.rowData, props.columnDef)
            setPrevValue(newValue)
        }
    }

    return (
        <TextField
            name={props.name}
            disabled={props.columnDef.editable === "never"}
            onBlur={handleSubmit}
            onKeyPress={(e) => e.key === 'Enter' && handleSubmit(e)}
            onFocus={handleFocus}
            onChange={handleChange}
            value={newValue}
            style={{ ...props.columnDef.cellStyle, minWidth: width, height: "30px", padding: 0, backgroundColor: backgroundColor }}
        />
    )
}

export default GridTextField
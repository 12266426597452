import * as api from '../api';
import { showNotification } from './notification'

//Action Creators
export const getUnsharedUsers = (queryObj) => async (dispatch) => {
    try {
        const { data } = await api.fetchUnsharedUsers(queryObj)
        if (data.success)
            return data
        else {
            dispatch(showNotification({ type: "error", message: data.message }))
            return null
        }
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const getSharedUsers = (shareObj) => async (dispatch) => {
    try {
        const { data } = await api.fetchSharedUsers(shareObj)
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
        return data
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const shareContent = (shareObj) => async (dispatch) => {
    try {
        const { data } = await api.shareContent(shareObj)
        dispatch(showNotification({ type: data.success ? "success" : "error", message: data.message }))
        return data
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const removeShare = (shareObj) => async (dispatch) => {
    try {
        const { data } = await api.removeSharedUser(shareObj)
        dispatch(showNotification({ type: data.success ? "success" : "error", message: data.message }))
        return data
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
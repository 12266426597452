import { useSelector, useDispatch } from 'react-redux';
import { closeNotification, resetNotification } from '../../../actions/notification'
import Snackbar from '@material-ui/core/Snackbar'
import MuiAlert from '@material-ui/lab/Alert'

function Alert(props) {
    return <MuiAlert elevation={6} variant="standard" {...props} />;
}

const Notification = () => {

    const notificationState = useSelector((state) => state.notification)
    const dispatch = useDispatch()

    const handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        dispatch(closeNotification())
    }

    const handleResetNotification = () => {
        dispatch(resetNotification())
    }

    return (
        <Snackbar
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            style={{ top: "7px" }}
            open={notificationState.open}
            autoHideDuration={3500}
            onClose={handleCloseNotification}
            onExited={handleResetNotification}
        >
            <Alert onClose={handleCloseNotification} severity={notificationState.type}>
                {notificationState.message}
            </Alert>
        </Snackbar>
    )
}

export default Notification
import { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useSelector, useDispatch } from 'react-redux';
import { changePassword, resetChangePassword } from '../../../actions/changePassword'
import Grid from '@material-ui/core/Grid'
import ChangePasswordForm from '../../organisms/ChangePasswordForm'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import WelcomeSection from '../../molecules/WelcomeSection'

const useStyles = makeStyles((theme) => ({
    normalScreen: {
        height: "100%"
    },
    smallScreen: {
        height: "20%"
    },
    welcomeSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        background: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '& p': {
            fontSize: "0.94rem"
        },
        '& a': {
            fontWeight: "bold",
            color: "inherit"
        }
    },
    formSection: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    }
}))

const ChangePasswordPage = () => {
    const changePasswordState = useSelector((state) => state.changePassword)
    const dispatch = useDispatch()
    const history = useHistory()
    const theme = useTheme()
    const smallScreen = useMediaQuery(theme.breakpoints.down('xs'))

    const classes = useStyles()

    useEffect(() => {
        if (changePasswordState.success) {
            dispatch(resetChangePassword())
            history.push("/")
        }
    }, [changePasswordState, history, dispatch])

    const handleChangePassword = (passwordObj) => {
        dispatch(changePassword(passwordObj))
    }

    return (
        <Grid className={smallScreen ? classes.smallScreen : classes.normalScreen} container>
            <Grid className={classes.welcomeSection} xs={12} md={5} item>
                <WelcomeSection />
            </Grid>
            <Grid className={classes.formSection} xs={12} md={7} item>
                <ChangePasswordForm
                    handleChangePassword={handleChangePassword}
                    changePasswordState={changePasswordState}
                />
            </Grid>
        </Grid>
    )
}

export default ChangePasswordPage;
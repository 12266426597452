import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { selectBreedingStagesData, selectBreedingStagesIsLoading, selectBreedingStagesPipeline, selectBreedingSchemePermission } from '../../../selectors/breedingStageSelector'
import { selectBreedingSchemeSettingsData, selectBreedingSchemeSettingsIsLoading } from '../../../selectors/breedingSchemeSettingsSelector'
import { useParams } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Header from '../../organisms/Header'
import Footer from '../../organisms/Footer'
import BreedingSchemeSettings from '../../organisms/BreedingSchemeSettings'
import BreedingStage from '../../organisms/BreedingStage'
import BreedingStageDialog from '../../organisms/BreedingStageDialog'
import TraitsForm from '../../organisms/TraitsForm'
import { getSchemeSettings } from '../../../actions/breedingSchemeSettings'
import { getBreedingStages, getBreedingPipeline } from '../../../actions/breedingStage';

const BreedingStagePage = () => {
    const breedingSchemePermissionState = useSelector(selectBreedingSchemePermission)
    const breedingSchemeSettingsDataState = useSelector(selectBreedingSchemeSettingsData)
    const breedingSchemeSettingsIsLoadingState = useSelector(selectBreedingSchemeSettingsIsLoading)
    const breedingStagePipelineState = useSelector(selectBreedingStagesPipeline)
    const breedingStageDataState = useSelector(selectBreedingStagesData)
    const breedingStageIsLoadingState = useSelector(selectBreedingStagesIsLoading)
    const [headerContent, setHeaderContent] = useState({
        breedingPipeline: "",
        name: "",
        headerPage: "breedingScheme"
    })
    const dispatch = useDispatch()
    let { id } = useParams();

    useEffect(() => {
        if (id) {
            dispatch(getBreedingPipeline(id))
            dispatch(getSchemeSettings(id))
            dispatch(getBreedingStages(id))
        }
    }, [dispatch, id])

    useEffect(() => {
        if (Object.keys(breedingStagePipelineState).length) {
            setHeaderContent({
                ...headerContent,
                breedingPipeline: breedingStagePipelineState.BreedingPipeline.specie,
                name: breedingStagePipelineState.name
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [breedingStagePipelineState])
    return (
        <>
            <Header content={headerContent} />
            <Box mb={2} pb={7}>
                <BreedingStageDialog breedingSchemeId={id} />
                <TraitsForm />
                <Grid container spacing={0}>
                    <Grid item xs={12} md={12}>
                        <BreedingSchemeSettings data={breedingSchemeSettingsDataState} permission={breedingSchemePermissionState} isLoading={breedingSchemeSettingsIsLoadingState} />
                    </Grid>
                    <Grid item xs={12} md={12}>
                        <BreedingStage
                            permission={breedingSchemePermissionState}
                            breedingScheme={breedingStagePipelineState}
                            breedingStagesData={breedingStageDataState}
                            isLoading={breedingStageIsLoadingState}
                            pipelineSettings={breedingSchemeSettingsDataState}
                            breedingSchemeId={id}
                        />
                    </Grid>
                </Grid>
            </Box>
            <Footer />
        </>
    )
}

export default BreedingStagePage;
import * as api from '../api';
import { showNotification } from './notification'
import {
    FETCH_ALL_PRODUCT_PROFILE_TRAIT_REQUEST, FETCH_ALL_PRODUCT_PROFILE_TRAIT_RESPONSE, CREATE_PRODUCT_PROFILE_TRAIT_RESPONSE,
    UPDATE_PRODUCT_PROFILE_TRAIT_RESPONSE, CONFIRM_DUPLICATE_PRODUCT_PROFILE_TRAIT, CANCEL_DUPLICATE_PRODUCT_PROFILE_TRAIT,
    DUPLICATE_PRODUCT_PROFILE_TRAIT_RESPONSE, CONFIRM_REMOVE_PRODUCT_PROFILE_TRAIT, CANCEL_REMOVE_PRODUCT_PROFILE_TRAIT,
    REMOVE_PRODUCT_PROFILE_TRAIT_RESPONSE, LOADING_PRODUCT_PROFILE_TRAIT
} from '../constants/actionTypes'

//Action Creators
export const getProductProfileTraits = (id) => async (dispatch) => {
    try {
        dispatch({ type: FETCH_ALL_PRODUCT_PROFILE_TRAIT_REQUEST, payload: { productProfileId: id } })
        const { data } = await api.fetchProductProfileTraits(id)
        dispatch({ type: FETCH_ALL_PRODUCT_PROFILE_TRAIT_RESPONSE, payload: { ...data, productProfileId: id } })
        if (!data.success) dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch({ type: FETCH_ALL_PRODUCT_PROFILE_TRAIT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const createProductProfileTrait = (productProfileTraitObj) => async (dispatch) => {
    try {
        //dispatch({ type: LOADING_PRODUCT_PROFILE })        
        const { data } = await api.createProductProfileTrait(productProfileTraitObj)
        dispatch({ type: CREATE_PRODUCT_PROFILE_TRAIT_RESPONSE })
        if (data.success) dispatch(getProductProfileTraits(productProfileTraitObj.ProductProfileId))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: CREATE_PRODUCT_PROFILE_TRAIT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const updateProductProfileTrait = (productProfileTraitObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_PRODUCT_PROFILE_TRAIT })
        const { data } = await api.updateProductProfileTrait(productProfileTraitObj.id, productProfileTraitObj)
        if (data.success) dispatch(getProductProfileTraits(productProfileTraitObj.ProductProfileId))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_PRODUCT_PROFILE_TRAIT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const orderProductProfileTrait = (orderObj) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_PRODUCT_PROFILE_TRAIT })
        const { data } = await api.orderProductProfileTrait(orderObj)
        if (data.success) dispatch(getProductProfileTraits(orderObj.ProductProfileId))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({ type: UPDATE_PRODUCT_PROFILE_TRAIT_RESPONSE })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmDuplicateProductProfileTrait = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_DUPLICATE_PRODUCT_PROFILE_TRAIT, payload: {
            duplicateProductProfileTraitForm: {
                productProfileTraitId: id,
                open: true
            }
        }
    })
}

export const cancelDuplicateProductProfileTrait = (id) => async (dispatch) => {
    dispatch({
        type: CANCEL_DUPLICATE_PRODUCT_PROFILE_TRAIT, payload: {
            duplicateProductProfileTraitForm: {
                productProfileTraitId: "",
                open: false
            }
        }
    })
}

export const duplicateProductProfileTrait = (id, productProfileId) => async (dispatch) => {
    try {
        dispatch({ type: LOADING_PRODUCT_PROFILE_TRAIT })
        dispatch({
            type: DUPLICATE_PRODUCT_PROFILE_TRAIT_RESPONSE, payload: {
                duplicateProductProfileTraitForm: {
                    productProfileTraitId: "",
                    open: false
                }
            }
        })
        const { data } = await api.duplicateProductProfileTrait(id)
        if (data.success) {
            await dispatch(getProductProfileTraits(productProfileId))
        }
        else
            dispatch(showNotification({
                type: data.success ? "success" : "error",
                message: data.message
            }))
    } catch (error) {
        dispatch({
            type: DUPLICATE_PRODUCT_PROFILE_TRAIT_RESPONSE, payload: {
                duplicateProductProfileTraitForm: {
                    productProfileTraitId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const confirmRemoveProductProfileTrait = (id) => async (dispatch) => {
    dispatch({
        type: CONFIRM_REMOVE_PRODUCT_PROFILE_TRAIT, payload: {
            removeProductProfileTraitForm: {
                productProfileTraitId: id,
                open: true
            }
        }
    })
}

export const cancelRemoveProductProfileTrait = (id) => async (dispatch) => {
    dispatch({
        type: CANCEL_REMOVE_PRODUCT_PROFILE_TRAIT, payload: {
            removeProductProfileTraitForm: {
                productProfileTraitId: "",
                open: false
            }
        }
    })
}

export const removeProductProfileTrait = (id, productProfileId) => async (dispatch) => {
    try {
        //dispatch({ type: LOADING_PRODUCT_PROFILE })
        const { data } = await api.removeProductProfileTrait(id)
        dispatch({
            type: REMOVE_PRODUCT_PROFILE_TRAIT_RESPONSE, payload: {
                removeProductProfileTraitForm: {
                    productProfileTraitId: "",
                    open: false
                }
            }
        })
        if (data.success) dispatch(getProductProfileTraits(productProfileId))
        else dispatch(showNotification({
            type: data.success ? "success" : "error",
            message: data.message
        }))
    } catch (error) {
        dispatch({
            type: REMOVE_PRODUCT_PROFILE_TRAIT_RESPONSE, payload: {
                removeProductProfileTraitForm: {
                    productProfileTraitId: "",
                    open: false
                }
            }
        })
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}

export const exportProductProfileTraits = (productProfileId) => async (dispatch) => {
    try {
        const { data } = await api.exportProductProfileTrait(productProfileId)
        if (data.success) window.open(`${window.location.origin}/api/product-profile-trait/export/${productProfileId}`)
        else dispatch(showNotification({ type: "error", message: data.message }))
    } catch (error) {
        dispatch(showNotification({ type: "error", message: error.message }))
    }
}
import { SIGNUP_REQUEST, SIGNUP_RESPONSE, SIGNUP_ERROR } from '../constants/actionTypes'

const initialState = {
    isLoading: false,
    success: false,
    message: null
}

const reducer = (userSignup = initialState, action) => {
    switch (action.type) {
        case SIGNUP_REQUEST:
            return {
                ...userSignup,
                isLoading: true,
            }
        case SIGNUP_RESPONSE:
            return {
                ...action.payload,
                isLoading: false,
            }
        case SIGNUP_ERROR:
            return {
                ...action.payload,
                isLoading: false,
            }       
        default:
            return userSignup
    }
}

export default reducer
import { useEffect } from 'react'
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"
import { useSelector, useDispatch } from 'react-redux';
import { checkUser } from './actions/userAuth'
import { MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles'
import blue from '@material-ui/core/colors/blue'
import LoginPage from './components/pages/LoginPage'
import SignUpPage from './components/pages/SignUpPage'
import ChangePasswordPage from './components/pages/ChangePasswordPage'
import BreedingPipelinePage from './components/pages/BreedingPipelinePage'
import ProductProfilePage from './components/pages/ProductProfilePage';
import BreedingStagePage from './components/pages/BreedingStagePage'
import BreedingSchemeFlowchartPage from './components/pages/BreedingSchemeFlowchartPage'
import UsersPage from './components/pages/UsersPage'
import ProfilePage from './components/pages/ProfilePage'
import VocabularyPage from './components/pages/VocabularyPage'
import AccountVerification from './components/organisms/AccountVerification'
import ForgotPassword from './components/organisms/ForgotPassword'
import PageProgress from './components/molecules/PageProgress'
import Notification from './components/organisms/Notification'

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#152733'
    },
    secondary: blue
  },
  typography: {
    h4: {
      fontSize: '1.15rem',
      fontWeight: "bold"
    },
    h6: {
      fontSize: '1rem'
    },
    subtitle2: {
      fontSize: '0.79rem'
    }
  }
});

const PublicRoute = ({ children, ...rest }) => {
  const userAuth = useSelector((state) => state.userAuth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        !userAuth.isAuthenticated ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: location.state ? location.state.from.pathname : "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

const PrivateRoute = ({ children, ...rest }) => {
  const userAuth = useSelector((state) => state.userAuth)
  return (
    <Route
      {...rest}
      render={({ location }) =>
        userAuth.isAuthenticated ? userAuth.isVerified ? (
          children
        ) : <AccountVerification open={!userAuth.isVerified} /> : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
}

function App() {
  const userAuth = useSelector((state) => state.userAuth)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(checkUser())
  }, [dispatch])

  return (
    <>
      <Notification />
      {!userAuth.isVerifying ?
        <Router>
          <ThemeProvider theme={theme}>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Switch>
                <PublicRoute exact path="/login"><LoginPage /></PublicRoute>
                <PublicRoute exact path="/signup"><SignUpPage /></PublicRoute>
                <PublicRoute exact path="/forgot-password/:token?"><ForgotPassword /></PublicRoute>
                <PrivateRoute exact path="/change-password"><ChangePasswordPage /></PrivateRoute>
                <PrivateRoute exact path="/account-verified"><AccountVerification open={userAuth.isVerified} /></PrivateRoute>
                <PrivateRoute exact path="/product-profile/:id"><ProductProfilePage /></PrivateRoute>
                <PrivateRoute exact path="/breeding-stage/:id"><BreedingStagePage /></PrivateRoute>
                <PrivateRoute exact path="/breeding-scheme-flowchart/:id"><BreedingSchemeFlowchartPage /></PrivateRoute>
                <PrivateRoute exact path="/users"><UsersPage /></PrivateRoute>
                <PrivateRoute exact path="/profile"><ProfilePage /></PrivateRoute>
                <PrivateRoute exact path="/vocabulary"><VocabularyPage /></PrivateRoute>
                <PrivateRoute exact path="/:shared?"><BreedingPipelinePage /></PrivateRoute>
              </Switch>
            </MuiPickersUtilsProvider>
          </ThemeProvider>
        </Router> : <PageProgress open={userAuth.isVerifying} />}
    </>
  );
}

export default App;

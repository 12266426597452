import { BREEDING_STAGE_TOGGLE_COLUMN } from '../constants/actionTypes';

const initialState = {
    columns: [
        { id: 1, label: 'Main Headers', type: "subheader" },
        { id: 'EvaluationStrategy', label: 'Evaluation Strategy', type: "column" },
        { id: 'Molecular', label: 'Molecular', type: "column" },
        { id: 'SelectionStrategy', label: 'Selection Strategy', type: "column" },
        { id: 'RecyclingStrategy', label: 'Recycling & Advancement Strategy', type: "column" },
        { id: 'MultiplicationStrategy', label: 'Multiplication Strategy', type: "column" },
        { id: 'CrossingStrategy', label: 'Crossing Strategy', type: "column" },
        { id: 2, label: 'Trait Columns', type: "subheader" },
        { id: 'selectionUnit', label: 'Selection Unit', type: "column" },
        { id: 'selectionValue', label: 'Selection Value', type: "column" },
        { id: 'breedingSurrogate', label: 'Breeding Surrogate', type: "column" },
        { id: 'noOfEnvironments', label: 'No. Of Environments', type: "column" },
        { id: 'selectionMethod', label: 'Selection Method', type: "column" },
    ],
    hiddenColumns: []
}
const reducer = (breedingStageColumns = initialState, action) => {
    switch (action.type) {
        case BREEDING_STAGE_TOGGLE_COLUMN:
            return {
                ...breedingStageColumns,
                hiddenColumns: action.payload
            }
        default:
            return breedingStageColumns
    }
}

export default reducer
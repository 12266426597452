import { Link } from 'react-router-dom'
import Box from '@material-ui/core/Box'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider';
import Tooltip from '@material-ui/core/Tooltip'
import IconButton from '@material-ui/core/IconButton'
import AddBoxIcon from '@material-ui/icons/AddBox'
import RefreshIcon from '@material-ui/icons/Refresh';
import AssessmentIcon from '@material-ui/icons/Assessment'
import GetAppIcon from '@material-ui/icons/GetApp'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import BreedingStageColumnToggler from '../BreedingStageColumnToggler'
import ShareContent from '../ShareContent'
import makeStyles from '@material-ui/styles/makeStyles'

const useStyles = makeStyles((theme) => ({
    list: {
        display: "inline-flex",
        '& li': {
            padding: "0px",
            width: "auto",
            '& button': {
                color: "#000"
            }
        },
        '& hr': {
            margin: "0px 2px"
        }
    },
}))

const BreedingStageToolbar = (props) => {
    const classes = useStyles()
    return (
        <Box display="inline-block">
            <List className={classes.list} dense disablePadding>
                <Divider orientation="vertical" flexItem />
                <ListItem>
                    <BreedingStageColumnToggler breedingSchemeId={props.breedingSchemeId} />
                </ListItem>
                <Divider orientation="vertical" flexItem />
                <ListItem>
                    <Tooltip title="Add New">
                        <span>
                            <IconButton disabled={props.permission === "VIEWER"} onClick={props.handleAddBreedingStage}>
                                <AddBoxIcon />
                            </IconButton>
                        </span>
                    </Tooltip>
                    <Tooltip title="Refresh">
                        <IconButton onClick={props.handleRefreshBreedingStage}>
                            <RefreshIcon />
                        </IconButton>
                    </Tooltip>
                    <Tooltip title="Flowchart">
                        <Link to={`/breeding-scheme-flowchart/${props.breedingSchemeId}`}>
                            <IconButton onClick={props.handleRefreshBreedingStage}>
                                <AssessmentIcon />
                            </IconButton>
                        </Link>
                    </Tooltip>
                </ListItem>
                <Divider orientation="vertical" flexItem />
                <ListItem>
                    <ShareContent
                        isDisabled={props.permission !== "OWNER"}
                        groupId={`BreedingScheme-${props.breedingSchemeId}`}
                        BreedingPipelineId={props.breedingPipelineId}
                        breedingSchemeIds={[props.breedingSchemeId]}
                        btnColor={props.permission !== "OWNER" ? "default" : "primary"}
                        btnSize="small"
                    />
                </ListItem>

                <ListItem>
                    <Tooltip title="Export to CSV">
                        <IconButton onClick={props.handleExportToCSV}>
                            <GetAppIcon />
                        </IconButton>
                    </Tooltip>
                </ListItem>
                <ListItem>
                    <Tooltip title="Export to PDF">
                        <IconButton onClick={props.handleExportToPDF}>
                            <PictureAsPdfIcon />
                        </IconButton>
                    </Tooltip>
                </ListItem>
            </List>
        </Box>
    )
}

export default BreedingStageToolbar;
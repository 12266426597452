import {
    FETCH_ALL_MARKET_SEGMENT_RESPONSE, CREATE_MARKET_SEGMENT_RESPONSE, UPDATE_MARKET_SEGMENT_RESPONSE,
    CONFIRM_DUPLICATE_MARKET_SEGMENT, CANCEL_DUPLICATE_MARKET_SEGMENT, DUPLICATE_MARKET_SEGMENT_RESPONSE,
    REMOVE_MARKET_SEGMENT_RESPONSE, CONFIRM_REMOVE_MARKET_SEGMENT, CANCEL_REMOVE_MARKET_SEGMENT
} from '../constants/actionTypes'

const initialState = {
    data: [],
    breedingPipelineId: "",
    isLoading: false,
    success: false,
    message: null,
    duplicateMarketSegmentForm: {
        marketSegmentId: "",
        breedingPipelineId: "",
        open: false
    },
    removeMarketSegmentForm: {
        marketSegmentId: "",
        breedingPipelineId: "",
        open: false
    }

}
const reducer = (marketSegment = initialState, action) => {
    switch (action.type) {
        case FETCH_ALL_MARKET_SEGMENT_RESPONSE:
            if (action.payload.data)
                action.payload.data.map((ms, idx) => ms.recordNo = idx + 1)
            return {
                ...marketSegment,
                ...action.payload,
                isLoading: false
            }
        case CREATE_MARKET_SEGMENT_RESPONSE:
            return {
                ...marketSegment,
                isLoading: false
            }
        case UPDATE_MARKET_SEGMENT_RESPONSE:
            return {
                ...marketSegment,
                isLoading: false
            }
        case CONFIRM_DUPLICATE_MARKET_SEGMENT:
            return {
                ...marketSegment,
                ...action.payload
            }
        case CANCEL_DUPLICATE_MARKET_SEGMENT:
            return {
                ...marketSegment,
                ...action.payload
            }
        case DUPLICATE_MARKET_SEGMENT_RESPONSE:
            return {
                ...marketSegment,
                ...action.payload
            }
        case CONFIRM_REMOVE_MARKET_SEGMENT:
            return {
                ...marketSegment,
                ...action.payload
            }
        case CANCEL_REMOVE_MARKET_SEGMENT:
            return {
                ...marketSegment,
                ...action.payload
            }
        case REMOVE_MARKET_SEGMENT_RESPONSE:
            return {
                ...marketSegment,
                ...action.payload,
                isLoading: false
            }
        default:
            return marketSegment
    }
}

export default reducer
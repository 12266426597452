import { SHOW_NOTIFICATION, CLOSE_NOTIFICATION, RESET_NOTIFICATION } from '../constants/actionTypes'

const initialState = {
    open: false,
    type: "info", // Type Options: [error, warning, info, success]
    message: ""
}

const reducer = (notification = initialState, action) => {
    switch (action.type) {
        case SHOW_NOTIFICATION:
            return action.payload
        case CLOSE_NOTIFICATION:
            return {
                ...notification,
                open: false
            }
        case RESET_NOTIFICATION:
            return initialState
        default:
            return notification
    }
}

export default reducer
import {
    LOGIN_REQUEST, LOGIN_RESPONSE, LOGOUT_RESPONSE, VERIFY_USER_REQUEST, VERIFY_USER_RESPONSE,
    EMAIL_VERIFICATION_REQUEST, EMAIL_VERIFICATION_RESPONSE, PASSWORD_RECOVER_REQUEST, PASSWORD_RECOVER_RESPONSE,
    PASSWORD_RESET_REQUEST, PASSWORD_RESET_RESPONSE, PASSWORD_TOKEN_VERIFY_REQUEST, PASSWORD_TOKEN_VERIFY_RESPONSE
} from '../constants/actionTypes'

const initialState = {
    isAuthenticated: false,
    isVerified: false,
    user: {},
    forgotPassword: {
        passwordReset: false,
        tokenExpired: false,
        isLoading: false,
    },
    isLoading: false,
    isVerifying: false,
    success: false,
    message: null
}

const reducer = (userAuth = initialState, action) => {
    switch (action.type) {
        case EMAIL_VERIFICATION_REQUEST:
            return {
                ...userAuth,
                isLoading: true
            }
        case EMAIL_VERIFICATION_RESPONSE:
            return {
                ...userAuth,
                ...action.payload,
                isLoading: false
            }
        case LOGIN_REQUEST:
            return {
                ...userAuth,
                isLoading: true
            }
        case LOGIN_RESPONSE:
            return {
                ...action.payload,
                isLoading: false,
                isVerifying: false
            }
        case LOGOUT_RESPONSE:
            return {
                ...initialState
            }
        case PASSWORD_RECOVER_REQUEST:
            return {
                ...userAuth,
                forgotPassword: {
                    passwordReset: false,
                    tokenExpired: false,
                    isLoading: true,
                }
            }
        case PASSWORD_RECOVER_RESPONSE:
            return {
                ...userAuth,
                forgotPassword: {
                    passwordReset: false,
                    tokenExpired: false,
                    isLoading: false,
                }
            }
        case PASSWORD_RESET_REQUEST:
            return {
                ...userAuth,
                forgotPassword: {
                    passwordReset: false,
                    tokenExpired: false,
                    isLoading: true,
                }
            }
        case PASSWORD_RESET_RESPONSE:
            return {
                ...userAuth,
                forgotPassword: {
                    passwordReset: action.payload.success,
                    tokenExpired: false,
                    isLoading: false,
                }
            }
        case PASSWORD_TOKEN_VERIFY_REQUEST:
            return {
                ...userAuth,
                forgotPassword: {
                    passwordReset: false,
                    tokenExpired: false,
                    isLoading: true,
                }
            }
        case PASSWORD_TOKEN_VERIFY_RESPONSE:
            return {
                ...userAuth,
                user: action.payload.userObj,
                forgotPassword: {
                    passwordReset: false,
                    tokenExpired: !action.payload.success,
                    isLoading: false,
                }
            }
        case VERIFY_USER_REQUEST:
            return {
                ...userAuth,
                isVerifying: true
            }
        case VERIFY_USER_RESPONSE:
            return {
                ...action.payload,
                isVerifying: false,
            }
        default:
            return userAuth
    }
}

export default reducer